const KioskScreenTypes = Object.freeze({
  Button: 1,
  Queue: 2,
  Service: 3,
  CreateAppointment: 4,
  CheckIn: 5,
  Notification: 6,
  Turn: 7,
  Client: 8,
  SpecialField: 9,
  Information: 10
});

export default KioskScreenTypes;
