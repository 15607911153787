<template>
  <div class="ViewAppointments">
    <validation-observer
      ref="appointmentRef"
      novalidate
    >
      <b-container
        id="appointments-container"
        fluid
      >
        <b-row>
          <content-header :title="$t('availableSpaces')" />
        </b-row>
        <div class="main-content">
          <b-row>
            <b-col>
              <div
                class="
                  caption
                  border-bottom
                  mb-4
                  text-left
                  main-content-title
                  pb-2
                "
              >
                {{ $t('filterspecialist') }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectcompany')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('company')"
                :options="availableCompanies"
                :placeholder="$t('select')"
                :value="selectedCompanies"
                @change="onCompanyChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectlocalization')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('localization')"
                :options="availableLocalizations"
                :placeholder="$t('select')"
                :value="selectedLocalizations"
                @change="onLocalizationChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectservices')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('services')"
                :options="availableServices"
                :placeholder="$t('select')"
                :select-all-enabled="true"
                :value="selectedServices"
                @change="onServicesChanged($event)"
              />
            </validation-provider>
          </b-row>
          <div v-if="!locationHaveTimes" class="row">
            <div class="col-md-12">
              <div class="alert alert-warning" role="alert">
              {{$t('toCreateSpaceOfficeMustHaveTimes')}}
            </div>
            </div>
          </div>
          <b-row>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12"
              rules="required"
            >
              <filter-specialists
                :available-specialists="availableSpecialists"
                class="mb-2"
                :collapsed="isSpecialistsCollapsed"
                :error="errors[0]"
                :error-msg="$t('mustselectspecialist')"
                :title="$t('selectionofspecialists')"
                :value="selectedSpecialists"
                @onSpecialistsAdded="onSpecialistsAdded"
                @onSpecialistsRemoved="onSpecialistsRemoved"
              />
            </validation-provider>
          </b-row>
          <div class="c-actionButtons">
            <b-row class="mr-2">
              <b-col class="text-right">
                <b-form-group>
                  <b-button
                    class="text-capitalize float-right mr-2"
                    variant="outline-success"
                    @click="openMassCreationModal"
                  >
                    <i class="fal fa-boxes" /> {{ $t('createInBatch') }}
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-2">
              <b-col class="text-right">
                <b-form-group>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="loadAvailableSpaces"
                  >
                    {{ $t('view') }}
                  </button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <b-form-group>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="goToCreateSpaces()"
                    :disabled="!locationHaveTimes"
                  >
                    {{ $t('createSpaces') }}
                  </button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row class="pt-4">
            <full-calendar
              id="appointment-calendar"
              ref="appointment-calendar"
              :first-date="this.calendarRange.beginDate"
              :items="appointments"
              :last-date="this.calendarRange.endDate"
              :month-items="appointmentsMonth"
              :type="calendarType"
              @on-start-end-date-changed="onStartEndDateChanged"
            />
          </b-row>
        </div>
      </b-container>
    </validation-observer>
    <custom-modal
      v-model="showMassCreationModal"
      size="lg"
      :title="$t('massCreateSpaces')"
    >
      <mass-space-creation-form
        v-if="showMassCreationModal"
        ref="massSpaceCreateForm"
        @close="showMassCreationModal = false"
      />
    </custom-modal>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';

import fullCalendar from '@/components/full-calendar/FullCalendar';
import contentHeader from '@/components/Header';
import CustomModal from '@/components/basics/modal/CustomModal';

import {
  getInitialFiltersData as _getInitialFiltersData,
} from '@/services/AppointmentService';
import { getLocationsForSpaceCreation as _getLocationsForSpaceCreation } from '@/services/LocationService';
import { getServiceTypesForSpaceCreation as _getServiceTypesForSpaceCreation } from '@/services/ServicesService';

import SpecialistService from '@/services/SpecialistService.js';
import AppointmentService from '@/services/AppointmentService.js';
import { VIEW_TYPE_CODE } from '@/components/full-calendar/constants';

import CalendarTypes from '@/constants/CalendarTypes';

import FilterSpecialists from '@/components/FilterSpecialists';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import MassSpaceCreationForm from './components/MassSpaceCreationForm';
import moment from 'moment';

export default {
  title: 'Available Spaces',
  name: 'ViewAppointments',
  components: {
    fullCalendar,
    contentHeader,
    FilterMultiSelect,
    FilterSpecialists,
    CustomModal,
    MassSpaceCreationForm
  },
  mixins: [sessionState],
  data() {
    return {
      availableCompanies: [],
      availableLocalizations: [],
      availableServices: [],

      selectedCompanies: [],
      selectedLocalizations: [],
      selectedServices: [],

      serviceIdSelected: [],

      appointments: [],
      appointmentsMonth: [],

      calendarRange: {
        beginDate: this.$moment(this.currentDate).startOf('isoWeek').toDate(),
        endDate: this.$moment(this.currentDate).endOf('isoWeek').toDate(),
      },
      searchRange: {
        beginDate: this.$moment(this.currentDate).startOf('isoWeek').toDate(),
        endDate: this.$moment(this.currentDate).endOf('isoWeek').toDate(),
      },
      viewType: VIEW_TYPE_CODE.WEEK,

      availableSpecialists: [],
      selectedSpecialists: [],

      filterModel: {
        selectedCompany: null,
        selectedLocation: null,
        selectedServices: null,
      },

      isSpecialistsCollapsed: true,
      showMassCreationModal: false,
    };
  },
  computed: {
    activated: function () {
      return this.selectedSpecialists.length == 0;
    },
    currentDate: function () {
      return moment().format();
    },
    calendarType() {
      return CalendarTypes.AvailableSpaces;
    },
    locationHaveTimes() {
      if (this.selectedLocalizations.length) {
        const location = this.selectedLocalizations[0];
        return location.officeOpenTime && location.officeCloseTime;
      }
      return true;
    }
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('SpacesAvailable');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      // default init
      await this.loadInitialFilters();
    }

    // prevent the user from going deeper in the space creation views
    if (this.hasSessionData('spacesToCreate')) {
      this.removeSessionData('spacesToCreate');
    }

    if (this.hasSessionData('spacesToVerify')) {
      this.removeSessionData('spacesToVerify');
    }

    // This is the data that is stored for the CreateSpace view
    if (this.hasSessionData('SpacesCreate')) {
      this.removeSessionData('SpacesCreate');
    }
  },
  methods: {
    async loadInitialFilters() {
      await _getInitialFiltersData()
        .then((response) => {
          const collections = response.data.collections;
          const selectedValues = response.data.filters;

          this.availableCompanies = collections.companies || [];
          this.availableLocalizations = collections.localizations || [];
          this.availableServices = collections.services || [];

          this.selectedCompanies = selectedValues.company
            ? [selectedValues.company]
            : [];
            if (selectedValues.localization) {
              this.selectedLocalizations = [selectedValues.localization];
              this.getAvailableServices(selectedValues.localization.id)
              this.getAvailableSpecialists();
            }

          this.selectedServices = selectedValues.service
            ? [selectedValues.service]
            : [];
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },

    async onCompanyChanged(item) {
      this.clearSelectedLocalization();
      this.clearSelectedServices();
      this.clearAvailableLocalizations();
      this.clearAvailableServices();
      this.selectedCompanies = [item];

      this.availableSpecialists = [];
      this.selectedSpecialists = [];
      this.appointments = [];

      await this.getAvailableLocalizations(item.id);
    },
    async onLocalizationChanged(item) {
      this.appointments = [];
      this.clearSelectedServices();
      this.clearSelectedSpecialists();
      this.clearAvailableServices();
      this.clearAvailableSpecialists();
      this.selectedLocalizations = [item];

      await this.getAvailableServices(item.id);

      if (this.selectedLocalizations.length > 0) {
        this.getAvailableSpecialists();
      }
    },
    onServicesChanged(item) {
      this.selectedServices = item;
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationsForSpaceCreation(companyId)
        .then((response) => {
          this.availableLocalizations = response.data;
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableServices(localizationId) {
      await _getServiceTypesForSpaceCreation(localizationId)
        .then((response) => {
          this.availableServices = response.data;
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    getAvailableSpecialists() {
      SpecialistService.getDropDownListForCreateSpace(
        this.selectedCompanies[0].id,
        0,
        0,
        this.selectedLocalizations[0].id,
      )
        .then((response) => {
          this.availableSpecialists = response.data;
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    onSpecialistsAdded(specialistsToAdd) {
      this.selectedSpecialists.push(...specialistsToAdd);
    },
    onSpecialistsRemoved(specialistsToRemove) {
      specialistsToRemove.forEach((e) => {
        let index = this.selectedSpecialists.indexOf(e);
        this.selectedSpecialists.splice(index, 1);
      });
    },
    clearSelectedLocalization() {
      this.selectedLocalizations = [];
    },
    clearSelectedServices() {
      this.selectedServices = [];
    },
    clearSelectedSpecialists() {
      this.selectedSpecialists = [];
    },
    clearAvailableLocalizations() {
      this.availableLocalizations = [];
    },
    clearAvailableServices() {
      this.availableServices = [];
    },
    clearAvailableSpecialists() {
      this.availableSpecialists = [];
    },
    async loadAvailableSpaces() {
      const isValid = await this.$refs.appointmentRef.validate();

      if (!isValid || this.selectedSpecialists.length == 0) {
        return;
      }

      var companyId = this.selectedCompanies[0].id;
      var locationId = this.selectedLocalizations[0].id;
      var serviceIds = this.selectedServices.map((x) => x.id);
      var specialistIds = this.selectedSpecialists.map((x) => x.id);

      var timeStart = moment(this.searchRange.beginDate).format();
      var timeEnd = moment(this.searchRange.endDate).format();

      if (
        companyId > 0 &&
        locationId > 0 &&
        serviceIds.length > 0 &&
        specialistIds.length > 0
      ) {
        // Actualizar Conteo
        if (
          this.viewType === VIEW_TYPE_CODE.MONTH ||
          this.viewType === VIEW_TYPE_CODE.DAY
        ) {
          AppointmentService.getAvailableAppointmentMonth(
            companyId,
            locationId,
            serviceIds,
            specialistIds,
            timeStart,
            timeEnd,
            false
          )
            .then((response) => {
              this.appointmentsMonth = response.data;
            })
            .catch(error => this.ShowErrorToast(error.response.data.message));
        } else {
          this.appointmentsMonth = [];
        }

        // Actualizar citas
        if (
          this.viewType === VIEW_TYPE_CODE.WEEK ||
          this.viewType === VIEW_TYPE_CODE.DAY
        ) {
          AppointmentService.getAvailableAppointment(
            companyId,
            locationId,
            serviceIds,
            specialistIds,
            timeStart,
            timeEnd,
            false
          )
            .then((response) => {
              this.appointments = response.data;
            })
            .catch(error => this.ShowErrorToast(error.response.data.message));
        } else {
          this.appointments = [];
        }
      } else {
        this.appointments = [];
        this.appointmentsMonth = [];
      }

      /*Scroll to the Calendar*/
      var htmlCalendarElement = this.$refs['appointment-calendar'];
      var top = htmlCalendarElement.$el.offsetTop;
      window.scrollTo(0, top);
    },
    onStartEndDateChanged({ startDate, endDate, viewType }) {
      this.calendarRange.beginDate = startDate;
      this.calendarRange.endDate = endDate;
      this.viewType = viewType;

      if (
        viewType == VIEW_TYPE_CODE.WEEK ||
        viewType == VIEW_TYPE_CODE.MONTH ||
        viewType == VIEW_TYPE_CODE.DAY
      ) {
        this.searchRange.beginDate = startDate;
        this.searchRange.endDate = endDate;
      }

      this.loadAvailableSpaces();
    },
    async goToCreateSpaces() {
      const isValid = await this.$refs.appointmentRef.validate();

      if (!isValid || this.selectedSpecialists.length == 0) {
        return;
      }

      const sessionData = {
        availableCompanies: this.availableCompanies,
        availableLocalizations: this.availableLocalizations,
        availableServices: this.availableServices,
        availableSpecialists: this.availableSpecialists,

        selectedCompanies: this.selectedCompanies,
        selectedLocalizations: this.selectedLocalizations,
        selectedServices: this.selectedServices,
        selectedSpecialists: this.selectedSpecialists,
      };

      // Persist the temp data that will be used in the next view
      this.saveSessionData('spacesToCreate', sessionData);

      // Persist current state of this view
      this.saveSessionData('SpacesAvailable', Object.assign({}, this.$data));

      this.$router.push({ name: 'CreateSpace' });
    },
    openMassCreationModal() {
      this.showMassCreationModal = true;
    }
  },
};
</script>

<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
#appointments-container {
  background-color: $color-app-background;
}
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 23px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.title-description {
  margin-top: 18px;
  margin-bottom: 18px;
}
.filters label {
  font: normal normal normal 14px/21px Lato;
  letter-spacing: 0px;
  color: $color-font-secondary;
  opacity: 1;
}
.select-all {
  text-decoration: underline;
}
select {
  padding: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
select option {
  color: $color-font-primary;
  opacity: 1;
  margin: 0px;
  padding: 5px;
}

select option:selected {
  background-color: $color-primary !important;
  background: $color-primary 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/28px Lato;
  letter-spacing: 0px;
  color: $color-font-primary;
  opacity: 1;
  margin: 0px;
  padding: 5px;
}

@media only screen and (max-width: 414px) {
  .main-content {
    padding: 11px !important;
  }
}

.c-actionButtons {
  display: flex;
  justify-content: flex-end;
}
</style>
