import { apiTurnos } from '@/core/api/';
import {
    serialize
  } from 'object-to-formdata'

export const getLogos = (payload) => apiTurnos.get('/api/logo/search',{ params : { ...payload }});

export const getLogo = (payload) => apiTurnos.get(`/api/logo/${payload}`);

export const createLogo = (model) => { 
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });
    
    return apiTurnos.post('/api/logo', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const updateLogo = (model) => { 
    const payload = serialize(model, {
        indices: true,
        nullsAsUndefineds: true
    });
    
    return apiTurnos.put('/api/logo', payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },);
}

export const deleteLogo = (payload) => apiTurnos.delete(`/api/logo/${payload}`);

export const deleteLogoImage = (payload) => apiTurnos.delete(`/api/logo/${payload}/image`);