<template>
  <div class="c-createSpaces">
    <validation-observer ref="formRef" novalidate>
      <b-row>
        <b-col cols="6">
          <div class="header-title">
            <h2>
              {{ $t('createspace') }}
            </h2>
          </div>
        </b-col>
        <b-col cols="6 d-flex justify-content-end align-items-center">
          <button
            class="btn btn-outline-primary mr-2"
            type="button"
            @click="$refs['go-back-modal-ref'].show()"
          >
            <i class="fal fa-arrow-circle-left" /> {{ $t('return') }}
          </button>
        </b-col>
      </b-row>
      <div class="c-s-createSpaces">
        <h6>{{ $t('generalInformation') }}</h6>
        <div class="c-createSpaces-form container-fluid">
          <b-row class="c-generalFilters">
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectcompany')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('company')"
                :options="availableCompanies"
                :placeholder="$t('select')"
                :value="selectedCompanies"
                @change="onCompanyChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectlocalization')"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('localization')"
                :options="availableLocalizations"
                :placeholder="$t('select')"
                :value="selectedLocalizations"
                @change="onLocalizationChanged($event)"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-4"
              rules="required"
            >
              <filter-multi-select
                :error="errors[0]"
                :error-msg="$t('mustselectservices')"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="true"
                :label="$t('services')"
                :options="availableServices"
                :placeholder="$t('select')"
                :select-all-enabled="true"
                :value="selectedServices"
                @change="onServicesChanged($event)"
              />
            </validation-provider>
          </b-row>
          <div v-if="!locationHaveTimes" class="row">
            <div class="col-md-12">
              <div class="alert alert-warning" role="alert">
                {{ $t('toCreateSpaceOfficeMustHaveTimes') }}
              </div>
            </div>
          </div>
          <b-row class="c-hourlyFilters">
            <base-datetime-picker
              v-model="startDate"
              class="col-12 col-xl-2"
              format="dd/MMM/yyyy"
              :min-datetime="today"
              :title="$t('startDate')"
              type="date"
              @change="onStartDateChanged($event)"
            />
            <filter-numeric
              v-model="hours.duration"
              class="col-12 col-xl-2"
              :clearable="false"
              icon="clock"
              :max="maxDurationTime"
              :min="1"
              title="durationInMinutes"
              @input="onDurationChanged"
            />
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-2"
              rules="required"
            >
              <base-time-picker
                :error="errors[0]"
                :error-msg="$t('mustselecttime')"
                icon="clock"
                :max-datetime="maxTimeFromHour"
                :min-datetime="officeOpenTime"
                :minute-step="1"
                :required="true"
                :title="$t('hourFrom')"
                :value="hours.from"
                @input="onHourFromChanged"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 col-md-2"
              rules="required"
            >
              <base-time-picker
                :error="errors[0]"
                :error-msg="$t('mustselecttime')"
                icon="clock"
                :max-datetime="officeCloseTime"
                :min-datetime="minimumHoursTo"
                :minute-step="hours.duration"
                :required="true"
                :title="$t('hourTo')"
                :value="hourTo"
                @input="onHourToChanged"
              />
            </validation-provider>
            <FilterMultiSelect
              class="col-12 col-xl-2"
              fieldtext="name"
              fieldvalue="id"
              icon="clock"
              :is-multiple="true"
              :label="$t('ignoredHours')"
              :options="availableHoursToIgnore"
              :placeholder="$t('select')"
              :value="hours.ignored"
              @change="onIgnoredHoursChanged($event)"
            />
          </b-row>
        </div>
        <div class="c-createSpaces-form container-fluid mt-2">
          <b-row>
            <b-col cols="12">
              <h6>{{ $t('recurrency') }}</h6>
            </b-col>
          </b-row>
          <b-row class="pt-3">
            <b-col cols="12" md="4">
              <b-row>
                <b-col cols="12">
                  <base-checkbox
                    id="checkbox-1"
                    v-model="recurrence.active"
                    :label="$t('recurrency')"
                    name="checkbox-1"
                    @change="onRecurrenceActiveChanged($event)"
                  />
                </b-col>
              </b-row>
              <b-row v-if="isRecurrenceActive">
                <b-col cols="12">
                  <div>
                    <base-radiobutton-group
                      :initial-selected="recurrence.frequency"
                      :options="availableFrecuencyTypes"
                      @change="onFrequencyChanged($event)"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="isRecurrenceActive">
                <b-col cols="12">
                  <b-form-group>
                    <b-row>
                      <b-col class="pr-sm-0" cols="5" lg="5" sm="5" xl="3">
                        <label class="strong pt-2 color-black">{{
                          $t('repeatEach')
                        }}</label>
                      </b-col>
                      <b-col class="pl-sm-0" cols="4" lg="4" sm="4" xl="2">
                        <filter-numeric
                          class="col-sm-12"
                          :clearable="false"
                          :min="1"
                          :value="recurrence.repeatAfter"
                          @input="onRepeatAfterChanged"
                        />
                      </b-col>
                      <b-col cols="3" lg="3" sm="3" xl="2">
                        <label class="pt-2 strong color-black">{{
                          $t(selectedDay)
                        }}</label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="isRecurrenceActive">
                <b-col cols="12">
                  <b-form-group>
                    <base-checkbox-group
                      id="checkbox-group-1"
                      v-model="recurrence.selectedWeekDays"
                      :disabled="recurrence.frequency === 1"
                      name="flavour-1"
                      :options="availableWeekDays"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="isRecurrenceActive"
              class="left-divider"
              cols="12"
              md="4"
            >
              <b-row v-if="isRecurrenceActive">
                <b-col class="pr-0" cols="7" lg="6" sm="12" xl="5">
                  <div>
                    <base-radiobutton-group
                      :initial-selected="recurrence.finish.selectedMode"
                      :options="recurrence.finish.modes"
                      @change="onFinishModeChanged($event)"
                    />
                  </div>
                </b-col>
                <b-col cols="4" lg="4" sm="6" xl="3">
                  <filter-numeric
                    class="col-sm-12"
                    :clearable="false"
                    :disabled="isOcurrencesDisabled"
                    :min="1"
                    :value="recurrence.finish.occurrences"
                    @input="onFinishOccurrenceChanged"
                  />
                </b-col>

                <b-col cols="3" lg="3" xl="3">
                  <label class="pt-2 color-black">{{
                    $t('occurrences')
                  }}</label>
                </b-col>

                <b-col cols="12" lg="9" sm="12">
                  <b-form-group>
                    <base-datetime-picker
                      class="theme-orange"
                      :disabled="!isOcurrencesDisabled"
                      format="dd/MMM/yyyy"
                      :max-datetime="limitDate.format()"
                      :min-datetime="startDate"
                      type="date"
                      :value="recurrence.finish.endDate"
                      @change="onFinishDateChanged($event)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="isRecurrenceActive"
              class="left-divider"
              cols="12"
              lg="3"
              sm="4"
            >
              <b-form-group :label="$t('selectDatesToIgnore')">
                <base-datetime-picker
                  class="theme-orange"
                  :max-datetime="maxIgnoredDate"
                  :min-datetime="startDate"
                  :selected-week-days="recurrence.selectedWeekDays"
                  type="date"
                  @change="onIgnoreDateSelected($event)"
                />
                <br />
                <b-row>
                  <b-col cols="12" sm="12">
                    <label
                      v-for="ignored in recurrence.ignoredDates"
                      :key="ignored"
                      class="label label-default label-round text-left color-black"
                      :value="ignored"
                    >
                      <span
                        class="float-left"
                        style="position: absolute; top: 25%"
                      />
                      {{ ignored | date }}
                      <span class="float-right">
                        <i
                          class="fal fa-times-circle text-dark color-black fs-18"
                          @click="removeIgnoredDateHandler(ignored)"
                        />
                      </span>
                    </label>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <validation-provider
              v-slot="{ errors }"
              class="col-sm-12 mt-3"
              rules="required"
            >
              <filter-specialists
                :available-specialists="availableSpecialists"
                class="mb-3"
                :collapsed="isSpecialistsCollapsed"
                :error="errors[0]"
                :error-msg="$t('mustselectspecialist')"
                :title="$t('selectionofspecialists')"
                :value="selectedSpecialists"
                @onSpecialistsAdded="onSpecialistsAdded"
                @onSpecialistsRemoved="onSpecialistsRemoved"
              />
            </validation-provider>
          </b-row>
        </div>
      </div>
      <CustomNotFound
        v-if="
          Number(createSpaceSetup.spaceLimit) < spaceCalculatedData.totalSpaces
        "
        icon-class="fal fa-exclamation-circle"
        :text="`${$t('systemDoesNotLetMore')} ${
          createSpaceSetup.spaceLimit
        } ${$t('spaces')}, ${$t('spaceAmount')} ${
          spaceCalculatedData.totalSpaces
        }`"
        :warning="true"
      />
      <CustomNotFound
        v-if="limitDateExceeded"
        icon-class="fal fa-exclamation-circle"
        :text="`${$t('dateLimitExceeded')}: ${limitDate.format('LL')}`"
        :warning="true"
      />

      <div
        v-if="
          specialistsWithCreatedSpaces &&
          specialistsWithCreatedSpaces.length > 0
        "
        style="margin-top: 20px"
      >
        <div class="row" style="text-align: center">
          <div class="col-12">
            <em class="fal fa-exclamation-circle space-verify-icon" />
          </div>
          <div class="col-12 mt-2">
            <span class="space-verify-text">{{
              `${$t('specialistWithSpacesCreated')}`
            }}</span>
          </div>
          <div
            class="offset-4 col-4 mt-2"
            style="max-height: 150px; overflow-y: scroll"
          >
            <span
              v-for="item in specialistsWithCreatedSpaces"
              :key="item"
              class="space-verify-text"
            >
              {{ item.toString() }}<br
            /></span>
          </div>
        </div>
      </div>
      <div>
        <b-row>
          <b-col cols="12">
            <div class="header-buttons py-3 float-right">
              <base-filled-button
                :disabled="limitDateExceeded || !locationHaveTimes"
                icon-class="fal fa-eye"
                :on-click="onSubmit"
                :text="$t('review')"
              />
              <base-filled-button
                bg-color="#707070"
                :disabled="!locationHaveTimes"
                icon-class="fal fa-times-circle"
                :on-click="resetView"
                :text="$t('reset')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <custom-modal id="go-back-modal" ref="go-back-modal-ref" size="md">
      <div class="back-container text-center">
        <i class="fal fa-exclamation-circle back-container__icon" />
        <div class="back-container__title text-center">
          {{ $t('areyousuregoback') }}
        </div>
        <div class="back-container__message text-center">
          {{ $t('irreversibleoperationmessage') }}
        </div>
        <div class="d-flex flex-row mt-4">
          <button
            class="btn btn-primary mx-4 flex-grow-1"
            type="button"
            @click="$router.push({ name: 'availablespaces' })"
          >
            {{ $t('yes') }}
          </button>

          <button
            class="btn btn-secondary mx-4 flex-grow-1"
            type="button"
            @click="$refs['go-back-modal-ref'].hide()"
          >
            {{ $t('no') }}
          </button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import localState from '@/mixins/localState';

import { getInitialFiltersData as _getInitialFiltersData } from '@/services/AppointmentService';
import { getLocationsForSpaceCreation as _getLocationsForSpaceCreation } from '@/services/LocationService';
import { getServiceTypesForSpaceCreation as _getServiceTypesForSpaceCreation } from '@/services/ServicesService';
import { getSpaceCreationSetup as _getSpaceCreationSetup } from '@/services/SetupParameterService';

import SpecialistService from '@/services/SpecialistService.js';

import FilterSpecialists from '@/components/FilterSpecialists';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';

import {
  calculateSpaces as _calculateSpaces,
  validateSpecialistSpaces as _validateSpecialistSpaces,
} from '@/services/AppointmentService';

import CustomModal from '@/components/basics/modal/CustomModal.vue';

// This import is only used to initialize inside data and enable the window reset
import moment from 'moment';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import FrequencyTypes from '@/constants/FrequencyTypes';

extend('required', {
  ...required,
});

export default {
  name: 'CreateSpaces',
  components: {
    ValidationProvider,
    ValidationObserver,
    FilterSpecialists,
    FilterMultiSelect,
    CustomModal,
    CustomNotFound,
  },
  mixins: [sessionState, localState],
  data() {
    return {
      ...this.initializeState(),
      officeOpenTime: null,
      officeCloseTime: null,
    };
  },
  computed: {
    isOcurrencesDisabled() {
      return this.recurrence.finish.selectedMode == 2;
    },
    selectedDay: function () {
      return this.recurrence.repeatEachTexts.find(
        (element) => element.value === this.recurrence.frequency,
      ).text;
    },
    limitDate: function () {
      return moment(this.startDate).add(this.createSpaceSetup.monthLimit, 'M');
    },
    limitDateExceeded: function () {
      let lastDate = moment(this.selectedDates[this.selectedDates.length - 1]);
      if (lastDate > this.limitDate) return true;
      else return false;
    },
    today() {
      return this.$moment().format();
    },
    isRecurrenceActive() {
      return this.recurrence.active;
    },
    spaceMinimumDate() {
      return this.$moment().format();
    },
    minimumHoursTo() {
      if (!this.hours.duration) return null;
      const mht = this.$moment(this.hours.from)
        .set({ second: 0 })
        .add({ minutes: this.hours.duration });
      return mht.format('HH:mm:ss');
    },
    maxTimeFromHour() {
      if (!this.hours.duration || !this.officeCloseTime) return null;
      return this.$moment(this.officeCloseTime, 'HH:mm:ss')
        .subtract(this.hours.duration, 'minutes')
        .format('HH:mm:ss');
    },
    maxDurationTime() {
      if (this.officeCloseTime && this.officeOpenTime) {
        const min = Math.abs(
          this.$moment(this.officeCloseTime).diff(
            this.officeOpenTime,
            'minutes',
          ),
        );

        return min;
      }

      return 480;
    },
    locationHaveTimes() {
      if (this.selectedLocalizations.length) {
        const location = this.selectedLocalizations[0];
        return location.officeOpenTime && location.officeCloseTime;
      }
      return true;
    },
    hourTo() {
      if (!this.hours.wasHourToTouched) {
        this.onHourToChanged(this.minimumHoursTo);
      }
      return this.hours.to;
    },
    availableHoursToIgnore() {
      let availableFromToHours = [];
      let addition = this.hours.from;
      if (!this.hours.duration) return;
      while (this.$moment(addition).isBefore(this.hours.to)) {
        availableFromToHours.push(addition);
        addition = this.$moment(addition)
          .add(this.hours.duration, 'minutes')
          .format();
      }
      availableFromToHours = availableFromToHours.map((e) => {
        return {
          name: this.$moment(e).format('h:mm A'),
          id: this.$moment(e).format('YYYY-MM-DDTHH:mm:ss'),
        };
      });

      return availableFromToHours;
    },
    selectedHours() {
      const self = this;
      const result = this.availableHoursToIgnore.filter((e) => {
        // The text is used because there is one component or serialization adding the ms to the time
        // and the comparison is inaccurate
        return !self.hours.ignored.some((e2) => e.name == e2.name);
      });

      return result;
    },
    availableWeekDays() {
      const result = this.$moment.weekdays().map((e, i) => {
        return { text: 'day_' + i, value: i };
      });

      return result;
    },
    availableFrecuencyTypes() {
      return [
        {
          text: 'frequency_type_' + FrequencyTypes.Daily,
          value: FrequencyTypes.Daily,
        },
        {
          text: 'frequency_type_' + FrequencyTypes.Weekly,
          value: FrequencyTypes.Weekly,
        },
      ];
    },
    selectedDates() {
      let result = [];
      const currentLimitEndDate = this.endDateLimit;

      // Only work if the recurrence is active
      if (this.recurrence.active) {
        let range;
        let isChecked;
        let step = this.recurrence.repeatAfter;
        let weekIndex = 1;
        let stepOfDay = 1;
        let index = 0;
        let isIgnored;

        // Daily
        if (this.recurrence.frequency === FrequencyTypes.Daily) {
          range = this.$moment().range(
            this.$moment(this.startDate).format('YYYYMMDD'),
            currentLimitEndDate,
          );

          for (const days of range.by('days', { step: step })) {
            isIgnored = this.recurrence.ignoredDates.find(
              (element) => element == days.format(),
            );

            if (!isIgnored) {
              if (
                this.recurrence.finish.selectedMode === 1 &&
                index + 1 <= this.recurrence.finish.occurrences
              ) {
                result.push(days.format());
                index++;
              } else if (
                this.recurrence.finish.selectedMode === 2 &&
                this.$moment(this.startDate).isBefore(
                  this.$moment(this.recurrence.finish.endDate).add(days, 1),
                )
              ) {
                result.push(days.format());
              }
            }
          }
        } else if (this.recurrence.frequency === FrequencyTypes.Weekly) {
          //Weekly
          const dateRange = this.$moment().range(
            this.startDate,
            currentLimitEndDate,
          );

          const totalDateRange = dateRange.by('week', { step: step });

          const arrWeekRange = Array.from(totalDateRange);

          let currentEndOfWeekDay = this.$moment(this.startDate)
            .add(1, 'weeks')
            .add(-1, 'days');
          let currentStartOfWeekDay = this.$moment(this.startDate);

          for (const weeks of arrWeekRange) {
            if (weekIndex > 1) {
              currentStartOfWeekDay = weeks.clone();
              currentEndOfWeekDay = weeks.add(1, 'weeks').add(-1, 'days');
            }

            range = this.$moment().range(
              currentStartOfWeekDay.format(),
              currentEndOfWeekDay.format(),
            );

            for (const days of range.by('days', { step: stepOfDay })) {
              isIgnored = this.recurrence.ignoredDates.find(
                (element) => element == days.format(),
              );

              isChecked = this.recurrence.selectedWeekDays.includes(
                this.$moment(days).day(),
              );

              if (isChecked && !isIgnored) {
                if (this.recurrence.finish.selectedMode === 1) {
                  result.push(days.format());
                } else if (
                  this.recurrence.finish.selectedMode === 2 &&
                  this.$moment(days).isBefore(
                    this.$moment(currentLimitEndDate).add(1, 'days').format(),
                    'day',
                  )
                ) {
                  result.push(days.format());
                }
              }
            }

            weekIndex++;
          }
        }
      } else {
        result.push(this.startDate);
      }

      const dates = result.map((x) => moment(x).format('YYYY-MM-DD'));
      return dates;
    },
    maxIgnoredDate() {
      if (this.selectedDates.length > 0) {
        return this.selectedDates[this.selectedDates.length - 1];
      }

      return this.startDate;
    },
    endDateLimit() {
      if (!this.recurrence.active) {
        return this.startDate;
      }

      let result = this.startDate;

      if (this.recurrence.frequency === FrequencyTypes.Daily) {
        result =
          this.recurrence.finish.selectedMode === 1
            ? this.$moment(this.startDate)
                .add(
                  this.recurrence.finish.occurrences *
                    this.recurrence.repeatAfter -
                    1,
                  'days',
                )
                .format()
            : this.$moment(this.recurrence.finish.endDate).format();
      } else if (this.recurrence.frequency === FrequencyTypes.Weekly) {
        result =
          this.recurrence.finish.selectedMode === 1
            ? this.$moment(this.startDate)
                .add(
                  this.recurrence.finish.occurrences *
                    this.recurrence.repeatAfter,
                  'weeks',
                )
                .add(-1, 'days')
                .format()
            : this.$moment(this.recurrence.finish.endDate).format();
      }

      return result;
    },
  },
  watch: {
    officeOpenTime(newValue) {
      if (newValue != null) {
        this.hours.from = newValue;
      }
    },
    limitDateExceeded(newVal) {
      if (newVal == true) {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 50);
      }
    },
  },
  methods: {
    async onCompanyChanged(item) {
      this.clearSelectedLocalization();
      this.clearSelectedServices();
      this.clearAvailableLocalizations();
      this.clearAvailableServices();
      this.selectedCompanies = [item];

      await this.getAvailableLocalizations(item.id);
    },
    async onLocalizationChanged(item) {
      this.clearSelectedServices();
      this.clearAvailableServices();
      this.clearAvailableSpecialists();
      this.clearSelectedSpecialists();
      this.selectedLocalizations = [item];
      this.setOfficeTimes();

      await this.getAvailableServices(item.id);

      if (this.selectedLocalizations.length > 0) {
        this.getAvailableSpecialists();
      }
    },
    onServicesChanged(item) {
      this.selectedServices = item;
    },
    onDurationChanged() {
      this.hours.wasHourToTouched = false;
    },
    onHourFromChanged(newHourFrom) {
      this.hours.wasHourToTouched = false;
      this.hours.from = newHourFrom;
    },
    onHourToChanged(newHourTo) {
      this.hours.wasHourToTouched = true;
      let hourTo = this.$moment(newHourTo);
      if (!hourTo.isValid()) {
        hourTo = this.$moment(newHourTo, 'HH:mm:ss');
      }
      this.hours.to = hourTo.format();
    },
    onIgnoredHoursChanged(newIgnoreHours) {
      this.hours.ignored = newIgnoreHours;

      // Update the localstate with the ignored dates information
      this.saveLocalData('IgnoredHours', this.hours.ignored);
    },
    onSpecialistsAdded(specialistsToAdd) {
      this.selectedSpecialists.push(...specialistsToAdd);
    },
    onSpecialistsRemoved(specialistsToRemove) {
      this.selectedSpecialists = this.selectedSpecialists.filter(function (
        elem,
      ) {
        return !specialistsToRemove.some((elem2) => elem.id == elem2.id);
      });
    },
    clearSelectedLocalization() {
      this.selectedLocalizations = [];
    },
    clearSelectedServices() {
      this.selectedServices = [];
    },
    clearAvailableLocalizations() {
      this.availableLocalizations = [];
    },
    clearAvailableServices() {
      this.availableServices = [];
    },
    clearAvailableSpecialists() {
      this.availableSpecialists = [];
    },
    clearSelectedSpecialists() {
      this.selectedSpecialists = [];
    },
    clearIgnoredHours() {
      this.hours.ignored = [];
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationsForSpaceCreation(companyId)
        .then((response) => {
          this.availableLocalizations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableServices(localizationId) {
      await _getServiceTypesForSpaceCreation(localizationId)
        .then((response) => {
          this.availableServices = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    getAvailableSpecialists() {
      SpecialistService.getDropDownListForCreateSpace(
        this.selectedCompanies[0].id,
        0,
        0,
        this.selectedLocalizations[0].id,
      )
        .then((response) => {
          this.availableSpecialists = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onStartDateChanged(value) {
      this.startDate = value;
      if (
        this.$moment(this.startDate).format() >
        this.$moment(this.recurrence.finish.endDate).format()
      )
        this.recurrence.finish.endDate = this.limitDate.format();
    },
    onFrequencyChanged(selectedValue) {
      this.recurrence.frequency = selectedValue;

      if (selectedValue === FrequencyTypes.Daily) {
        this.recurrence.selectedWeekDays = this.availableWeekDays.map(
          (e) => e.value,
        );
      } else if (selectedValue === FrequencyTypes.Weekly) {
        this.recurrence.selectedWeekDays = this.recurrence.mainWeekDays;
      }
    },
    onRepeatAfterChanged(value) {
      this.recurrence.repeatAfter = parseInt(value);
    },
    onFinishModeChanged(value) {
      this.recurrence.finish.selectedMode = value;
    },
    onFinishOccurrenceChanged(value) {
      this.recurrence.finish.occurrences = parseInt(value);
    },
    onFinishDateChanged(value) {
      this.recurrence.finish.endDate = value;
    },
    onRecurrenceActiveChanged(value) {
      this.recurrence.active = value;
    },
    onIgnoreDateSelected(value) {
      if (this.$moment(value).isValid()) {
        const formatedValue = this.$moment(value).format('YYYYMMDD');

        var isAdded = this.recurrence.ignoredDates.find(
          (element) => element == this.$moment(formatedValue).format(),
        );

        if (!isAdded) {
          this.recurrence.ignoredDates.push(
            this.$moment(formatedValue).format(),
          );
        }

        // Update the localstate with the ignored dates information
        this.saveLocalData('IgnoredDates', this.recurrence.ignoredDates);
      }
    },
    removeIgnoredDateHandler(date) {
      const foundIndex = this.recurrence.ignoredDates.findIndex(
        (a) => a.date == date,
      );

      this.recurrence.ignoredDates.splice(foundIndex, 1);

      // Update the localstate with the ignored dates information
      this.saveLocalData('IgnoredDates', this.recurrence.ignoredDates);
    },

    async onSubmit() {
      const isValid = await this.$refs.formRef.validate();
      this.specialistsWithCreatedSpaces = {};

      if (!isValid || this.selectedSpecialists.length == 0) {
        return;
      }

      let specialist = this.selectedSpecialists.map((x) => x.id);
      let services = this.selectedServices.map((x) => x.id);
      let hours = this.selectedHours.map((x) => x.id);

      let calculatedData = await _calculateSpaces(
        specialist,
        services,
        this.selectedDates,
        hours,
        this.hourDuration,
      ).catch((error) => this.ShowErrorToast(error.response.data.message));

      this.spaceCalculatedData = calculatedData.data;

      if (
        this.spaceCalculatedData.totalSpaces > this.createSpaceSetup.spaceLimit
      ) {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 50);
        return;
      }

      let validateSpecialistSpaces = await _validateSpecialistSpaces(
        specialist,
        services,
        this.selectedDates,
        hours,
        this.hourDuration,
      ).catch((error) => this.ShowErrorToast(error.response.data.message));

      this.specialistsWithCreatedSpaces = validateSpecialistSpaces.data;

      if (
        this.specialistsWithCreatedSpaces &&
        this.specialistsWithCreatedSpaces.length > 0
      ) {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 50);
        return;
      }

      const swDays = this.recurrence.selectedWeekDays;

      const currentlySelectedWeekDays = this.availableWeekDays
        .filter((e1) => {
          return swDays.includes(e1.value);
        })
        .map((e) => {
          return { id: e.value, name: e.text };
        });

      let hourEnd = this.$moment(this.hours.to);
      if (!hourEnd.isValid()) {
        hourEnd = this.$moment(this.hours.to, 'HH:mm');
      }

      const sessionData = {
        selectedDates: this.selectedDates,
        selectedHours: this.selectedHours,

        selectedCompany: this.selectedCompanies[0],
        selectedLocalization: this.selectedLocalizations[0],
        selectedServices: this.selectedServices,

        selectedSpecialists: this.selectedSpecialists.map((x) => {
          return { id: x.id, name: x.name };
        }),

        selectedWeekDays: currentlySelectedWeekDays,

        spaceCalculatedData: this.spaceCalculatedData,

        hourDuration: this.hours.duration,
        hourStart: this.hours.from,
        hourEnd: hourEnd,

        ignoredHours: this.hours.ignored || [],
        ignoredDates: this.recurrence.ignoredDates || [],

        selectedFrecuency: {
          id: this.recurrence.frequency,
          name: this.recurrence.repeatEachTexts.find(
            (e) => e.value === this.recurrence.frequency,
          ).text,
        },
      };

      // Persist the temp data that will be used in the next view
      this.saveSessionData('spacesToVerify', sessionData);

      // Persist the current data information so we can go back to this view from the next one
      this.saveSessionData('SpacesCreate', Object.assign({}, this.$data));

      const spacesAvailableData = this.loadSessionData('SpacesAvailable');
      this.saveSessionData('SpacesAvailable', {
        ...spacesAvailableData,
        selectedSpecialists: sessionData.selectedSpecialists,
      });

      this.$router.push({ name: 'VerifySpace' });
    },
    async resetView() {
      Object.assign(this.$data, this.initializeState());
    },
    async loadInitialFilters() {
      await _getInitialFiltersData()
        .then((response) => {
          const collections = response.data.collections;
          const selectedValues = response.data.filters;

          this.availableCompanies = collections.companies || [];
          this.availableLocalizations = collections.localizations || [];
          this.availableServices = collections.services || [];

          this.selectedCompanies = selectedValues.company
            ? [selectedValues.company]
            : [];
          this.selectedLocalizations = selectedValues.localization
            ? [selectedValues.localization]
            : [];
          this.selectedServices = selectedValues.service
            ? [selectedValues.service]
            : [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    initializeState() {
      if (!this.hasSessionData('spacesToCreate')) {
        this.$router.push({ name: 'availablespaces' });
      }

      const sessionData = this.loadSessionData('spacesToCreate');

      const viewState = {
        hours: {
          duration: 30,
          from: moment().hours(7).minutes(0).format(),
          to: '',
          wasHourToTouched: false,
          ignored: [],
        },
        startDate: moment().format(),

        spaceLimit: 0,
        totalSpaces: 0,

        spaceCalculatedData: {},

        createSpaceSetup: {},

        specialistsWithCreatedSpaces: {},

        recurrence: {
          active: false,
          frequency: FrequencyTypes.Daily,
          repeatAfter: 1,
          availableFrecuencyTypes: [
            FrequencyTypes.Daily,
            FrequencyTypes.Weekly,
            FrequencyTypes.Monthly,
            FrequencyTypes.Annually,
          ],
          repeatEachTexts: [
            { text: 'day', value: 1 },
            { text: 'week', value: 2 },
            { text: 'month', value: 3 },
            { text: 'year', value: 4 },
          ],

          selectedWeekDays: [0, 1, 2, 3, 4, 5, 6],
          mainWeekDays: [1, 2, 3, 4, 5],

          finish: {
            occurrences: 1, // selectedTimes
            modes: [
              // options
              { text: 'finishAfter', value: 1 },
              { text: 'finishOnThisDay', value: 2 },
            ],
            selectedMode: 1, // selectedOption
            endDate: null,
          },
          ignoredDates: [],
        },

        isSpecialistsCollapsed: true,
      };

      const result = { ...sessionData, ...viewState };

      if (this.hasLocalData('IgnoredHours')) {
        result.hours.ignored = this.loadLocalData('IgnoredHours');
      }

      if (this.hasLocalData('IgnoredDates')) {
        result.recurrence.ignoredDates = this.loadLocalData('IgnoredDates');
      }

      return result;
    },
    setOfficeTimes() {
      if (this.selectedLocalizations.length) {
        let officeOpenTime = this.selectedLocalizations[0].officeOpenTime;
        const openTime = moment().toDate();
        openTime.setHours(7);
        openTime.setMinutes(0);

        this.officeOpenTime = this.$moment(
          officeOpenTime || openTime,
          'HH:mm:ss',
        ).format();

        let officeCloseTime = this.selectedLocalizations[0].officeCloseTime;
        const closeTime = moment().toDate();
        closeTime.setHours(19);
        closeTime.setMinutes(0);

        this.officeCloseTime = this.$moment(
          officeCloseTime || closeTime,
          'HH:mm:ss',
        ).format();
      }
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadSessionData('SpacesCreate');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      // default init
    }

    this.setOfficeTimes();

    _getSpaceCreationSetup()
      .then((result) => {
        if (result.data) {
          this.createSpaceSetup = result.data;
          if (!this.recurrence.finish.endDate)
            this.recurrence.finish.endDate = this.limitDate.format();
        }
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
};
</script>

<style scoped lang="scss">
.left-divider {
  border-left: 1px solid $color-label-default;
  opacity: 1;
}

.c-createSpaces {
  width: 100%;
  padding: 15px;
  background-color: $color-app-background;

  h2 {
    font-family: 'lato-bold', sans-serif;
    font-size: 24px;
    color: $color-font-primary;
  }

  .c-s-createSpaces {
    width: 100%;
    background-color: $color-white;
    border-radius: 10px;
    padding: 25px;
    margin: 15px 0 0 0;
    h6 {
      font-family: 'lato-bold', sans-serif;
      font-size: 16px;
      color: $color-primary;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;
    }
    .c-createSpaces-form {
      &.container-fluid {
        padding: 0;
      }
      .c-generalFilters,
      .c-hourlyFilters {
        display: flex;

        @media only screen and (max-width: 600px) {
          flex-direction: column;
        }
      }
    }
  }

  .label-default {
    background-color: $color-label-default;
  }

  .label {
    padding: 0.2em 0.6em 0.3em;
    font-size: 100%;
    font-weight: bold;
    line-height: 1;
    color: $color-white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .label-round {
    border-radius: 1.25em;
    padding-right: 5x;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    width: 60%;
  }

  [data-role='remove'] {
    margin-left: 8px;
    cursor: pointer;
  }

  [data-role='remove']:after {
    content: 'x';
    padding: 0px 2px;
  }

  .fs-18 {
    font-size: 18px !important;
  }
}

.back-container {
  padding: 10px;
}
.back-container__icon {
  font-size: 40px;
}
.back-container__title {
  font-weight: bold;
  font: bold bold medium 14px/29px Lato;
  margin-top: 20px;
}
.back-container__message {
  font: normal normal medium 14px/29px Lato;
  margin-top: 20px;
}
.space-verify-text {
  color: red;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
}
.space-verify-icon {
  color: red;
  font-size: 50px;
}
</style>
