<template>
  <div>
    <custom-modal
      id="addMacAddressModal"
      ref="addMacAddressModal"
      :no-close-on-back-drop="true"
      size="m"
      :title="$t('assignMacAddress')"
    >
      <validation-observer ref="ModalRef" novalidate>
        <b-row>
          <b-col sm="8">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:17|max:17"
            >
              <base-input
                v-model="newMacAddress"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('enterTheMacAddress')"
                mask-type="MacAddress"
              />
            </validation-provider>
          </b-col>
          <b-col class="text-right pt-sm-1" sm="4">
            <b-button
              class="mt-sm-4"
              variant="outline-primary"
              @click="add(newMacAddress)"
            >
              <i class="far fa-plus-square" /> {{ $t('add') }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row class="mt-4">
        <b-col cols="12" style="padding: 0 30px">
          <b-row v-if="value.length" class="macadddress-list py-3">
            <b-col v-for="(item, index) in value" :key="index" cols="12">
              <b-row v-if="!item.isEditing">
                <b-col sm="7">
                  <span>
                    {{ item.macAddress }}
                  </span>
                </b-col>
                <b-col class="text-right" sm="5">
                  <b-button
                    class="mr-2 btn-actions"
                    size="sm"
                    variant="info"
                    @click="edit(item)"
                  >
                    <i class="far fa-edit" />
                    {{ $t('edit') }}
                  </b-button>
                  <b-button
                    class="btn-actions"
                    size="sm"
                    variant="outline-danger"
                    @click="remove(item)"
                  >
                    <i class="far fa-save" /> {{ $t('delete') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="item.isEditing">
                <b-col sm="7">
                  <validation-observer :ref="'voEdit' + index" novalidate>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|min:17|max:17"
                    >
                      <base-input
                        v-model="item.macAddress"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        mask-type="MacAddress"
                      />
                    </validation-provider>
                  </validation-observer>
                </b-col>
                <b-col class="pt-2 text-right" sm="5">
                  <b-button
                    class="mr-2 btn-actions"
                    size="sm"
                    variant="info"
                    @click="edit(item, index)"
                  >
                    <i class="far fa-edit" />
                    {{ $t('save') }}
                  </b-button>

                  <b-button
                    class="btn-actions"
                    size="sm"
                    variant="outline-danger"
                    @click="cancelEdit(item)"
                  >
                    <i class="fas fa-ban" /> {{ $t('cancel') }}
                  </b-button>
                </b-col>
              </b-row>
              <hr v-if="index != value.length - 1" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #footer>
        <b-row>
          <b-col class="text-right mt-2 pr-0" cols="12">
            <b-button variant="primary" @click="onMacAddressContinue()">
              <i class="fal fa-arrow-alt-circle-right" /> {{ $t('continue') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'MacAddressManager',
  components: {
    CustomModal,
    BaseInput,
  },
  props: {
    id: {
      type: String,
      default: () => 'puMacAddress',
      meta: {
        description: 'Id of the tag',
        example: 'puMacAddress',
      },
    },
    name: {
      type: String,
      default: () => 'puMacAddress',
      meta: {
        description: 'Name of the tag',
        example: 'puMacAddress',
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
    onMacAddressValidating: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      newMacAddress: null,
      editMacAddress: null,
    };
  },
  computed: {
    currentMask() {
      if (!this.maskType) return { id: '', mask: '' };

      let mask = this.maskTypes.find((x) => x.id == this.maskType);
      return mask != undefined ? mask : { id: '', mask: '' };
    },
  },
  methods: {
    show() {
      this.newMacAddress = null;

      this.$refs.addMacAddressModal.show();
    },
    async add(item) {
      if (!(await this.$refs.ModalRef.validate())) return;

      if (
        !item ||
        this.value.some((x) => x.macAddress.toUpperCase() == item.toUpperCase())
      ) {
        this.ShowToast('Error', `${this.$t('macAddressRepeated')}`, 'error');
        return;
      }

      if (
        this.onMacAddressValidating &&
        !(await this.onMacAddressValidating(item))
      ) {
        return;
      }

      this.value.push({
        macAddress: item,
        isEditing: false,
      });

      this.newMacAddress = null;

      this.$refs.ModalRef.reset();
    },
    async edit(item, index) {
      if (item.isEditing) {
        if (!(await this.$refs['voEdit' + index][0].validate())) return;

        var valueArr = this.value.map((item) => {
          return item.macAddress;
        });

        var isDuplicate = valueArr.some((item, idx) => {
          return valueArr.indexOf(item) != idx;
        });

        if (isDuplicate) {
          this.ShowToast('Error', `${this.$t('macAddressRepeated')}`, 'error');
          return;
        }

        if (
          this.onMacAddressValidating &&
          !(await this.onMacAddressValidating(item.macAddress))
        ) {
          return;
        }

        this.$set(item, 'isEditing', false);
      } else {
        this.value.forEach((x) => {
          if (x.macAddress != item.macAddress) {
            this.$set(x, 'isEditing', false);
          }
        });

        this.$set(item, 'isEditing', true);
        this.editMacAddress = item.macAddress;
      }
    },
    async cancelEdit(item) {
      item.macAddress = this.editMacAddress;
      item.isEditing = false;
    },
    async remove(item) {
      const idx = this.value.indexOf(item);

      if (idx >= 0) {
        this.value.splice(idx, 1);
      }
    },
    onMacAddressContinue() {
      this.value.forEach((x) => (x.isEditing = false));
      this.$refs.addMacAddressModal.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.macadddress-list {
  border: 1px solid $color-label-default !important;
  border-radius: 5px;
}
.btn-actions {
  font: normal normal normal 10px Lato;
  padding: 5px;
}
::v-deep .c-filterMultiSelect {
  margin-bottom: 0;
}
</style>
