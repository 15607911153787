<template>
  <div
    class="pr-0 pl-0 c-filterSpecialists"
    fluid
  >
    <div class="collapse-container">
      <div class="acordion-title">
        <span
          class="title-description"
        ><i
           class="collapse-button fal text-dark pointer"
           :class="[{'fa-plus-circle' : isCollapsed},{'fa-minus-circle' : !isCollapsed}]"
           @click="isCollapsed = !isCollapsed"
         />
          {{ title }}</span>
      </div>
      <b-collapse
        id="specialist-selection-collapse"
        :visible="!isCollapsed"
      >
        <div class="top-divider mb-3" />
        <b-row
          id="specialist-selection"
          class="accordion-content collapse d-flex flex-row justify-content-center p-3"
        >
          <b-col
            cols="12"
            lg="3"
            sm="5"
          >
            <div class="items-selection availables-items">
              <div class="text-left mb-3">
                <strong>{{ $t('availablespecialist') }}</strong>
              </div>
              <div class="form-group selectable-items mb-3">
                <select
                  id="availables-items"
                  v-model="leftOptionsSelectedIds"
                  class="form-control p-3"
                  multiple
                  name="availabes-items"
                >
                  <option
                    v-for="specialistOption in specialistsOptions"
                    :key="specialistOption.id"
                    :value="specialistOption.id"
                  >
                    {{ specialistOption.name }}
                  </option>
                </select>
              </div>
              <div class="options d-flex justify-content-between">
                <span
                  class="select-all"
                  @click="selectAllLeftOptions"
                >{{ $t('selectall') }}</span>
                <span
                  class="available-count"
                >{{ $t('available') }} ({{
                  specialistsOptions != undefined
                    ? specialistsOptions.length
                    : 0
                }})</span>
              </div>
            </div>
          </b-col>
          <b-col
            class="mb-4 selection-actions-container pr-sm-0 pl-sm-0"
            cols="12"
            lg="1"
            sm="2"
          >
            <div class="selection-actions align-self-center">
              <button
                class="add-items btn-block btn btn-sm btn-primary mb-3 pr-sm-0 pl-sm-0"
                type="button"
                @click="addSpecialist"
              >
                {{ $t('add') }} <span class="fal fa-chevron-right" />
              </button>
              <button
                class="remove-items btn-block btn btn-sm btn-secondary pr-sm-0 pl-sm-0"
                type="button"
                @click="removeSpecialist"
              >
                <span class="fal fa-chevron-left" /> {{ $t('remove') }}
              </button>
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            sm="5"
          >
            <div class="items-selection selected-items">
              <div class="text-left mb-3">
                <strong>{{ $t('selectedspecialist') }}</strong>
              </div>
              <div class="form-group mb-3 selectable-items">
                <select
                  id="selected-items"
                  v-model="rightOptionsSelectedIds"
                  class="form-control p-3"
                  multiple
                  name="selected-items"
                >
                  <option
                    v-for="selectedSpecialist in value"
                    :key="selectedSpecialist.id"
                    :value="selectedSpecialist.id"
                  >
                    {{ selectedSpecialist.name }}
                  </option>
                </select>
              </div>
              <div class="options d-flex justify-content-between">
                <span
                  class="select-all"
                  @click="selectAllRightOptions"
                >{{ $t('selectall') }}</span>
                <span
                  class="selected-count"
                >{{ $t('selected') }} ({{
                  value != undefined
                    ? value.length
                    : 0
                }})</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <error
      v-if="error"
      class="mt-2 pt-0"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </div>
</template>
<script>

import Error from '@/components/basics/error/CustomError.vue';

export default {
  components: {
    Error
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    availableSpecialists: {
      type: Array,
      default: ()=> []
    },
    collapsed: {
      type: Boolean,
      default: () => true
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state'
      }
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: "Error Message",
        example: "This field is required",
      }
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    specialistsOptions(){
      return this.availableSpecialists.filter((e)=>{
        return !this.value.some((el)=>{
          return el.id === e.id;
        });
      });
    }
  },
  data () {
    return {
      leftOptionsSelectedIds: [],
      rightOptionsSelectedIds: this.value,
      isCollapsed: this.collapsed
    };
  },
  watch: {
    collapsed: function (value) {
      this.isCollapsed = value;
    },
    value (newVal, oldVal) {
      if (newVal != oldVal) {
        this.rightOptionsSelectedIds = newVal;
      }
    }
  },
  methods: {
    selectAllLeftOptions () {
      this.leftOptionsSelectedIds = this.specialistsOptions.map((e)=>e.id);
    },
    selectAllRightOptions () {
      let specialistsToBeSelected = [];
      this.value.forEach((e)=>{
        specialistsToBeSelected.push(e.id);
      });
      this.rightOptionsSelectedIds = specialistsToBeSelected;
    },
    addSpecialist (event) {
      event.preventDefault();
      let specialistsToAdd = [];
      this.availableSpecialists.forEach((e)=>{
        if(this.leftOptionsSelectedIds.includes(e.id)){
          specialistsToAdd.push(e);
        }
      });
      this.$emit('onSpecialistsAdded', specialistsToAdd);
      this.leftOptionsSelectedIds = [];
    },
    removeSpecialist (event) {
      event.preventDefault();

      let specialistsToRemove = [];
      this.availableSpecialists.forEach((e)=>{
        if(this.rightOptionsSelectedIds.includes(e.id)){
          specialistsToRemove.push(e);
        }
      });
      this.$emit('onSpecialistsRemoved', specialistsToRemove);
      this.rightOptionsSelectedIds = [];

    },
  }
};
</script>

<style lang="scss" scoped>
.collapse-container {
  background: $color-app-background;
  border-radius: 10px;
}
.acordion-title {
  text-align: left;
  opacity: 1;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  padding: 18px 10px 18px 18px;
}
.items-selection {
  margin-bottom: 34px;
}
.items-selection select {
  height: 217px !important;
}
.items-selection strong {
  font: normal normal bold 16px/21px Lato !important;
  letter-spacing: 0px;
  color: $color-font-primary;
  opacity: 1;
}
.select-all,
.available-count,
.selected-count,
.selectable-items {
  font: normal normal normal 14px/19px Lato !important;
  color: $color-font-primary !important;
}

.selectable-items {
  select {
    padding-left: 0px !important;
    padding-right: 0px !important;

    option {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
      font: normal normal normal 14px/19px Lato !important;
      color: $color-font-primary !important;
    }
  }
}

.selection-actions {
  font: normal normal bold 14px/19px Lato;
  color: $color-white;
  width: 100%;
}
.select-all {
  text-decoration: underline;
  cursor: pointer;
}

#availables-items {
  padding: 0px !important;
  option{
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: solid $color-label-default 1px;
    white-space: normal;
  }
}

select:-internal-list-box option:checked {
  background-color: $color-primary !important;
}

.selection-actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-divider {
  width: 98%;
  border-top: 1px solid #e0e0e0;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
}

.selectable-items {
  select option:hover,
  select option:focus,
  select option:active,
  select option:checked,
  select option:focus-visible,
  select option:focus-within {
    background: linear-gradient($color-primary, $color-primary);
    background-color: $color-primary !important; /* for IE */
    font: normal normal normal 14px/19px Lato !important;
    color: $color-font-primary !important;
  }
}

select#selected-items option{
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-top: solid $color-label-default 1px;
  white-space: normal;
}

#selected-items{
  padding: 0px !important;
}
</style>
