<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col md="6">
          <base-input
            :disabled="true"
            :label="$t('office')"
            :value="kiosk.locationConfigurationName"
          />
        </b-col>
        <b-col md="6">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="value.specialField"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="name"
              :label="$t('selectSpecialField')"
              :options="validSpecialFields"
            />
          </validation-provider>
        </b-col>
        <b-col v-if="isQueueScreen" md="3">
          <filter-select
            v-model="value.showRowCounter"
            :clearable="false"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('showRowCounter')"
            :options="personInRowOptions"
          />
        </b-col>
        <b-col v-if="isQueueScreen" md="3">
          <validation-provider
            v-if="canDisplayPersonInRow"
            v-slot="{ errors }"
            rules="required"
          >
            <base-input
              :error="errors[0]"
              :error-msg="$t('requiredField')"
              :label="$t('showRowCounterText')"
              :value="value.showRowCounterText"
              @input="value.showRowCounterText = $event"
            />
          </validation-provider>
        </b-col>
        <b-col v-if="!isQueueScreen" md="4">
          <b-form-checkbox
            v-model="value.maintainQueueRelationshipWithService"
            switch
          >
            {{ $t('maintainQueueRelationshipWithService') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import { getSpecialFieldList as _getSpecialFieldList } from '@/services/KioskService';
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import ScreenTypes from '@/constants/KioskScreenTypes';

export default {
  name: 'InputSpecialField',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      specialFields: [],
      validSpecialFields: [],
      personInRowOptions: ConfirmationOptions,
    };
  },
  watch: {
    'value.kioskScreenTypeId'() {
      this.loadValidSpecialFields();
    },
  },
  computed: {
    canDisplayPersonInRow() {
      return this.value?.showRowCounter;
    },
    isQueueScreen() {
      return this.value?.kioskScreenTypeId === ScreenTypes.Queue;
    },
  },
  mounted() {
    _getSpecialFieldList(this.kiosk.locationConfigurationId)
      .then((response) => {
        this.specialFields = response.data;
        this.loadValidSpecialFields();
        const specialField = this.specialFields.find(
          (x) => x.name === this.value.specialField,
        );
        if (!specialField) this.value.specialField = null;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  methods: {
    loadValidSpecialFields() {
      this.validSpecialFields = this.specialFields.filter(
        (x) =>
          !this.kiosk.screens
            .filter(
              (y) =>
                y.kioskScreenTypeId == this.value.kioskScreenTypeId &&
                y.kioskScreenId !== this.value.kioskScreenId,
            )
            .map((y) => y.specialField)
            .includes(x.name),
      );
    },
  },
};
</script>
