<template>
  <div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="kioskNameIsNotCorrect"
      :item="selectedKiosk"
      label="kioskName"
      @on-confirm="onConfirmDelete()"
    />
    <content-header :title="$t('kioskmaintenance')" />
    <div class="container-fluid">
      <b-container class="main-page-container" fluid>
        <b-row>
          <sub-header :title="$t('filterkiosks')" />
        </b-row>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-3"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getKiosks(1)"
        />
      </b-container>
      <separator class="my-3" :text="$t('kioskregistered')" />
      <b-row fluid>
        <b-col sm="12">
          <b-button
            class="text-capitalize float-right"
            variant="primary"
            @click="goToCreateKiosk"
          >
            <i class="fas fa-plus" /> {{ $t('create') }}
          </b-button>
          <b-button
            class="text-capitalize float-right mr-2"
            variant="outline-success"
            @click="downloadInstaller"
          >
            <i class="fal fa-download" /> {{ $t('downloadInstaller') }}
          </b-button>
        </b-col>
        <b-col
          v-for="(kiosk, index) in kiosks.data"
          :key="index"
          class="mt-3"
          cols="12"
        >
          <search-result
            :value="kiosk"
            @on-clone="onCloneKiosk"
            @on-delete="onDeleteKiosk(kiosk)"
            @on-edit="onEditKiosk"
            @on-show-preview="onShowPreview"
          />
        </b-col>
      </b-row>
      <custom-pagination
        v-if="kiosks.count > 0"
        id="pgPager"
        :data="kiosks"
        :page-size="pageSize"
        @pagination-go-page="getKiosks($event)"
        @pagination-rows-per-page="getKiosks(pageIndex, $event)"
      />

      <CustomNotFound
        v-if="filtered && !kiosks.data.length"
        :text="$t('noKioskFoundForFilter')"
      />

      <preview-modal ref="preview-modal-ref" :title="previewTitle" />
    </div>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult';
import {
  getKiosks as _getKiosks,
  getMacInstaller,
  deleteKiosk as _deleteKiosk,
} from '@/services/KioskService';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationListForKiosk as _getLocationListForKiosk } from '@/services/LocationService';
import CustomPagination from '@/components/CustomPagination.vue';
import contentHeader from '@/components/Header';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import PreviewModal from '@/components/PreviewModal.vue';
import SubHeader from '@/components/SubHeader.vue';
import { KIOSK } from '@/config';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'RegisteredKiosks',
  components: {
    SearchResult,
    FilterManager,
    CustomPagination,
    Separator,
    contentHeader,
    CustomNotFound,
    PreviewModal,
    SubHeader,
    DeleteModal,
  },
  data() {
    return {
      filters: {
        location: [],
        companies: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        name: '',
      },
      selectedKioskId: null,
      kiosks: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      macInstallerUrl: '',
      pageSize: 10,
      filtered: false,
      showDeleteModal: false,
      selectedKiosk: null,
      previewTitle: null,
      pageIndex: 1
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.location,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          const collections = response.data;
          this.filters.companies = collections || [];
          if (this.filters.companies.length == 1) {
            this.getAvailableLocalizations(
              this.selectedFilters.company[0].value,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await getMacInstaller().then((resp) => {
        this.macInstallerUrl = resp.data;
      });
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationListForKiosk(companyId)
        .then((response) => {
          this.filters.location = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.filters.location = [];
      this.selectedFilters.company = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getAvailableLocalizations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (!value) {
        this.selectedFilters.location = [];
        return;
      }
      this.selectedFilters.location = [value];
    },
    async getKiosks(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        LocationConfigurationId:
          this.selectedFilters.location.length > 0
            ? this.selectedFilters.location[0].id
            : null,
        Name: this.selectedFilters.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getKiosks(payload)
        .then(({ data }) => {
          this.kiosks = data;
          this.filtered = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async goToCreateKiosk() {
      // Persist current state of this view
      this.saveSessionData('kiosksData', Object.assign({}, this.$data));

      this.$router.push({ name: 'kioskCreate' });
    },
    async onEditKiosk(kiosk) {
      // Persist current state of this view
      this.saveSessionData('kiosksData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'kioskEdit',
        params: { id: kiosk.kioskId },
      });
    },
    async onCloneKiosk(kiosk) {
      // Persist current state of this view
      this.saveSessionData('kiosksData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'kioskClone',
        params: { id: kiosk.kioskId },
      });
    },
    downloadInstaller() {
      let downloadWindow = window.open(this.macInstallerUrl);
      if (downloadWindow) {
        this.ShowSuccessToast(
          this.$t('downloadStartedSuccessful'),
          this.$t('findDownloadLocally'),
        );
      } else {
        this.ShowToast(
          this.$t('downloadError'),
          this.$t('downloadErrorMessage'),
          'error',
        );
      }
    },
    onShowPreview(kiosk) {
      if (kiosk.macAddressList && kiosk.macAddressList != '') {
        const splitIndex = kiosk.macAddressList.indexOf(',');

        // Show the modal
        this.previewTitle = kiosk.name;

        this.$refs['preview-modal-ref'].show(
          `${KIOSK.BASE_URL}?macAddress=${encodeURIComponent(
            kiosk.macAddressList.slice(
              0,
              splitIndex == -1 ? kiosk.macAddressList.length : splitIndex,
            ),
          )}&preview=1`,
        );
      }
    },
    onDeleteKiosk(kiosk) {
      this.showDeleteModal = true;
      this.selectedKiosk = kiosk;
    },
    onConfirmDelete() {
      _deleteKiosk(this.selectedKiosk.kioskId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getKiosks();
          this.selectedKiosk = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('kiosksData');
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
};
</script>

<style lang="scss">
#preview-modal-ref > .modal-dialog {
  margin: 0;
  margin-left: 8%;
}

#preview-modal-ref > .modal-dialog .modal-content {
  min-width: 1280px;
  min-height: 846px;
  overflow: hidden;
}

#frmPreviewContainer {
  width: 100%;
  height: 95%;
  position: absolute;
  overflow: hidden;
}
</style>
