<template>
  <b-row>
    <b-col
      md="6"
      :xl="showColumnsQuantity || showDisplayKeyboard ? 4 : 6"
    >
      <validation-provider
        v-slot="{ errors }"
        rules="max:256"
      >
        <base-input
          v-model="value.topText"
          :error="errors[0]"
          :error-msg="$t('invalidField')"
          :label="`${$t('topText')} ESP`"
        />
      </validation-provider>
    </b-col>
    <b-col
      md="6"
      :xl="showColumnsQuantity || showDisplayKeyboard ? 4 : 6"
    >
      <validation-provider
        v-slot="{ errors }"
        rules="max:256"
      >
        <base-input
          v-model="value.topTextEN"
          :error="errors[0]"
          :error-msg="$t('invalidField')"
          :label="`${$t('topText')} ENG`"
        />
      </validation-provider>
    </b-col>
    <b-col
      v-if="showColumnsQuantity"
      lg="3"
      md="6"
      xl="2"
    >
      <filter-numeric
        v-model="value.columnsQuantity"
        :max="6"
        :min="1"
        :title="$t('columnsQuantity')"
      />
    </b-col>
    <b-col
      v-if="showDisplayKeyboard"
      lg="3"
      md="6"
      xl="2"
    >
      <filter-select
        v-model="value.displayKeyboard"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('displayKeyboard')"
        :options="confirmationOptions"
        translate
      />
    </b-col>
    <b-col
      :lg="showColumnsQuantity || showDisplayKeyboard ? 3 : 4"
      md="6"
      :xl="showColumnsQuantity || showDisplayKeyboard ? 2 : 4"
    >
      <filter-color
        v-model="value.topTextColor"
        :label="$t('topTextColor')"
      />
    </b-col>
    <b-col
      :lg="showColumnsQuantity || showDisplayKeyboard ? 3 : 4"
      md="6"
      xl="4"
    >
      <filter-numeric
        v-model="value.topTextSize"
        :min="0"
        :title="$t('topTextSize')"
        measurement-unit="pt"
      />
    </b-col>
    <b-col
      :lg="showColumnsQuantity || showDisplayKeyboard ? 3 : 4"
      md="6"
      xl="4"
    >
      <filter-select
        v-model="value.topTextBoldPercentage"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('topTextBoldPercentage')"
        :options="fontWeightOptions"
        translate
      />
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import ScreenTypes from '@/constants/KioskScreenTypes';
import FontWeightOptions from '@/constants/FontWeightOptions';
import ConfirmationOptions from '@/constants/ConfirmationOptions';

export default {
  name: 'ScreenHeader',
  components: {
    BaseInput
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fontWeightOptions: FontWeightOptions,
      confirmationOptions: ConfirmationOptions,
    }
  },
  computed: {
    showColumnsQuantity() {
      return [ScreenTypes.Button, ScreenTypes.Queue, ScreenTypes.Service].includes(this.value.kioskScreenTypeId) ;
    },
    showDisplayKeyboard() {
      return [ScreenTypes.Client, ScreenTypes.CheckIn].includes(this.value.kioskScreenTypeId) ;
    },
  }
};
</script>
