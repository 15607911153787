<template>
  <div class="gauge-container p-3">
    <div class="row header pt-2 pb-2">
      <h4>{{ title }}</h4>
    </div>
    <div class="row body">
      <vue-svg-gauge
        :end-angle="90"
        :gauge-color="[
          { offset: 0, color: color },
          { offset: 100, color: color },
        ]"
        :inner-radius="75"
        :max="100"
        :min="0"
        :scale-interval="0"
        :separator-step="0"
        :start-angle="-90"
        :value="value"
      >
        <div class="inner-text">
          <span v-if="!valueText">{{ value }}</span>
          <span v-else>{{ valueText }}</span>
        </div>
      </vue-svg-gauge>
    </div>
    <div v-if="showLeftData || showRightData" class="row footer pt-3">
      <div v-if="showLeftData" class="col-sm-4 p-0 text-center">
        <h4>{{ leftDataValue }}</h4>
        <span>{{ leftDataText }}</span>
      </div>
      <div class="col-sm-4 p-0 text-center">
        <h4 v-if="showCenterDataValue">{{ centerDataValue }}</h4>
        <span>{{ centerDataText }}</span>
      </div>
      <div v-if="showRightData" class="col-sm-4 p-0 text-center">
        <h4>{{ rightDataValue }}</h4>
        <span>{{ rightDataText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge';

export default {
  data() {
    return {};
  },
  components: { VueSvgGauge },
  props: {
    id: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    value: {
      type: Number,
      default: () => 0,
    },
    valueText: {
      type: String,
      default: () => '',
    },
    showLeftData: {
      type: Boolean,
      default: () => true,
    },
    showRightData: {
      type: Boolean,
      default: () => true,
    },
    leftDataValue: {
      type: Number,
      default: () => 0,
    },
    showCenterDataValue: {
      type: Boolean,
      default: () => true,
    },
    centerDataValue: {
      type: Number,
      default: () => 0,
    },
    rightDataValue: {
      type: Number,
      default: () => 0,
    },
    leftDataText: {
      type: String,
      default: () => '',
    },
    centerDataText: {
      type: String,
      default: () => '',
    },
    rightDataText: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => '#f37e00',
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click');
    },
  },
};
</script>

<style scoped lang="scss">
.inner-text {
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  margin-top: 52px;
  span {
    max-width: 100px;
    font-size: 32px;
  }
}

.inner-text .sub-text {
  clear: both;
}

.gauge-container .header {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gauge-container {
  max-width: 321px;
}

.gauge-container h4 {
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.gauge-container .gauge {
  max-width: 213px;
  max-height: 123px;
  margin-left: auto;
  margin-right: auto;
}
</style>
