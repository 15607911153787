<template>
  <validation-observer ref="buttonsFormRef" novalidate>
    <b-row>
      <b-col class="my-4" cols="12">
        <h4 class="font-weight-bolder float-left">
          {{ $t('buttons') }}
        </h4>
        <hr />
      </b-col>
      <b-col
        v-for="(item, index) in buttons"
        :key="index"
        class="mb-3"
        cols="12"
      >
        <b-card border-variant="light">
          <b-row>
            <b-col lg="3" md="6">
              <validation-provider v-slot="{ errors }" rules="required|max:64">
                <base-input
                  v-model="item.buttonText"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="`${$t('buttonText')} ${index + 1} ESP`"
                />
              </validation-provider>
            </b-col>
            <b-col lg="2" md="6">
              <validation-provider v-slot="{ errors }" rules="max:64">
                <base-input
                  v-model="item.buttonTextEN"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="`${$t('buttonText')} ${index + 1} ENG`"
                />
              </validation-provider>
            </b-col>
            <b-col lg="3" md="6">
              <filter-select
                v-model="item.redirectKioskId"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('kiosk')"
                :options="relations"
                @input="item.redirectScreenId = null"
              />
            </b-col>
            <b-col lg="3" md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="item.redirectScreenId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="kioskScreenId"
                  :label="`${$t('button')} '${item.buttonText || ''}' ${$t(
                    'sendTo',
                  )}`"
                  :options="screenOptions(item.redirectKioskId)"
                />
              </validation-provider>
            </b-col>
            <b-col class="text-right" lg="1" md="6">
              <b-button
                class="mt-4"
                variant="outline-danger"
                @click="onRemoveButton(index)"
              >
                <i class="fas fa-trash" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col class="mt-1" cols="12">
        <b-button class="float-right" size="sm" @click="onAddButton()">
          <i class="far fa-plus-square" />
          {{ $t('addButton') }}
        </b-button>
      </b-col>
      <b-col class="mt-2 text-right" cols="12">
        <b-button
          :disabled="!buttons.length"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('saveButtons') }}
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  submitScreenButtons as _submitScreenButtons,
  getRelations as _getRelations,
  getRelationScreens as _getRelationScreens,
} from '@/services/KioskService';
import ScreenTypes from '@/constants/KioskScreenTypes';

export default {
  name: 'ScreenButtons',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    screens: {
      type: Array,
      default: () => [],
    },
    screenNumber: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      buttons: [],
      relations: [],
      relationScreens: [],
    };
  },
  watch: {
    'value.buttons': {
      immediate: true,
      handler() {
        this.buttons = [...this.value.buttons];
      },
    },
  },
  async mounted() {
    await _getRelations(this.value.kioskId).then(
      ({ data }) => (this.relations = data),
    );

    await _getRelationScreens(this.value.kioskId).then(
      ({ data }) => (this.relationScreens = data),
    );
  },
  methods: {
    screenOptions(kioskId) {
      if (!kioskId)
        return this.screens.filter((_, i) => i !== this.screenNumber - 1);

      return this.orderScreens(
        this.relationScreens.find((x) => x.kioskId == kioskId)?.screens || []
      );
    },
    orderScreens(screens) {
      const baseScreenTypeIds = [ScreenTypes.Notification, ScreenTypes.Turn];

      const optionalScreens = screens.filter(
        (x) => !baseScreenTypeIds.includes(x.screenTypeId),
      );

      const baseScreens = screens
        .filter((x) => baseScreenTypeIds.includes(x.screenTypeId))
        .sort((a, b) => {
          if (a.screenTypeId > b.screenTypeId) return 1;
          if (a.screenTypeId < b.screenTypeId) return -1;
          return 0;
        });

      return optionalScreens.concat(baseScreens).map((screen, index) => {
        const name = !baseScreenTypeIds.includes(screen.screenTypeId)
          ? `${this.$t('screen')} ${index + 1}`
          : '';

        return {
          kioskScreenId: screen.id,
          name: `${name}${name && screen.name ? ' - ' : ''}${
            screen.name || ''
          }`,
          kioskScreenTypeId: screen.screenTypeId
        };
      });
    },
    onAddButton() {
      this.buttons.push({
        kioskScreenId: this.value.kioskScreenId,
        buttonText: null,
        buttonTextEN: null,
        redirectScreenId: null,
        redirectKioskId: null,
      });
    },
    onRemoveButton(index) {
      this.buttons.splice(index, 1);
    },

    async onSubmit() {
      if (!(await this.$refs.buttonsFormRef.validate())) return;
      _submitScreenButtons({
        kioskId: this.value.kioskId,
        kioskScreenId: this.value.kioskScreenId,
        buttons: this.buttons,
      })
        .then(() => {
          this.value.buttons = [...this.buttons];
          this.$emit('submitted');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
