<template>
  <validation-observer ref="logoFormRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:100">
          <base-input
            v-model="model.description"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('description')"
            :max-length="100"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <validation-provider v-slot="{ errors }" rules="required|max:50">
          <base-input
            v-model="model.key"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('domain')"
            :max-length="50"
          />
        </validation-provider>
      </div>
      <div class="col-sm-12 col-md-6">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.alternativeKey"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('subcode')"
            :max-length="50"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <custom-image-input
          v-model="model.image"
          :auto-delete="false"
          :file="model.imageFile"
          :label="$t('addLogo')"
          @clearFile="onDeleteLogo()"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12 col-md-6">
        <b-form-checkbox
          v-model="model.active"
          class="mt-2 chk-available"
          switch
        >
          {{ $t('active') }}
        </b-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import CustomImageInput from '@/components/CustomImageInput';

import { 
  createLogo as _createLogo,
  updateLogo as _updateLogo,
  deleteLogoImage as _deleteLogoImage
} 
from '@/services/LogoService';

export default {
  name: 'LogoForm',
  components: { 
    BaseInput,
    CustomImageInput
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      model: this.value ? this.value : {
        id: null,
        key: null,
        description: null,
        active: true,
        alternativeKey: null,
        storagePath: null,
        image: null,
        imageFile: null,
      },
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.logoFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = { ...this.model };

      payload.imageFile = this.model.image;

      if(!payload.id) {
        _createLogo(payload)
          .then(() => {
            this.ShowSuccessSaveToast();

            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      } else {
        _updateLogo(payload)
          .then(() => {
            this.ShowSuccessSaveToast();

            this.$emit('close');
            this.$emit('submitted');
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async onDeleteLogo() {
      if(this.model.storagePath)
      {
        await _deleteLogoImage(this.model.id);

        this.ShowSuccessSaveToast();
      }

      this.model.image = null;
      this.model.imageFile = null;
      this.model.storagePath = null;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
