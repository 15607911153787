<template>
  <div>
    <table class="table table-striped table-bordered table-hover table-sm">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">{{ $t('company') }}</th>
          <th scope="col">{{ $t('name') }}</th>
          <th scope="col">{{ $t('status') }}</th>
          <th scope="col">{{ $t('percCompleted') }}</th>
          <th scope="col">{{ $t('recordsProcessed') }}</th>
          <th scope="col">{{ $t('totalRecords') }}</th>
          <th scope="col">{{ $t('startDate') }}</th>
          <th scope="col">{{ $t('endDate') }}</th>
        </tr>
      </thead>
      <tbody v-if="noResultsFound">
        <tr>
          <td colspan="9">
            <CustomNotFound :text="$t('noResultsFound')" />
          </td>
        </tr>
      </tbody>
      <tbody v-if="!noResultsFound">
        <tr
          v-for="(item, index) in value.data"
          :key="index"
          class="mt-3 col-12"
        >
          <th scope="row">{{ item.processId }}</th>
          <td>{{ item.companyName }} ({{ item.companyId }})</td>
          <td>{{ item.name }}</td>
          <td>{{ $t('processStatus_' + item.status) }}</td>
          <td>
            <div class="progress">
              <div
                class="progress-bar"
                :class="{
                  pending: item.percentageCompleted == 0,
                  'in-progress progress-bar-striped progress-bar-animated':
                    item.percentageCompleted > 0 &&
                    item.percentageCompleted < 100,
                  finalized: item.percentageCompleted == 100,
                }"
                role="progressbar"
                :aria-valuenow="item.percentageCompleted"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{ width: item.percentageCompleted + '%' }"
              >
                {{ item.percentageCompleted }}%
              </div>
            </div>
          </td>
          <td>{{ item.affectedRecords }}</td>
          <td>{{ item.totalRecords }}</td>
          <td>
            {{
              $moment(item.createdDate).format('dddd D/MMMM/YYYY hh:mm:ss A')
            }}
          </td>
          <td>
            {{
              item.finishDate
                ? $moment(item.finishDate).format('dddd D/MMMM/YYYY hh:mm:ss A')
                : ''
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <custom-pagination
      v-if="value.count > 0"
      id="pgPager"
      class="my-4"
      :data="value"
      :page-size="pageSize"
      @pagination-go-page="search($event)"
      @pagination-rows-per-page="search(pageIndex, $event)"
    />
  </div>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';

export default {
  name: 'ProcessLogResults',
  components: {
    CustomPagination,
    CustomNotFound,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    noResultsFound() {
      return this.value.count == 0;
    },
  },
  data() {
    return {
      logRecords: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageIndex: 1,
      pageSize: 10,
    };
  },
  methods: {
    search(pageIndex = 1, pageSize = 10) {
      this.pageSize = pageSize;
      this.pageIndex = pageIndex;

      this.$emit('on-search', pageIndex, pageSize);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    text-align: center;
  }
}
.progress {
  height: 20x;
}
.progress-bar.pending {
  background-color: $color-font-secondary !important;
  width: 100% !important;
}
.progress-bar.in-progress {
  background-color: $color-primary !important;
}
.progress-bar.finalized {
  background-color: $color-success !important;
}
</style>
