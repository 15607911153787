<template>
  <section>
    <content-header :title="$t('processLogs')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterProcessLogs')" />
        </div>
        <validation-observer ref="errorsFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="triggerSearch(1)"
          />
        </validation-observer>
      </div>
      <separator class="my-4" :text="$t('processesList')" />
      <search-results
        v-model="records"
        ref="dtResults"
        :filters="selectedFilters"
        @on-search="search"
      />
    </div>
  </section>
</template>

<script>
import { modulesName } from '@/store';
import { mapState } from 'vuex';

import SearchResults from './components/SearchResults';
import FilterManager from '@/components/FilterManager';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import { getProcesses as _getProcesses } from '@/services/SystemLogsService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';

export default {
  name: 'SystemErrorsList',
  components: {
    SearchResults,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
  },
  data() {
    return {
      selectedFilters: {
        company: null,
        status: null,
        dateFrom: this.$moment().format(),
        dateTo: this.$moment().format(),
      },
      filters: {
        companies: [],
        status: [
          { value: 'P', text: 'Pending' },
          { value: 'R', text: 'Running' },
          { value: 'S', text: 'Success' },
        ],
      },
      records: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
    };
  },
  mounted() {
    this.getCompanies();
    this.search();
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['profile']),
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'dateFrom',
          label: this.$t('dateFrom'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.dateFrom,
          onChanged: this.onDateFromChanged,
        },
        {
          rules: 'required',
          name: 'dateTo',
          label: this.$t('dateTo'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.dateTo,
          onChanged: this.onDateToChanged,
        },
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
          visible: !this.profile.companyId,
        },
        {
          rules: '',
          name: 'status',
          label: this.$t('status'),
          component: 'FilterSelect',
          options: this.filters.status,
          placeholder: `${this.$t('all')}`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.status,
          onChanged: this.onStatusChanged,
        },
      ];
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.company = null;
      if (value) {
        this.selectedFilters.company = value;
      }
    },
    onStatusChanged(value) {
      this.selectedFilters.status = null;
      if (value) {
        this.selectedFilters.status = value;
      }
    },
    async triggerSearch(pageIndex = 1) {
      this.$refs.dtResults.search(pageIndex, 10);
    },
    onDateFromChanged(value) {
      this.selectedFilters.dateFrom = value;
    },
    onDateToChanged(value) {
      this.selectedFilters.dateTo = value;
    },
    async search(pageIndex = 1, pageSize = 10) {
      const isValid = await this.$refs.errorsFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = {
        dateFrom: this.$moment(this.selectedFilters.dateFrom).format(
          'YYYY-MM-DD',
        ),
        dateTo: this.$moment(this.selectedFilters.dateTo).format('YYYY-MM-DD'),
        status: this.selectedFilters.status,
        pageIndex: pageIndex,
        pageSize: pageSize,
        companyId:
          this.selectedFilters.company != null
            ? this.selectedFilters.company
            : null,
      };

      await _getProcesses(payload)
        .then(({ data }) => {
          this.records = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped></style>
