<template>
  <div class="row mt-5">
    <div class="col-md-12">
      <b-card border-variant="light">
        <div class="row">
          <div class="col-md-4">
            <b-form-checkbox
              v-model="model.showConfirmationButton"
              class="mt-4"
              switch
            >
              {{ $t('showConfirmationButton') }}
            </b-form-checkbox>
          </div>
          <div class="col-md-4">
            <filter-select
              v-model="model.confirmationButtonSize"
              fieldtext="text"
              fieldvalue="value"
              :label="`${$t('confirmationButtonSize')}`"
              :options="sizeOptions"
            />
          </div>
          <div class="col-md-4">
            <filter-select
              v-model="model.confirmationButtonPWASize"
              fieldtext="text"
              fieldvalue="value"
              :label="`${$t('confirmationButtonSize')} (PWA)`"
              :options="sizeOptions"
            />
          </div>
          <div class="col-md-6">
            <validation-provider v-slot="{ errors }" rules="max:128">
              <base-input
                v-model="model.confirmationText"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('confirmationText')"
              />
            </validation-provider>
          </div>
          <div class="col-md-3">
            <filter-color
              v-model="model.confirmationColor"
              :label="$t('confirmationTextColor')"
            />
          </div>

          <div class="col-sm-4">
            <base-input
              v-model="model.continueButtonText"
              :label="`${$t('continueButtonText')} ESP`"
            />
          </div>
          <div class="col-sm-4">
            <base-input
              v-model="model.continueButtonTextEN"
              :label="`${$t('continueButtonText')} ENG`"
            />
          </div>
          <div class="col-md-4">
            <filter-select
              v-model="model.redirectScreenId"
              fieldtext="name"
              fieldvalue="kioskScreenId"
              :label="`${$t('button')} ${$t('sendTo')}`"
              :options="screens"
            />
          </div>
          <div class="col-md-12">
            <h3>{{ $t('informationText') }}</h3>
            <editor v-model="model.informationText" :init="editorInit" />
          </div>
          <div class="col-md-12 text-right">
            <b-button
              class="mt-3"
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import { submitScreenType as _submitScreenType } from '@/services/KioskService';
import Editor from '@tinymce/tinymce-vue';
import BaseInput from '@/components/BaseInput';

export default {
  name: 'ScreenInformation',
  components: {
    BaseInput,
    Editor,
  },
  props: {
    kiosk: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    screenNumber: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      options: ConfirmationOptions,
      sizeOptions: [],
      model: {
        informationText: null,
        showConfirmationButton: false,
        confirmationText: null,
        confirmationColor: null,
        redirectScreenId: null,
        continueButtonText: null,
        continueButtonTextEN: null,
        confirmationButtonSize: null,
        confirmationButtonPWASize: null,
      },
      error: '',
    };
  },
  created() {
    this.sizeOptions = [
      {text: this.$t('regular'), value: 1},
      {text: this.$t('medium'), value: 2},
      {text: this.$t('big'), value: 3}
    ];
  },
  watch: {
    value: {
      immediate: true,
      handler(screen) {
        this.model = {
          informationText: screen.informationText,
          showConfirmationButton: screen.showConfirmationButton,
          confirmationText: screen.confirmationText,
          confirmationColor: screen.confirmationColor,
          redirectScreenId: screen.redirectScreenId,
          continueButtonText: screen.continueButtonText,
          continueButtonTextEN: screen.continueButtonTextEN,
          confirmationButtonSize: screen.confirmationButtonSize,
          confirmationButtonPWASize: screen.confirmationButtonPWASize,
        };
      },
    },
  },
  computed: {
    screens() {
      return this.kiosk.screens.filter(
        (_, index) => index != this.screenNumber - 1,
      );
    },
    editorInit() {
      return {
        height: 500,
        menubar: false,
        plugins:
          'advlist autolink lists link image charmap print preview anchor formatpainter searchreplace visualblocks code fullscreen insertdatetime media table paste textcolor',
        toolbar:
          'undo redo | formatselect | forecolor bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist | link image',
      };
    },
  },
  methods: {
    onSubmit() {
      const screen = {
        ...this.value,
        informationText: this.model.informationText,
        showConfirmationButton: this.model.showConfirmationButton,
        confirmationText: this.model.confirmationText,
        confirmationColor: this.model.confirmationColor,
        redirectScreenId: this.model.redirectScreenId,
        continueButtonText: this.model.continueButtonText,
        continueButtonTextEN: this.model.continueButtonTextEN,
        confirmationButtonSize: this.model.confirmationButtonSize,
        confirmationButtonPWASize: this.model.confirmationButtonPWASize,
      };

      _submitScreenType(screen)
        .then(() => {
          this.$emit('input', screen);
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
