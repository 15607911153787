<template>
  <div>
    <div
      v-if="viewType == 1"
      class="calendar-navigator-selector"
    >
      <div
        class="calendar-navigator-selector__action button-before"
        @click="moveBack"
      >
        <em class="fa fa-chevron-left" />
      </div>
      <div class="calendar-navigator-selector__date_range">
        {{ getShortDateDesc(startDate, 'MMMM, yyyy') }}
      </div>
      <div
        class="calendar-navigator-selector__action button-next"
        @click="moveForward"
      >
        <em class="fa fa-chevron-right" />
      </div>
    </div>
    <div
      v-else-if="viewType == 2"
      class="calendar-navigator-selector"
    >
      <div
        class="calendar-navigator-selector__action button-before"
        @click="moveBack"
      >
        <em class="fa fa-chevron-left" />
      </div>
      <div class="calendar-navigator-selector__date_range">
        {{ getShortDateDesc(startDate, 'DD MMMM') }} -
        {{ getShortDateDesc(endDate, 'DD MMMM') }},
        {{ getShortDateDesc(endDate, 'yyyy') }}
      </div>
      <div
        class="calendar-navigator-selector__action button-next"
        @click="moveForward"
      >
        <em class="fa fa-chevron-right" />
      </div>
    </div>
    <div
      v-else-if="viewType == 3"
      class="calendar-navigator-selector"
    >
      <div
        class="calendar-navigator-selector__action button-before"
        @click="moveBack"
      >
        <em class="fa fa-chevron-left" />
      </div>
      <div class="calendar-navigator-selector__date_range">
        {{ getShortDateDesc(startDate, 'dddd, LL') }}
      </div>
      <div
        class="calendar-navigator-selector__action button-next"
        @click="moveForward"
      >
        <em class="fa fa-chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { VIEW_ORIENTATION } from '@/components/full-calendar/constants';

export default {
  name: 'CalendarNavigateSelector',
  props: {
    viewType: {
      type: Number,
      default: () => 1
    },
    firstDate: {
      type: [ String, Date ],
      default: function () {
        return new Date();
      },
      required: true
    },
    lastDate: {
      type: [ String, Date ],
      default: function () {
        return new Date();
      },
      required: true
    },
    language: {
      type: String,
      default: function () {
        return 'es';
      },
      required: true
    }
  },
  computed: {
    startDate: function () {
      return typeof this.firstDate === 'string' ? this.$moment(this.firstDate) : this.firstDate;
    },
    endDate: function () {
      return typeof this.lastDate === 'string' ? this.$moment(this.lastDate) : this.lastDate;
    }
  },
  methods: {
    getShortDateDesc (day, format) {
      return this.$moment(day).locale(this.language).format(format);
    },
    moveBack () {
      this.$emit('on-navigate', {
        orientation: VIEW_ORIENTATION.BACK,
        viewType: this.viewType
      });
    },
    moveForward () {
      this.$emit('on-navigate', {
        orientation: VIEW_ORIENTATION.FORWARD,
        viewType: this.viewType
      });
    }
  }
};
</script>

<style scoped lang="scss">
.calendar-navigator-selector {
  display: flex;
  justify-content: flex-end;
  &__date_range {
    text-transform: capitalize;
    font: normal normal normal 16px/28px Lato;
    color: $color-font-success;
    display: inherit;
    align-items: center;
    justify-content: center;
  }
  .button-before {
    margin-right: 19px;
  }
  .button-next {
    margin-left: 19px;
  }
  &__action {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border: 1px solid $color-label-default;
    border: 1px solid $color-label-default;
    border-radius: 4px;
    em {
      color: #ff7058;
    }
  }
}
</style>
