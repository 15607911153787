<template>
  <div
    id="calendar-event-day-container"
    class="calendar-event-day-container__body__weekday"
  >
    <div v-if="!skip" class="item-move-container">
      <span>&nbsp;</span>
    </div>
    <div
      v-else
      class="
        item-move-container
        show-more-items show-items-container show-items-container-counter
      "
      @click="showLessItems"
    >
      <i class="fal fa-angle-double-up" />
      <strong
        >+{{ skip }}
        <span class="text-capitalize">{{
          skip == 1 ? $t('item') : $t('items')
        }}</span>
      </strong>
    </div>
    <div v-for="(item, index) in filteredItems" :key="index" class="data-item">
      <slot :data-index="index" :data-item="item" name="row" />
    </div>
    <div
      v-if="skip + maxItemCountPerColumn >= items.length"
      class="item-move-container"
    >
      <span>&nbsp;</span>
    </div>
    <div
      v-else
      class="
        item-move-container
        show-more-items show-items-container show-items-container-counter
      "
      @click="showMoreItems"
    >
      <i class="fal fa-angle-double-down" />
      <strong
        >+{{ totalItemCount }}
        <span class="text-capitalize">{{
          totalItemCount == 1 ? $t('item') : $t('items')
        }}</span>
      </strong>
    </div>
    <div v-if="totalItemCount == 0" class="start-of-item-list bottom">
      <span>&nbsp;</span>
    </div>

    <slot name="extra" />
  </div>
</template>
<script>
import { MAX_ITEMS_PER_COLUMN } from '@/components/full-calendar/constants';

export default {
  name: 'CalendarEventDay',
  data() {
    return {
      skip: 0,
      take: MAX_ITEMS_PER_COLUMN,
      maxItemCountPerColumn: MAX_ITEMS_PER_COLUMN,
      popoverShow: false,
    };
  },
  mounted() {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    weekDay: {
      type: [Date, Object],
      default: () => new Date(),
    },
    itemClick: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    showMoreItems() {
      this.skip++;

      //This line is to prevent a partial scroll up when the height of the new row changes
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50);
    },
    showLessItems() {
      this.skip--;
    },
    togglePopover() {
      if (this.$slots.popoverContent) {
        this.popoverShow = !this.popoverShow;
      }
    },
    onItemClick(event, item) {
      event.preventDefault();
      event.stopPropagation();
      this.itemClick(item);
    },
  },
  computed: {
    filteredItems() {
      let result = this.items.slice(this.skip, this.skip + this.take);

      return result;
    },
    totalItemCount() {
      if (this.items.length > this.maxItemCountPerColumn) {
        return this.items.length - (this.skip + this.filteredItems.length);
      } else {
        return this.filteredItems.length;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.calendar-event-day-container {
  border: 1px solid $color-label-default;
  border-radius: 5px 0px 0px 0px;
  min-height: 500px;
  &__header {
    background: #f2f2f2;
    height: 131px;

    .calendar-day {
      font: Bold 14px/21px Lato;
      color: $color-font-success;
      width: 100%;
      border: 1px solid $color-label-default;
      .day-short-name {
        color: $color-primary;
        width: 100%;
        text-align: left;
        margin-left: 20%;
      }
      .day-number {
        margin-top: 15px;
        text-align: right;
        letter-spacing: NaNpx;
        opacity: 1;
        font-size: 48px;
        color: $color-font-secondary;
        width: 100%;
        text-align: left;
        margin-left: 20%;
      }
    }
  }
  &__body {
    margin: 0px 0px 0px 0px;
    &__weekday {
      .start-of-space-list {
        background-color: #f2f2f2;
        height: 40px;
        border-bottom: 1px solid $color-label-default;
        border-left: 1px solid $color-label-default;
      }
      .show-spaces-container {
        border-right: 1px solid $color-label-default;

        .show-spaces-container-counter {
          margin-left: -1px;
          border: 1px solid $color-label-default;
          padding-top: 25px;
          padding-bottom: 21px;
        }
      }
      .data-item {
        font-size: 0.6vw;
        background: $color-white 0 0 no-repeat padding-box;
        border-bottom: 1px solid $color-label-default;
        padding: 15px;
        text-align: center;

        .items {
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .start-item-list {
        padding: 3px;
        height: 40px;
        width: 100%;
      }

      background: $color-white 0% 0% no-repeat padding-box;
      border: 1px solid $color-label-default;
      opacity: 1;
    }
  }
}

.show-more-spaces {
  text-align: center;
  width: 228px;
  float: left;
}

.popover-body {
  border: 1px solid $color-label-default;
  opacity: 1;
}

.popover.b-popover {
  border: 1px solid $color-label-default !important;
  opacity: 1 !important;
}

.popover.b-popover.item-detail {
  border: 1px solid $color-primary;
  border-radius: 4px;
  opacity: 1;
  .container-fluid {
    padding-bottom: 10px;
  }
  .main-content-title {
    text-align: center;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: $color-primary;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 7px;
  }
}

.show-more-items {
  background-color: $color-primary !important;
  color: $color-white;
  padding: 3px;
  .fal {
    font-size: 2em;
    font-weight: bold;
  }
  text-align: center;
  cursor: pointer;
  clear: both;
}

.show-more-items strong {
  text-align: center;
  margin-left: 10px;
  font-size: 10pt !important;
  cursor: pointer;
}

.show-more-items strong span {
  font-size: 12pt !important;
  cursor: pointer;
}

.item-move-container {
  background-color: #f2f2f2;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid $color-label-default;
  border-top: 2px solid $color-label-default;
}
</style>
