export const DEFAULT_HOUR_FORMAT = 'hh:mm';

export const HOUR_FORMAT_AM_PM = 'hh:mm A';

export const MAX_ITEMS_PER_COLUMN = 6;

export const VIEW_TYPE_CODE = Object.freeze({
  MONTH: 1,
  WEEK: 2,
  DAY: 3
});

export const VIEW_ORIENTATION = Object.freeze({
  BACK: 1,
  FORWARD: 2
});

export const DAYS_OF_WEEK = [
  {
    name: 'lun',
    day: null
  },
  {
    name: 'mar',
    day: null
  },
  {
    name: 'mie',
    day: null
  },
  {
    name: 'jue',
    day: null
  },
  {
    name: 'vie',
    day: null
  },
  {
    name: 'sab',
    day: null
  },
  {
    name: 'dom',
    day: null
  }
];

export const DEFAULT_HOURS = [
  { name: '7:00 AM', value: 7 },
  { name: '8:00 AM', value: 8 },
  { name: '9:00 AM', value: 9 },
  { name: '10:00 AM', value: 10 },
  { name: '11:00 AM', value: 11 },
  { name: '12:00 PM', value: 12 },
  { name: '1:00 PM', value: 13 },
  { name: '2:00 PM', value: 14 },
  { name: '3:00 PM', value: 15 },
  { name: '4:00 PM', value: 16 },
  { name: '5:00 PM', value: 17 },
  { name: '6:00 PM', value: 18 },
  { name: '7:00 PM', value: 19 },
  { name: '8:00 PM', value: 20 }
];
