<template>
  <b-row>
    <b-col cols="12">
      <b-card border-variant="light">
        <validation-observer ref="kioskFormRef" novalidate>
          <b-row>
            <b-col lg="6" md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="collections.companies"
                />
              </validation-provider>
            </b-col>
            <b-col lg="6" md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.locationConfigurationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('activeLocation')"
                  :options="collections.locations"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12">
              <validation-provider v-slot="{ errors }" rules="required|max:64">
                <base-input
                  v-model="value.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('kioskName')"
                />
              </validation-provider>
            </b-col>
            <b-col lg="6" md="12">
              <div class="mt-4 float-left">
                <b-form-checkbox
                  v-model="value.isMultiLang"
                  switch
                >
                  {{ $t('multiLanguage') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="value.hasCardReader"
                  switch
                >
                  {{ $t('hasCardReader') }}
                </b-form-checkbox>
              </div>
              <b-button
                v-if="hideSubmitButton === undefined"
                class="mt-4 ml-2 float-right"
                variant="primary"
                @click="onSubmitKiosk()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </b-button>
              <b-button
                class="mt-4 float-right"
                variant="outline-primary"
                @click="showMacAddressManager()"
              >
                <i class="far fa-plus-square" /> {{ $t('macAddress') }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
    <slot />
    <b-col v-if="editMode" cols="12">
      <validation-observer ref="kioskLayoutFormRef" novalidate>
        <b-row>
          <b-col class="my-4" cols="12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('header') }}
            </h4>
            <hr />
          </b-col>
          <b-col>
            <b-card border-variant="light">
              <b-row>
                <b-col lg="4" md="6">
                  <filter-color
                    v-model="value.layout.headerBackgroundColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <b-col lg="4" md="6">
                  <custom-image-input
                    v-model="value.layout.headerBackgroundImage"
                    :auto-delete="false"
                    :file="value.layout.headerBackgroundImageFile"
                    :label="$t('backgroundImage')"
                    :max-height="150"
                    :max-width="1000"
                    @clearFile="onDeleteImage('headerBackgroundImage')"
                  />
                </b-col>
                <b-col lg="4" md="6">
                  <custom-image-input
                    v-model="value.layout.headerLogo"
                    :auto-delete="false"
                    :file="value.layout.headerLogoFile"
                    :label="$t('logo')"
                    :max-height="150"
                    :max-width="250"
                    @clearFile="onDeleteImage('headerLogo')"
                  />
                </b-col>
                <b-col lg="4" md="6">
                  <filter-select
                    v-model="value.layout.headerLogoPosition"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('logoPosition')"
                    :options="this.collections.positions"
                    translate
                  />
                </b-col>
                <b-col lg="4" md="12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('text')} ESP`"
                    />
                  </validation-provider>
                </b-col>
                <b-col lg="4" md="12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('text')} ENG`"
                    />
                  </validation-provider>
                </b-col>
                <b-col lg="4" md="6">
                  <filter-color
                    v-model="value.layout.headerTextColor"
                    :label="$t('textColor')"
                  />
                </b-col>
                <b-col lg="4" md="6">
                  <filter-select
                    v-model="value.layout.headerTextPosition"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('textPosition')"
                    :options="this.collections.positions"
                    translate
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="my-4" cols="12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('content') }}
            </h4>
            <hr />
          </b-col>
          <b-col>
            <b-card border-variant="light">
              <b-row>
                <b-col md="4">
                  <filter-color
                    v-model="value.layout.contentBackgroundColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <b-col md="4">
                  <custom-image-input
                    v-model="value.layout.contentBackgroundImage"
                    :auto-delete="false"
                    :file="value.layout.contentBackgroundImageFile"
                    :label="$t('backgroundImage')"
                    :max-height="500"
                    :max-width="1000"
                    @clearFile="onDeleteImage('contentBackgroundImage')"
                  />
                </b-col>
                <div class="col-md-4">
                  <filter-numeric
                    v-model="value.layout.backToStartCounter"
                    :min="0"
                    :max="99999"
                    :title="$t('backToStartCounter')"
                    :clearable="true"
                  />
                </div>
                <div class="col-md-4 pt-3">
                  <b-form-checkbox
                    v-model="value.layout.keyboardSimple"
                    switch
                  >
                    {{ $t('showSimpleKeyboard') }}
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="value.layout.showInformativeMessage"
                    switch
                  >
                    {{ $t('showInformativeMessage') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-4">
                  <filter-select
                    v-model="value.layout.keyboardSize"
                    fieldtext="key"
                    fieldvalue="value"
                    :label="$t('keyboardSize')"
                    :options="collections.keyboardSizes"
                  />
                </div>
                <div class="col-md-4 pt-3">
                  <b-form-checkbox
                    v-model="value.layout.keyboardDisplayZoom"
                    switch
                  >
                    {{ $t('showZoomOnKeyboard') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="value.layout.keyboardCapsLock"
                    switch
                  >
                    {{ $t('capsLockOnKeyboard') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-8">
                  <validation-provider v-slot="{ errors }" rules="max:128">
                    <base-input
                      v-model="value.layout.informativeMessage"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('informativeMessage')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <filter-color
                    v-model="value.layout.informativeMessageColor"
                    :label="$t('informativeMessageColor')"
                  />
                </div>
                <div class="col-md-4">
                  <filter-color
                    v-model="value.layout.keyboardBackgroundColor"
                    :label="$t('keyboardBackgroundColor')"
                  />
                </div>
                <div class="col-md-4">
                  <filter-color
                    v-model="value.layout.keyboardTextColor"
                    :label="$t('keyboardTextColor')"
                  />
                </div>
                <div class="col-md-4">
                  <filter-color
                    v-model="value.layout.datePickerBackgroundColor"
                    :label="$t('datePickerBackgroundColor')"
                  />
                </div>
                <div class="col-md-4">
                  <filter-color
                    v-model="value.layout.datePickerTextColor"
                    :label="$t('datePickerTextColor')"
                  />
                </div>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="my-4" cols="12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('footer') }}
            </h4>
            <hr />
          </b-col>
          <b-col>
            <b-card border-variant="light">
              <b-row>
                <b-col lg="3" md="6">
                  <filter-color
                    v-model="value.layout.footerBackgroundColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <b-col lg="3" md="6">
                  <custom-image-input
                    v-model="value.layout.footerBackgroundImage"
                    :auto-delete="false"
                    :file="value.layout.footerBackgroundImageFile"
                    :label="$t('backgroundImage')"
                    :max-height="150"
                    :max-width="1000"
                    @clearFile="onDeleteImage('footerBackgroundImage')"
                  />
                </b-col>
                <b-col md="6">
                  <custom-image-input
                    v-model="value.layout.footerLogo"
                    :auto-delete="false"
                    :file="value.layout.footerLogoFile"
                    :label="$t('logo')"
                    :max-height="150"
                    :max-width="250"
                    @clearFile="onDeleteImage('footerLogo')"
                  />
                </b-col>
                <b-col lg="6">
                  <b-row class="align-items-end">
                    <b-col cols="12">
                      <validation-provider v-slot="{ errors }" rules="max:32">
                        <base-input
                          v-model="value.layout.homeButtonText"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                          :label="`${$t('homeButtonText')} ESP`"
                        />
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider v-slot="{ errors }" rules="max:32">
                        <base-input
                          v-model="value.layout.homeButtonTextEN"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                          :label="`${$t('homeButtonText')} ENG`"
                        />
                      </validation-provider>
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.homeButtonTextColor"
                        :label="$t('homeButtonTextColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.homeButtonBackgroundColor"
                        :label="$t('homeButtonBackgroundColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.homeButtonBorderColor"
                        :label="$t('homeButtonBorderColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.homeButtonShadowColor"
                        :label="$t('homeButtonShadowColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-numeric
                        v-model="value.layout.homeButtonRadiusSize"
                        measurement-unit="pt"
                        :min="0"
                        :title="$t('homeButtonRadiusSize')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-numeric
                        v-model="value.layout.homeButtonBorderSize"
                        measurement-unit="pt"
                        :min="0"
                        :title="$t('homeButtonBorderSize')"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="6">
                  <b-row class="align-items-end">
                    <b-col lg="12">
                      <validation-provider v-slot="{ errors }" rules="max:32">
                        <base-input
                          v-model="value.layout.backButtonText"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                          :label="`${$t('backButtonText')} ESP`"
                        />
                      </validation-provider>
                    </b-col>
                    <b-col lg="12">
                      <validation-provider v-slot="{ errors }" rules="max:32">
                        <base-input
                          v-model="value.layout.backButtonTextEN"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                          :label="`${$t('backButtonText')} ENG`"
                        />
                      </validation-provider>
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.backButtonTextColor"
                        :label="$t('backButtonTextColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.backButtonBackgroundColor"
                        :label="$t('backButtonBackgroundColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.backButtonBorderColor"
                        :label="$t('backButtonBorderColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-color
                        v-model="value.layout.backButtonShadowColor"
                        :label="$t('backButtonShadowColor')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-numeric
                        v-model="value.layout.backButtonRadiusSize"
                        measurement-unit="pt"
                        :min="0"
                        :title="$t('backButtonRadiusSize')"
                      />
                    </b-col>
                    <b-col sm="6">
                      <filter-numeric
                        v-model="value.layout.backButtonBorderSize"
                        measurement-unit="pt"
                        :min="0"
                        :title="$t('backButtonBorderSize')"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-col>
    <b-col class="my-5 text-right" cols="12">
      <base-filled-button
        bg-color="#707070"
        class="float-left"
        icon-class="fas fa-ban"
        :on-click="OnCancel"
        :text="$t('cancel')"
      />

      <b-button
        v-if="editMode"
        class="mr-2"
        variant="outline-primary"
        @click="onSubmitKioskLayout()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </b-button>
      <b-button
        v-if="editMode"
        variant="primary"
        @click="onSubmitKioskLayout(true)"
      >
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
      </b-button>
      <slot name="buttons" />
    </b-col>
    <mac-address-manager
      ref="macAddressManager"
      v-model="this.value.macAddress"
      :on-mac-address-validating="onMacAddressValidating"
    />
  </b-row>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import BaseInput from '@/components/BaseInput.vue';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { submitKiosk as _submitKiosk } from '@/services/KioskService';
import { submitKioskLayout as _submitKioskLayout } from '@/services/KioskService';
import PositionOptions from '@/constants/PositionOptions';
import CustomImageInput from '@/components/CustomImageInput';
import { getLocationListForKiosk as _getLocationListForKiosk } from '@/services/LocationService';
import { validateKioskByMacAddress as _validateKioskByMacAddress } from '@/services/KioskService';
import { deleteImage as _deleteImage } from '@/services/KioskService';
import MacAddressManager from '@/components/MacAddressManager';

export default {
  name: 'Create',
  components: {
    BaseInput,
    CustomImageInput,
    MacAddressManager,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    hideSubmitButton: {
      type: undefined,
      default: () => undefined,
    },
  },
  data() {
    return {
      collections: {
        companies: [],
        locations: [],
        positions: PositionOptions,
        keyboardSizes: [],
      },
      edit: false,
    };
  },
  mixins: [sessionState],
  computed: {
    editMode() {
      return this.$route.name === 'kioskEdit';
    },
  },
  mounted() {
    this.collections.keyboardSizes = [
      { key: `${this.$t('zoom')}-1`, value: 1 },
      { key: `${this.$t('zoom')}-2`, value: 2 },
      { key: `${this.$t('zoom')}-3`, value: 3 },
      { key: `${this.$t('zoom')}-4`, value: 4 },
      { key: `${this.$t('zoom')}-5`, value: 5 },
    ];
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData(
      'registeredKioskData',
    );
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
  watch: {
    'value.companyId': {
      immediate: true,
      handler(value) {
        this.collections.locations = [];
        if (value) this.getAvailableLocalizations(value);
      },
    },
  },
  methods: {
    async onSubmitKiosk() {
      if (!(await this.$refs.kioskFormRef.validate())) return;

      if (!this.value.macAddress.length) {
        this.ShowToast('Error', this.$t('macAddressError'), 'error');
        return;
      }

      _submitKiosk({
        kioskId: this.value.kioskId,
        companyId: this.value.companyId,
        locationConfigurationId: this.value.locationConfigurationId,
        name: this.value.name,
        macAddress: this.value.macAddress,
        isMultiLang: this.value.isMultiLang,
        hasCardReader: this.value.hasCardReader,
      })
        .then((response) => {
          const location = this.collections.locations.find(
            (x) => x.id === this.value.locationConfigurationId,
          );
          if (location) this.value.locationConfigurationName = location.name;

          if (this.editMode) this.$emit('submitted');
          else
            this.$router.push({
              name: 'kioskEdit',
              params: { id: response.data },
            });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      // Persist current state of this view
      this.saveSessionData(
        'registeredAppointmentsData',
        Object.assign({}, this.$data),
      );
    },
    async OnCancel() {
      this.$router.push({ name: 'kiosksList' });
    },
    async onSubmitKioskLayout(next = false) {
      if (!(await this.$refs.kioskLayoutFormRef.validate())) return;
      _submitKioskLayout({
        ...this.value.layout,
        kioskId: this.value.kioskId,
      })
        .then((response) => {
          if (next) this.$emit('continue', 1);
          if (this.value.layout.kioskDesignConfigurationId) return;
          this.value.layout.kioskDesignConfigurationId = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationListForKiosk(companyId)
        .then((response) => {
          this.collections.locations = response.data;
          if (this.value.locationConfigurationId) {
            const location = this.collections.locations.find(
              (x) => x.id === this.value.locationConfigurationId,
            );
            if (!location) this.value.locationConfigurationId = null;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    showMacAddressManager() {
      this.$refs.macAddressManager.show();
    },
    async onMacAddressValidating(item) {
      var result = await _validateKioskByMacAddress(
        this.value.kioskId || null,
        item,
      )
        .then((response) => {
          if (response.data == '') return true; // Mac Address is not duplicated

          this.ShowToast(
            'Error',
            `${this.$t('macAddressExits')}: [${response.data.name}]
            [${response.data.companyName}] [${
              response.data.locationConfigurationName
            }] `,
            'error',
          );
          return false; // Mac Address is not valid
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
          return false;
        });

      return result;
    },
    async onDeleteImage(imageKey) {
      _deleteImage({
        kioskId: this.value.kioskId,
        imageKey: imageKey,
      })
        .then(() => {
          this.value.layout[imageKey] = null;
          this.value.layout[`${imageKey}File`] = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
.title {
  font-size: 14px;
  color: $color-primary;
}
</style>
