<template>
  <div class="row">
    <div class="col-md-12 my-4">
      <h4 class="font-weight-bolder float-left">
        {{ $t('specialFields') }}
      </h4>
      <hr style="margin-left: 100px" />
    </div>
    <div class="col-md-12">
      <validation-observer
        ref="formRef"
        novalidate
      >
        <div class="card border-light">
          <div class="card-body">
            <div
              v-if="specialFieldsInput"
              class="row"
            >
              <div class="col-12">
                <b-form-checkbox
                  v-model="model.specialField1Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField1Enabled && !model.specialField1Enabled) || (specialFields.length >= 3 && !model.specialField1Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField1, $event)"
                >
                  <small>{{ specialFieldsInput.specialField1Name || `${$t('specialField')} 1` }}</small>
                  <br />
                  <small
                    v-if="model.specialField1Enabled && !specialFieldsInput.specialField1Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="model.specialField2Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField2Enabled && !model.specialField2Enabled) || (specialFields.length >= 3 && !model.specialField2Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField2, $event)"
                >
                  <small>{{ specialFieldsInput.specialField2Name || `${$t('specialField')} 2` }}</small>
                  <br />
                  <small
                    v-if="model.specialField2Enabled && !specialFieldsInput.specialField2Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="model.specialField3Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField3Enabled && !model.specialField3Enabled) || (specialFields.length >= 3 && !model.specialField3Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField3, $event)"
                >
                  <small>{{ specialFieldsInput.specialField3Name || `${$t('specialField')} 3` }}</small>
                  <br />
                  <small
                    v-if="model.specialField3Enabled && !specialFieldsInput.specialField3Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="model.specialField4Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField4Enabled && !model.specialField4Enabled) || (specialFields.length >= 3 && !model.specialField4Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField4, $event)"
                >
                  <small>{{ specialFieldsInput.specialField4Name || `${$t('specialField')} 4` }}</small>
                  <br />
                  <small
                    v-if="model.specialField4Enabled && !specialFieldsInput.specialField4Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="model.specialField5Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField5Enabled && !model.specialField5Enabled) || (specialFields.length >= 3 && !model.specialField5Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField5, $event)"
                >
                  <small>{{ specialFieldsInput.specialField5Name || `${$t('specialField')} 5` }}</small>
                  <br />
                  <small
                    v-if="model.specialField5Enabled && !specialFieldsInput.specialField5Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="model.specialField6Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField6Enabled && !model.specialField6Enabled) || (specialFields.length >= 3 && !model.specialField6Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField6, $event)"
                >
                  <small>{{ specialFieldsInput.specialField6Name || `${$t('specialField')} 6` }}</small>
                  <br />
                  <small
                    v-if="model.specialField6Enabled && !specialFieldsInput.specialField6Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
                 <b-form-checkbox
                  v-model="model.specialField7Enabled"
                  class="float-left mr-3"
                  :disabled="(!specialFieldsInput.specialField7Enabled && !model.specialField7Enabled) || (specialFields.length >= 3 && !model.specialField7Enabled)"
                  size="lg"
                  switch
                  @change="onChangeSpecialField(specialFieldType.SpecialField7, $event)"
                >
                  <small>{{ specialFieldsInput.specialField7Name || `${$t('specialField')} 7` }}</small>
                  <br />
                  <small
                    v-if="model.specialField7Enabled && !specialFieldsInput.specialField7Enabled"
                    class="text-warning"
                    style="font-size: 14px"
                  >{{ $t('valueDoesNotMatch') }}</small>
                </b-form-checkbox>
              </div>
              <div class="col-md-12 pt-3">
                <alert-message
                  bg-color="#CFE2FF"
                  icon-class="fas fa-info"
                  :message="$t('only3FieldsCanBeConfiguredPerScreen')"
                />
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div
                    v-for="(specialField, i) in specialFields"
                    :key="i"
                    class="col-md-12"
                  >
                    <div class="row">
                      <div class="col-sm-3">
                        <base-input
                          :disabled="true"
                          :label="`${$t('specialField')} ${specialField.specialFieldType}`"
                          :value="specialFieldsInput[`specialField${specialField.specialFieldType}Name`] || `${$t('specialField')} ${specialField.specialFieldType}` "
                        />
                      </div>
                      <div
                        v-if="showKeyboardForm(specialField.specialFieldType)"
                        class="col-sm-3"
                      >
                        <filter-select
                          v-model="specialField.showKeyboard"
                          :clearable="false"
                          fieldtext="text"
                          fieldvalue="value"
                          :label="$t('displayKeyboard')"
                          :options="confirmationOptions"
                          translate
                          @on-selection-changed="specialField.keyboardType = null"
                        />
                      </div>
                      <div v-if="showKeyboardForm(specialField.specialFieldType)" class="col-sm-3">
                        <filter-numeric
                          v-model="specialField.maxLength"
                          :clearable="true"
                          :min="1"
                          :title="$t('maxCharacters')"
                        />

                      </div>
                      <div
                        v-if="showKeyboardForm(specialField.specialFieldType)"
                        class="col-sm-3"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="{required: specialField.showKeyboard}"
                        >
                          <filter-select
                            v-model="specialField.keyboardType"
                            :clearable="false"
                            :disabled="!specialField.showKeyboard"
                            :error="errors[0]"
                            :error-msg="$t('mustSelectOne')"
                            fieldtext="text"
                            fieldvalue="value"
                            :label="$t('keyboardType')"
                            :options="keyboardOptions"
                            translate
                          />
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <base-input
                      v-model="model.continueButtonText"
                      :label="`${$t('continueButtonText')} ESP`"
                    />
                  </div>
                  <div class="col-sm-4">
                    <base-input
                      v-model="model.continueButtonTextEN"
                      :label="`${$t('continueButtonText')} ENG`"
                    />
                  </div>
                  <div class="col-sm-4">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <filter-select
                        v-model="model.redirectScreenId"
                        :error="errors[0]"
                        :error-msg="$t('mustSelectOne')"
                        fieldtext="name"
                        fieldvalue="kioskScreenId"
                        :label="`${$t('button')} '${model.continueButtonText || ''}' ${$t(
                          'sendTo',
                        )}`"
                        :options="
                          screens.filter((screen, i) => i !== screenNumber - 1)
                        "
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <b-button
                  variant="outline-primary"
                  @click="onSubmit()"
                >
                  <i class="far fa-save" /> {{ $t('save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import { getSpecialFieldEnabled as _getSpecialFieldEnabled } from '@/services/LocationService';
import KeyboardOptions from '@/constants/KeyboardOptions';
import SpecialFieldType from '@/constants/SpecialFieldType';
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import { submitScreenSpecialFields as _submitScreenSpecialFields } from '@/services/KioskService';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

export default {
  name: 'ScreenSpecialField',
  components: {
      BaseInput,
      AlertMessage
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
    screens: {
      type: Array,
      default: () => [],
    },
    screenNumber: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
      return {
          specialFieldType: SpecialFieldType,
          keyboardOptions: KeyboardOptions,
          confirmationOptions: ConfirmationOptions,
          model: {
              specialField1Enabled: false,
              specialField2Enabled: false,
              specialField3Enabled: false,
              specialField4Enabled: false,
              specialField5Enabled: false,
              specialField6Enabled: false,
              specialField7Enabled: false,
              continueButtonText: null,
              continueButtonTextEN: null,
              redirectScreenId: null
          },
          specialFieldsInput: null,
          specialFields: []
      }
  },
  mounted() {
      this.loadSpecialFieldsEnabled();
  },
  watch: {
    value: {
      immediate: true,
      handler(screen) {
        this.model = {
          specialField1Enabled: screen.specialField1Enabled,
          specialField2Enabled: screen.specialField2Enabled,
          specialField3Enabled: screen.specialField3Enabled,
          specialField4Enabled: screen.specialField4Enabled,
          specialField5Enabled: screen.specialField5Enabled,
          specialField6Enabled: screen.specialField6Enabled,
          specialField7Enabled: screen.specialField7Enabled,
          continueButtonText: screen.continueButtonText,
          continueButtonTextEN: screen.continueButtonTextEN,
          redirectScreenId: screen.redirectScreenId
        };
        if (screen.specialFields && screen.specialFields.length) this.specialFields = screen.specialFields.map(x => ({...x}));
      },
    },
  },
  methods: {
      loadSpecialFieldsEnabled() {
          _getSpecialFieldEnabled(this.kiosk.locationConfigurationId)
          .then(response => {
              this.specialFieldsInput = response.data;
          })
      },
      showKeyboardForm(specialField) {
        return ![this.specialFieldType.SpecialField3, this.specialFieldType.SpecialField7].includes(specialField);
      },
      onChangeSpecialField(type, value) {
          const specialFieldIndex = this.specialFields.findIndex(x => x.specialFieldType == type);

          if (specialFieldIndex == -1 && value) {
              this.specialFields.push({
                  kioskScreenSpecialFieldId: 0,
                  kioskScreenId: this.value.kioskScreenId,
                  specialFieldType: type,
                  showKeyboard: false,
                  maxLength: null,
                  keyboardType: null
              });
          }
          else if (specialFieldIndex > -1 && !value) this.specialFields.splice(specialFieldIndex, 1);

          this.specialFields = this.specialFields.sort(function(a,b) {
                var x = a.specialFieldType;
                var y = b.specialFieldType;
                return x < y ? -1 : x > y ? 1 : 0;
            })
      },
      async onSubmit() {
        if (!(await this.$refs.formRef.validate())) return;
        _submitScreenSpecialFields({...this.model, kioskId: this.value.kioskId,
        kioskScreenId: this.value.kioskScreenId, specialFields: this.specialFields })
        .then(response => {
          this.specialFields = response.data;
          this.$emit('input', {...this.value, ...this.model, specialFields: response.data.map(x => ({...x}))});
        })
      }
  }
};
</script>
