var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-striped table-bordered table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('company')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('status')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('percCompleted')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('recordsProcessed')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('totalRecords')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('startDate')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('endDate')))])])]),(_vm.noResultsFound)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"9"}},[_c('CustomNotFound',{attrs:{"text":_vm.$t('noResultsFound')}})],1)])]):_vm._e(),(!_vm.noResultsFound)?_c('tbody',_vm._l((_vm.value.data),function(item,index){return _c('tr',{key:index,staticClass:"mt-3 col-12"},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(item.processId))]),_c('td',[_vm._v(_vm._s(item.companyName)+" ("+_vm._s(item.companyId)+")")]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.$t('processStatus_' + item.status)))]),_c('td',[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",class:{
                pending: item.percentageCompleted == 0,
                'in-progress progress-bar-striped progress-bar-animated':
                  item.percentageCompleted > 0 &&
                  item.percentageCompleted < 100,
                finalized: item.percentageCompleted == 100,
              },style:({ width: item.percentageCompleted + '%' }),attrs:{"role":"progressbar","aria-valuenow":item.percentageCompleted,"aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(item.percentageCompleted)+"% ")])])]),_c('td',[_vm._v(_vm._s(item.affectedRecords))]),_c('td',[_vm._v(_vm._s(item.totalRecords))]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.createdDate).format('dddd D/MMMM/YYYY hh:mm:ss A'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.finishDate ? _vm.$moment(item.finishDate).format('dddd D/MMMM/YYYY hh:mm:ss A') : '')+" ")])])}),0):_vm._e()]),(_vm.value.count > 0)?_c('custom-pagination',{staticClass:"my-4",attrs:{"id":"pgPager","data":_vm.value,"page-size":_vm.pageSize},on:{"pagination-go-page":function($event){return _vm.search($event)},"pagination-rows-per-page":function($event){return _vm.search(_vm.pageIndex, $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }