import {
  apiTurnos
} from '@/core/api/';
import {
  serialize
} from 'object-to-formdata';

export const getKiosks = (payload) => apiTurnos.get('/api/kiosk/search',{ params : { ...payload }});

export const getScreenTypes = () => apiTurnos.get('/api/kiosk/screentype/list');

export const getMacInstaller = () => apiTurnos.get('/api/kiosk/macinstaller');

export const submitKiosk = model =>
  apiTurnos[model.kioskId ? 'put' : 'post']('/api/kiosk', model);

export const getKiosk = id => apiTurnos.get(`/api/kiosk/${id}`);

export const getKioskList = (companyId,locationConfigurationId) => apiTurnos.get(`/api/kiosk/${companyId}/${locationConfigurationId}/list`);

export const submitKioskLayout = model => {

  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true
  });

  return apiTurnos[model.kioskDesignConfigurationId ? 'put' : 'post'](
    '/api/kiosk/layout',
    payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const setScreenTypeMetadata = model =>
  apiTurnos.post(`api/kiosk/${model.kioskId}/screentypes`, model);

export const getScreenInfo = payload =>
  apiTurnos.get(
    `api/kiosk/${payload.kioskId}/${payload.kioskScreenId}/screentype`,
  );

export const submitScreenType = model => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true
  });

  return apiTurnos.post(
    `api/kiosk/${model.kioskId}/${model.kioskScreenId}/screentype`,
    payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}



export function submitScreenButtons (payload) {
  return apiTurnos.post(
    `api/kiosk/${payload.kioskId}/${payload.kioskScreenId}/screentype/buttons`,
    payload.buttons,
  );
}


export const submitScreenService = payload =>
  apiTurnos.post(
    `api/kiosk/${payload.kioskId}/${payload.kioskScreenId}/screentype/services`,
    payload.services,
  );

export const submitScreenQueues = payload =>
  apiTurnos.post(
    `api/kiosk/${payload.kioskId}/${payload.kioskScreenId}/screentype/queues`,
    payload.queues,
  );
export const getOfficeList = kioskId => apiTurnos.get(`/api/kiosk/${kioskId}/office/list`);
export const getOfficeListWithConfigureServiceScreen = (kioskId, kioskScreenId) => apiTurnos.get(`/api/kiosk/${kioskId}/office/servicescreen/${kioskScreenId}/list`);

export const getSpecialFieldList = locationConfigurationId => apiTurnos.get(`/api/kiosk/${locationConfigurationId}/specialfield/list`);

export const saveCreationStep = (kioskId, step) => apiTurnos.post(`api/kiosk/${kioskId}/creationstep/${step}`);

export const validateKioskByMacAddress = (kioskId, macAddress) => {
  let qstring = '';

  if(kioskId)
  qstring += `kioskId=${kioskId}`;

  return apiTurnos.get(`/api/kiosk/validate/${macAddress}?${qstring}`);
}

export const createKioskClone = (payload) => apiTurnos.post(`/api/kiosk/${payload.kioskId}/clone`, payload);

export const deleteImage = (payload) => apiTurnos.delete(`/api/kiosk/${payload.kioskId}/image/delete`, { data : { ...payload }});

export const deleteKiosk = (payload) => apiTurnos.delete(`/api/kiosk/${payload}`);

export const submitScreenSpecialFields = payload =>
  apiTurnos.post(
    `api/kiosk/${payload.kioskId}/${payload.kioskScreenId}/screentype/specialFields`,
    payload,
  );

  export const getIntegratedClientsAsync = (kioskId) => apiTurnos.get(`/api/kiosk/${kioskId}/integration/clients`);

  export const submitIntegrationClientsAsync = (kioskId, payload) => apiTurnos.post(`/api/kiosk/${kioskId}/integration/clients`, payload);

  export const getRelations = (kioskId) => apiTurnos.get(`/api/kiosk/${kioskId}/relations`);

  export const getRelationScreens = (kioskId) => apiTurnos.get(`/api/kiosk/${kioskId}/relation/screens`);
