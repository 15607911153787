<template>
  <div class="">
    <router-view />
  </div>
</template>

<script>
export default {
  title: 'Create Spaces',
  name: 'CreateSpaces'
};
</script>
