<template>
  <div>
    <div
      id="calendar-container"
      :class="'calendar-container ' + [viewTypeCssClass]"
    >
      <div
        class="calendar-container__header row mx-0"
        :class="{ 'd-flex': isDayType }"
      >
        <div
          v-for="(item, index) in getDays()"
          :key="'calendar-day' + index"
          class="col calendar-day fixed-day-width"
        >
          <div class="day-short-name text-center">
            {{ $t(item.name) }}
          </div>
          <div v-if="isWeekType || isDayType">
            <div class="day-number text-center">
              {{ item.day }}
            </div>
            <div
              v-if="isDayType"
              class="text-center mt-3 day-short-name"
            >
              {{ todayAppointmentsSpaces.availableCount }} {{ $t('of') }}
              {{ todayAppointmentsSpaces.totalCount }} {{ $t('spaces') }}
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-container__body">
        <div v-if="isMonthType">
          <div
            v-for="(item, index) in daysOfMonth"
            :key="'day-in-month' + index"
            class="calendar-container__week d-flex"
          >
            <div
              v-for="(day, indexDay) in item"
              :key="'day-box' + indexDay"
              class="calendar-container__day"
            >
              <span>{{ day.getDate() }}</span>
              <div v-if="hasAppointmentByMonthDate(day)">
                <CalendarEventMonth
                  :current-day="day"
                  :item-count="monthItemsCount(day)"
                >
                  <template v-slot:content>
                    <div class="month-item">
                      <span
                        class="d-none d-sm-block"
                        @click="goToWeekView(day)"
                      >
                        {{ monthItemsSummary(day).availableCount }}
                        {{ $t('of') }}
                        {{ monthItemsSummary(day).count }}
                        {{ $t('spaces') }}
                      </span>
                      <span
                        class="d-sm-none"
                        @click="goToWeekView(day)"
                      >
                        {{ monthItemsSummary(day).availableCount }}/{{
                          monthItemsSummary(day).count
                        }}
                      </span>
                    </div>
                  </template>
                </CalendarEventMonth>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isWeekType"
          class="row mx-0"
        >
          <div
            v-for="weekDay in weekArray"
            :key="weekDay.id"
            class="calendar-container__body__weekday col px-0"
          >
            <div v-if="hasAppointmentByDate(weekDay)">
              <CalendarEventWeek
                :item-click="itemClick"
                :items="getAppointmentByDate(weekDay)"
              >
                <template v-slot:content="slotProps">
                  <div class="hour">
                    <span
                      class="item-select"
                      @click="onItemClick($event, slotProps.dataItem)"
                    >
                      {{ getWeeklyFormatedHour(slotProps.dataItem.startHour) }}
                      -
                      {{ getWeeklyFormatedHour(slotProps.dataItem.endHour) }}
                    </span>
                  </div>
                  <div class="items">
                    <span
                      :id="'available-items-input-' + slotProps.dataItem.index"
                      class="no-focus"
                      tabindex="0"
                    >
                      {{ slotProps.dataItem.availableCount }} {{ $t('of') }}
                      {{ slotProps.dataItem.count }}
                      {{
                        slotProps.dataItem.count == 1
                          ? $t('space')
                          : $t('spaces')
                      }}
                    </span>
                  </div>
                  <div class="service-name">
                    {{ slotProps.dataItem.serviceName }}
                  </div>
                </template>
                <template v-slot:popover-content="slotProps">
                  <b-popover
                    ref="popover"
                    container="calendar-container"
                    offset="1000"
                    placement="bottom"
                    :target="
                      'available-items-input-' + slotProps.dataItem.index
                    "
                    triggers="click blur"
                  >
                    <template>
                      <div
                        v-for="availableAppointment in slotProps.dataItem
                          .availableAppointments"
                        :id="
                          'specialist-name-' +
                            availableAppointment.clientAppointmentId
                        "
                        :key="availableAppointment.clientAppointmentId"
                        class="text-center"
                        style="margin-top: 21px; margin-bottom: 21px"
                      >
                        <span
                          class="specialist-name-container cursor-pointer"
                          @click="
                            showSpaceManager(
                              availableAppointment,
                              slotProps.dataItem,
                            )
                          "
                        >
                          {{ availableAppointment.specialistNameFull }}
                        </span>
                        <b-popover
                          v-if="availableAppointment != undefined"
                          ref="popover"
                          container="calendar-container"
                          custom-class="item-detail"
                          offset="0"
                          placement="right"
                          :target="
                            'specialist-name-' +
                              availableAppointment.clientAppointmentId
                          "
                          triggers="hover"
                        >
                          <template>
                            <b-container fluid>
                              <b-row>
                                <b-col cols="12">
                                  <div class="caption main-content-title">
                                    {{ slotProps.dataItem.serviceName }}
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="item-detail-content">
                                <b-col cols="12">
                                  <label
                                    class="item-detail-content-description"
                                  >{{ $t('start') }}:</label>
                                  <label class="item-detail-content-detail">
                                    {{
                                      getFormatedDate(slotProps.dataItem.date)
                                    }}
                                    {{
                                      getWeeklyFormatedHour(
                                        slotProps.dataItem.startHour,
                                      )
                                    }}</label>
                                </b-col>
                                <b-col
                                  class="mb-3"
                                  cols="12"
                                >
                                  <label
                                    class="item-detail-content-description"
                                  >{{ $t('end') }}:</label>
                                  <label class="item-detail-content-detail">
                                    {{
                                      getFormatedDate(slotProps.dataItem.date)
                                    }}
                                    {{
                                      getWeeklyFormatedHour(
                                        slotProps.dataItem.endHour,
                                      )
                                    }}</label>
                                </b-col>
                                <b-col cols="12">
                                  <label
                                    class="item-detail-content-description"
                                  >{{ $t('company') }}:</label>
                                  <label class="item-detail-content-detail">
                                    {{
                                      availableAppointment.companyName
                                    }}</label>
                                </b-col>
                                <b-col cols="12">
                                  <label
                                    class="item-detail-content-description"
                                  >{{ $t('localization') }}:</label>
                                  <label class="item-detail-content-detail">
                                    {{
                                      availableAppointment.locationName
                                    }}</label>
                                  <label class="item-detail-content-detail">
                                    {{
                                      availableAppointment.locationTelephoneNo
                                    }}</label>
                                </b-col>
                              </b-row>
                            </b-container>
                          </template>
                        </b-popover>
                      </div>
                    </template>
                  </b-popover>
                </template>
              </CalendarEventWeek>
            </div>
            <div
              v-else
              class="calendar-container__body__empty"
            >
              <div class="calendar-container__body__empty_weekday">
                <div class="full-day">
                  {{ $t('notavailable') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isDayType"
          class="d-flex flex-row"
        >
          <div
            v-if="todayAppointments.length"
            class="calendar-container__body__weekday"
          >
            <CalendarEventDay :items="todayAppointments">
              <template v-slot:row="slotProps">
                <div class="row">
                  <div class="col-xs-12 col-md-3 daily-space">
                    <div class="hour">
                      <label>
                        {{ getDailyFormatedHour(slotProps.dataItem.startHour) }}
                        -
                        {{ getDailyFormatedHour(slotProps.dataItem.endHour) }}
                      </label>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-9 daily-detail">
                    <div
                      v-for="availableAppointment in slotProps.dataItem
                        .availableAppointments"
                      :id="
                        'specialist-name-' +
                          availableAppointment.clientAppointmentId
                      "
                      :key="availableAppointment.clientAppointmentId"
                      class="specialist-name"
                    >
                      <b-container
                        class="specialist-item item-detail-content"
                        fluid
                        @click="
                          showSpaceManager(
                            availableAppointment,
                            slotProps.dataItem,
                          )
                        "
                      >
                        <b-row class="text-left d-flex justify-content-center align-items-center">
                          <b-col
                            cols="12"
                            xl="2"
                          >
                            <div class="caption main-content-title text-center mb-4 mb-lg-0">
                              {{ slotProps.dataItem.serviceName }}
                            </div>
                          </b-col>
                          <b-col cols="12" xl="10">
                            <b-row>
                              <b-col cols="12" sm="6">
                                <b-form-group class="mb-0">
                                  <label
                                    class="title"
                                  >{{ $t('start') }}:</label>
                                  <label class="value">
                                    {{
                                      getFormatedDate(slotProps.dataItem.date)
                                    }}
                                    {{
                                      getDailyFormatedHour(
                                        slotProps.dataItem.startHour,
                                      )
                                    }}</label>
                                </b-form-group>
                                <b-form-group class="mb-0">
                                  <label class="title">{{ $t('end') }}:</label>
                                  <label class="value">
                                    {{
                                      getFormatedDate(slotProps.dataItem.date)
                                    }}
                                    {{
                                      getDailyFormatedHour(
                                        slotProps.dataItem.endHour,
                                      )
                                    }}</label>
                                </b-form-group>
                                <b-form-group class="mb-0">
                                  <label
                                    class="title"
                                  >{{ $t('specialist') }}:</label>
                                  <label class="value specialist-fullname">
                                    {{
                                      availableAppointment.specialistNameFull
                                    }}</label>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" sm="6">
                                <b-form-group class="mb-0">
                                  <label
                                    class="title"
                                  >{{ $t('company') }}:</label>
                                  <label class="value">
                                    {{ availableAppointment.companyName }}
                                  </label>
                                </b-form-group>
                                <b-form-group class="mb-0">
                                  <label
                                    class="title"
                                  >{{ $t('localization') }}:</label>
                                  <label class="value">
                                    {{
                                      availableAppointment.locationName
                                    }}</label>
                                  <label class="value">
                                    {{
                                      availableAppointment.locationTelephoneNo
                                    }}</label>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </div>
                </div>
              </template>
            </CalendarEventDay>
          </div>
          <div
            v-else
            class="calendar-container__body__empty_weekday"
          >
            <div class="full-day">
              {{ $t('notavailable') }}
            </div>
          </div>
        </div>

        <custom-modal
          id="space-manager-modal"
          ref="space-manager-ref"
          size="xl"
        >
          <space-manager
            :selected-space="selectedSpace"
            @on-cancel-space-manager="hideSpaceManagerModal"
          />
        </custom-modal>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import CalendarEventMonth from '@/components/full-calendar/CalendarEventMonth';
import CalendarEventWeek from '@/components/full-calendar/CalendarEventWeek';
import CalendarEventDay from '@/components/full-calendar/CalendarEventDay';

import CustomModal from '@/components/basics/modal/CustomModal.vue';
import SpaceManager from '@/components/spaces/SpaceManager.vue';

import {
  DEFAULT_HOUR_FORMAT,
  VIEW_TYPE_CODE,
  DAYS_OF_WEEK,
  DEFAULT_HOURS,
  MAX_ITEMS_PER_COLUMN,
} from '@/components/full-calendar/constants';

export default {
  name: 'AvailableSpacesCalendarEvent',
  components: {
    CalendarEventMonth,
    CalendarEventWeek,
    CalendarEventDay,
    'space-manager': SpaceManager,
    CustomModal,
  },
  props: {
    entryDate: {
      type: Date,
      default: () => new Date(),
    },
    eventList: {
      type: Array,
      default: () => [],
    },
    viewType: {
      type: Number,
      default: 1,
      validator(value) {
        return [1, 2, 3].includes(value);
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
    monthItems: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: function () {
        return 'es';
      },
      required: true,
    },
    itemClick: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    daysOfMonth: [],
    hoursOfWeek: [],
    firstDayOfWeek: 0,
    firstDayOfWeekOfMonth: 0,
    weekArray: [],
    eventArrayAdded: [],
    eventDetailsVisible: false,
    maxItemPerColumn: MAX_ITEMS_PER_COLUMN,
    selectedSpace: {},
  }),
  filters: {
    shortenServiceName: function (value) {
      if (!value) return '';
      value = value.toString();
      return `${value.substring(0, 20)} ...`;
    },
  },
  computed: {
    isMonthType() {
      return this.viewType === VIEW_TYPE_CODE.MONTH;
    },
    isWeekType() {
      return this.viewType === VIEW_TYPE_CODE.WEEK;
    },
    isDayType() {
      return this.viewType === VIEW_TYPE_CODE.DAY;
    },
    getWeekDay() {
      return (date) => this.$moment(date).date();
    },
    todayAppointments() {
      var filetedTodaysAppointment = this.items.filter(
        (x) =>
          this.$moment(x.date).format('YYYY-MM-DD') ==
          this.$moment(this.entryDate).format('YYYY-MM-DD'),
      );

      return filetedTodaysAppointment;
    },
    todayAppointmentsSpaces() {
      var filetedTodaysAppointment = this.items.filter(
        (x) =>
          this.$moment(x.date).format('YYYY-MM-DD') ==
          this.$moment(this.entryDate).format('YYYY-MM-DD'),
      );
      return {
        availableCount: filetedTodaysAppointment.reduce(
          (acc, item) => acc + item.availableCount,
          0,
        ),
        totalCount: filetedTodaysAppointment.reduce(
          (acc, item) => acc + item.count,
          0,
        ),
      };
    },
    todayCount() {
      var element = this.monthItems.find(
        (x) =>
          this.$moment(x.date).format('YYYY-MM-DD') ==
          this.$moment(this.entryDate).format('YYYY-MM-DD'),
      );

      if (element === undefined) {
        return 0;
      }

      return element.count;
    },
    todayAvailableCount() {
      var element = this.monthItems.find(
        (x) =>
          this.$moment(x.date, 'YYYY-MM-DD').valueOf() ==
          this.$moment(this.entryDate, 'YYYY-MM-DD').toDate().getTime(),
      );

      if (element === undefined) {
        return 0;
      }
      return element.availableCount;
    },
    viewTypeCssClass() {
      var css = '';

      switch (this.viewType) {
        case VIEW_TYPE_CODE.DAY:
          css = 'day-view';
          break;
        case VIEW_TYPE_CODE.WEEK:
          css = 'week-view';
          break;
        case VIEW_TYPE_CODE.MONTH:
          css = 'month-view';
          break;
        default:
          css = 'day-view';
      }

      return css;
    },
    monthItemsCount() {
      return (day) => {
        const filteredData = this.monthItems.filter(
          (x) => this.$moment(x.date, 'YYYY-MM-DD').valueOf() == day.getTime(),
        );

        if (filteredData.length == 1) return filteredData[0].count;

        return 0;
      };
    },
    monthItemsSummary() {
      return (day) => {
        let totalCount = 0;
        let totalAvailableCount = 0;

        const currentItems = this.monthItems.filter(
          (x) => this.$moment(x.date, 'YYYY-MM-DD').valueOf() == day.getTime(),
        );

        currentItems.forEach(function (value) {
          totalCount += value.count;
          totalAvailableCount += value.availableCount;
        });

        const summarizedItems = {
          date: currentItems[0].date,
          availableCount: totalAvailableCount,
          count: totalCount,
        };

        return summarizedItems;
      };
    },
  },
  watch: {
    entryDate() {
      this.loadCalendarDays();
      this.getWeekView();
    },
  },
  methods: {
    getAppointmentByDate(weekDay) {
      const result = this.items.filter(
        (x) =>
          this.$moment(x.date, 'YYYY-MM-DD').valueOf() == weekDay.getTime(),
      );

      return result;
    },
    getCountAppointmentByDate(weekDay) {
      return this.getAppointmentByDate(weekDay).length;
    },
    hasAppointmentByDate(weekDay) {
      const result = this.items.some(
        (x) =>
          this.$moment(x.date, 'YYYY-MM-DD').valueOf() == weekDay.getTime(),
      );

      return result;
    },
    hasAppointmentByHour(hourDay) {
      if (!this.todayAppointments.length) return false;

      return this.todayAppointments.some(
        (x) => new Date(x.startHour).getHours() == hourDay,
      );
    },
    hasAppointmentByMonthDate(monthDay) {
      return this.monthItems.some(
        (x) =>
          this.$moment(x.date, 'YYYY-MM-DD').valueOf() == monthDay.getTime(),
      );
    },
    getDays() {
      if (this.isWeekType) {
        return DAYS_OF_WEEK.map((item, index) => {
          return {
            ...item,
            name: this.$moment(this.weekArray[index])
              .locale('en')
              .format('dddd')
              .toLowerCase(),
            day: this.$moment(this.weekArray[index]).date(),
          };
        });
      }
      if (this.isDayType) {
        return [
          {
            name: this.$moment(this.entryDate)
              .locale('en')
              .format('dddd')
              .toLowerCase(),
            day: this.$moment(this.entryDate).locale(this.language).date(),
          },
        ];
      }
      return DAYS_OF_WEEK;
    },
    loadCalendarDays() {
      this.daysOfMonth = this.getCalendar(this.entryDate);
    },
    getHourFormat(time) {
      return this.$moment(time).format('LT');
    },
    onSelectEventHandler(item) {
      this.openPopup(item);
    },
    openPopup(item) {
      this.$refs._eventDetailsPopupRef.info = item;
      const element = this.$refs._eventDetailsPopupRef.$el;
      $(element).modal('show');
    },
    evalEventDate(day) {
      return this.eventList.filter((s) => {
        return this.$moment(s.dateFrom).isSame(day);
      });
    },
    evalEventWeekDate(hour, day) {
      const added = {};
      const arrayResult = this.eventList.filter((s) => {
        const matchDay = this.$moment(s.dateFrom).isSame(day);
        const matchTime = this.isTimeBetween(
          this.$moment(hour.value, DEFAULT_HOUR_FORMAT),
          s.hourStartFormatted,
          s.hourEndFormatted,
        );
        const isAdded = !!Object.values(added).find((x) => x === s.id);

        const matched = matchDay && matchTime;

        if (matched) {
          added[s.id] = s.id;
        }

        return matched && !isAdded;
      });

      return arrayResult;
    },
    evalEventDayDate(hour) {
      const day = this.entryDate;
      const arrayResult = this.eventList.filter((s) => {
        const matchDay = this.$moment(s.dateFrom).isSame(day);
        const matchTime = this.isTimeBetween(
          this.$moment(hour.value, DEFAULT_HOUR_FORMAT),
          s.hourStartFormatted,
          s.hourEndFormatted,
        );
        const matched = matchDay && matchTime;
        return matched;
      });
      return arrayResult;
    },
    getTime(hour) {
      return this.$moment(hour, DEFAULT_HOUR_FORMAT).format(
        DEFAULT_HOUR_FORMAT,
      );
    },
    isTimeBetween(time, hourStart, hourEnd) {
      const format = DEFAULT_HOUR_FORMAT;
      const timeHour = this.$moment(time, format).hour();
      const startHour = this.$moment(hourStart, format).hour();
      const endHour = this.$moment(hourEnd, format).hour();

      return timeHour >= startHour && timeHour <= endHour;
    },
    parseDate(year, month, day) {
      return new Date(year, month, day);
    },
    getCalendar: function (date) {
      const calendar = [];

      const startDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const startDow = (startDay.getDay() + 6) % 7;
      const endDow = (lastDay.getDay() + 6) % 7;

      startDay.setDate(startDay.getDate() - startDow);

      lastDay.setDate(lastDay.getDate() + (6 - endDow));

      let week = [];
      while (startDay <= lastDay) {
        week.push(new Date(startDay));
        if (week.length === 7) {
          calendar.push(week);
          week = [];
        }
        startDay.setDate(startDay.getDate() + 1);
      }

      this.firstDayOfWeek = calendar[0][0];

      this.getDays();
      return calendar;
    },
    getWeekView() {
      this.hoursOfWeek = DEFAULT_HOURS;
      this.getWeekArray(this.entryDate);
    },
    getWeekArray(date) {
      const startOfWeek = this.$moment(date).startOf('isoWeek');
      const endOfWeek = this.$moment(date).endOf('isoWeek');

      const days = [];
      let day = startOfWeek;

      while (day <= endOfWeek) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
      }

      this.weekArray = days;
      return days;
    },
    goToWeekView(date) {
      this.$emit('on-viewtype-changed', {
        viewType: VIEW_TYPE_CODE.WEEK,
        date: date,
      });
    },

    onItemClick(event, item) {
      event.preventDefault();
      event.stopPropagation();
      this.itemClick(item);
    },

    getDailyFormatedHour(time) {
      return this.$moment(time).locale('en').format('LT').toLowerCase();
    },

    getWeeklyFormatedHour(time) {
      return this.$moment(time).format('h:mm A');
    },
    getFormatedDate(date) {
      return this.$moment(date).format('MM/DD/YYYY');
    },
    showSpaceManager(availableSpace, appointment) {
      this.selectedSpace = {
        clientAppointmentId: availableSpace.clientAppointmentId,
        company: {
          value: availableSpace.companyId,
          text: availableSpace.companyName,
        },
        service: {
          value: appointment.serviceId,
          text: appointment.serviceName,
        },
        duration: availableSpace.duration,
        endHour: appointment.endHour,
        startHour: appointment.startHour,
        date: appointment.date,
        specialist: {
          value: availableSpace.specialistId,
          text: availableSpace.specialistNameFull,
        },
      };

      this.$refs['space-manager-ref'].show();
    },
    hideSpaceManagerModal() {
      this.$refs['space-manager-ref'].hide();
      this.clearSpaceModel();
      this.updateSpaces();
    },
    updateSpaces() {
      this.$emit('refreshItems');
    },
    clearSpaceModel() {
      this.selectedSpace = {
        company: {
          value: 0,
          text: '',
        },
        service: {
          value: 0,
          text: '',
        },
        duration: 30,
        endHour: '',
        startHour: '',
        data: '',
        specialist: {
          value: 0,
          text: '',
        },
      };
    },
  },
  mounted() {
    this.loadCalendarDays();
    this.getWeekView();
    this.firstDayOfWeekOfMonth = this.$moment(this.entryDate).startOf('month');

    this.clearSpaceModel();
  },
};
</script>

<style scoped lang="scss">
.calendar-container {
  border: 1px solid $color-label-default;
  border-radius: 5px 0px 0px 0px;

  &__header {
    background: #f2f2f2;
    height: 131px;

    .text-underline {
      text-decoration: underline;
    }

    .calendar-day {
      font: Bold 14px/21px Lato;
      color: $color-font-success;
      width: 10%;
      border: 1px solid $color-label-default;
      .day-short-name {
        color: #ff8105;
        margin-top: 30px;
      }
      .day-number {
        padding-top: 15px;
        text-align: right;
        letter-spacing: NaNpx;
        opacity: 1;
        font-size: 3rem;
        color: #707070;
        text-align: left;
      }
    }
  }
  &__body {
    margin: 0px 0px 0px 0px;
    .start-of-space-list {
      background-color: #f2f2f2;
      height: 40px;
    }
    &__weekday {
      width: 100%;
      background: $color-white 0% 0% no-repeat padding-box;
      border-left: 1px solid $color-label-default;
      border-bottom: 1px solid $color-label-default;
      opacity: 1;
    }
    &__empty {
      height: 100%;
    }
    &__empty_weekday {
      width: 100%;
      height: 100%;
      background: $color-white 0% 0% no-repeat padding-box;
      opacity: 1;
      display: flex;
      justify-items: center;
      align-items: center;
      text-align: center;

      min-height: 74px;

      .full-day {
        font: normal normal normal 18px/21px Lato;
        width: 100%;
        color: #707070;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  &__week {
    display: flex;
  }
  &__day {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: right;
    padding: 10px 0 10px 10px;
    padding-right: 10px;
    border: 1px solid #eeeeee;
    min-height: 150px;
  }

  &__weekViewHour {
    padding: 10px;
    width: 60px;
    border: 1px solid #eeeeee;
  }

  &__weekView {
    display: flex;
    flex-direction: row;
  }
  &__dayView {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: right;
    padding: 10px 0 10px 10px;
    padding-right: 10px;
    border: 1px solid #eeeeee;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
  }
  .separator-line {
    background: $color-label-default !important;
    height: 1px !important;
  }
}

@media only screen and (max-width: 414px) {
  .show-more-spaces {
    width: 102px !important;
  }
  .start-of-space-list {
    margin-left: 102px !important;
  }

  .fixed-day-width {
    width: 102px !important;
  }

  .end-hour {
    display: none !important;
  }

  .hour {
    text-align: center !important;
  }

  .item-space-container {
    min-height: 73px !important;
  }
}

@media only screen and (max-width: 768px) {
  .calendar-container {
    &__day {
      text-align: center;
      min-height: auto;

      /deep/ .event-circle {
        display: block;
      }

      /deep/ .calendar-event-card {
        display: none;
      }

      /deep/ .calendar-event-card-compact {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .calendar-day {
    .day-short-name {
      margin-left: 0px !important;
    }
    .day-number {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px !important;
      font-size: 2rem !important;
    }
  }
}

.start-of-space-list {
  background-color: #f2f2f2;
  height: 40px;
  border-bottom: 1px solid $color-label-default;
  border-top: 2px solid $color-label-default;
}

.service-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 19ch;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.6vw;
  text-decoration: underline;
}

.popover.b-popover {
  border: 1px solid $color-label-default !important;
  opacity: 1 !important;
}

.popover.b-popover.item-detail {
  border: 1px solid #ff8105;
  border-radius: 4px;
  opacity: 1;
  .container-fluid {
    padding-bottom: 10px;
  }
  .main-content-title {
    text-align: center;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: #ff8105;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 7px;
  }
  .item-detail-content {
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #1c1d21;

    label {
      margin-bottom: 0px !important;
    }

    .item-detail-content-description {
      font-weight: bold !important;
    }

    .item-detail-content-detail {
      margin-left: 5px;
    }
  }
}

.popover.b-popover.item-detail {
  border: 1px solid #ff8105;
  border-radius: 4px;
  opacity: 1;
  width: 100%;
  max-width: 400px;
  .arrow {
    display: none;
  }
}

.item-detail.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 2rem !important;
}

.item-detail.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 2rem !important;
}

.item-detail.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 2rem !important;
}

.item-detail.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 2rem !important;
}

span.item-select {
  font-size: 0.6vw;
  text-decoration: none;
  color: #1c1d21;
  font-weight: bold;
}

.daily-space .hour {
  height: 100%;
  display: grid;
}

.daily-space .hour label {
  font-size: 16px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
}

.daily-detail .item-detail-content {
  border: 1px solid #ff8105;
  border-radius: 4px;
  padding: 15px;
  font: normal normal 700 16px/19px Lato;
  cursor: pointer;
}

.daily-detail .item-detail-content .caption {
  text-align: left;
  letter-spacing: 0;
  color: #ff8105;
  opacity: 1;
}

.daily-detail .item-detail-content .title {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 5px;
}

.daily-detail .specialist-name {
  padding: 10px 0;
}

.daily-detail .specialist-name .specialist-item {
  padding: 5px 15px;
}

.daily-detail .specialist-name .specialist-item .specialist-fullname {
  color: #ff8105 !important;
  font-weight: bold;
}
</style>
