import { apiTurnos } from '@/core/api/';

export const getParameterValue = (key) => apiTurnos.get(`api/setupparameter/${key}/value`);

export const getSpaceCreationSetup = () => {
  return apiTurnos.get(`api/setupparameter/spacecreation`);
}

export const getAllParameters = () => {
  return apiTurnos.get('api/setupparameter/list');
};

export const getParameters = (payload) => apiTurnos.get('/api/setupparameter/search', { params: { ...payload } });

export const deleteParameter = (payload) => apiTurnos.delete(`/api/setupparameter/${payload}`);

export const getSetupParameter = key => apiTurnos.get(`/api/setupparameter/${key}`);

export const submitSetupParameter = (model, isEdit) => {

  return apiTurnos[isEdit ? 'put' : 'post'](
    '/api/setupparameter',
    model)
};