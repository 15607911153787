<template>
  <section>
    <content-header :title="$t('maintenanceOfLogos')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterLogos')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getLogos(1)"
        />
      </div>

      <separator
        class="my-4"
        :text="$t('listOfLogos')"
      />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="openCreateModal"
            :text="$t('create')"
          />
        </div>
      </div>
       <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <div class="row">
        <div
          v-for="(item, index) in logos.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="item"
            @on-edit="openEditModal(item)"
            @on-delete="openDeleteModal(item)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="logos.count > 0"
        class="my-4"
        :data="logos"
        :page-size="pageSize"
        @pagination-go-page="getLogos($event)"
        @pagination-rows-per-page="getLogos(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showLogoFormModal"
      size="md"
      :title="!editModel ? $t('createLogo') : $t('editLogo')"
    >
      <logo-form
        v-if="showLogoFormModal"
        v-model="editModel"
        @close="showLogoFormModal = false"
        @submitted="getLogos()"
      />
    </custom-modal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="domainNameIsNotCorrect"
      :item="editModel"
      label="domainName"
      name="key"
      @on-confirm="onDelete()"
    />
  </section>
</template>
<script>
import CustomModal from '@/components/basics/modal/CustomModal';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import LogoForm from './components/LogoForm';
import DeleteModal from '@/components/DeleteModal';

import {
  getLogos as _getLogos,
  getLogo as _getLogo,
  deleteLogo as _deleteLogo
} from '@/services/LogoService';

export default {
  name: 'LogosSearch',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,    
    DeleteModal,
    LogoForm,
    CustomModal
  },
  data() {
    return {
      selectedFilters: {
        domain: '',
      },
      logos: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      showCreateModal: false,
      pageIndex: 1,

      showLogoFormModal: false,
      showDeleteModal: false,

      editModel: null,
    };
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'key',
          label: this.$t('domain'),
          component: 'BaseInput',
          placeholder: this.$t('domain'),
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.key,
          value: this.selectedFilters.key,
        },
      ];
    },
  },
  methods: {
  async openEditModal(item) {
    await _getLogo(item.id).then(({ data }) => {
      this.editModel = data;
      this.showLogoFormModal = true;
    });
  },
  onDelete() {
    _deleteLogo(this.editModel.id)
      .then(async () => {
        this.ShowSuccessToast(this.$t('successfullyDeleted'));
        this.showDeleteModal = false;
        await this.getLogos();
        this.editModel = null;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  openCreateModal() {
    this.showLogoFormModal = true;
    this.editModel = null;
  },
  openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
    },
  async getLogos(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        key: this.selectedFilters.key,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getLogos(payload)
        .then(({ data }) => {
          this.logos = data;
          this.noResultsFound = !this.logos.count;
        })
    },
  },
};
</script>

