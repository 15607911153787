<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-center my-4" cols="12">
      <h6 class="text-uppercase">
        {{ $t('assignClients') }}
      </h6>
    </div>
    <div class="col-md-12">
      <div class="row">
        <sub-header :title="$t('listOfClients')" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="row text-center">
        <div v-for="(client, index) in clients" :key="index" class="col-4 mb-4">
          <p class="m-0">
            {{ client.name }}
          </p>
          <b-form-checkbox v-model="client.selected" size="lg" switch />
        </div>
      </div>
    </div>
    <div class="col-md-12 text-right">
      <base-filled-button
        bg-color="#707070"
        class="float-left mx-0"
        icon-class="far fa-times-circle"
        :on-click="() => $emit('close')"
        :text="$t('cancel')"
      />
      <b-button variant="primary" @click="onSubmit()">
        <i class="fal fa-save" /> {{ $t('save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import {
  getIntegratedClientsAsync as _getIntegratedClientsAsync,
  submitIntegrationClientsAsync as _submitIntegrationClientsAsync,
} from '@/services/KioskService';
export default {
  name: 'AssignClient',
  components: {
    SubHeader,
  },
  props: {
    kioskId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      clients: [],
    };
  },
  mounted() {
    setTimeout(() => this.getClients(), 300);
  },
  methods: {
    async getClients() {
      await _getIntegratedClientsAsync(this.kioskId)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      await _submitIntegrationClientsAsync(
        this.kioskId,
        this.clients.filter((x) => x.selected).map((x) => ({ clientId: x.id })),
      )
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
