<template>
  <b-row>
    <b-col
      class="my-4"
      cols="12"
    >
      <h4 class="font-weight-bolder float-left">
        {{ $t('serviceConfiguration') }}
      </h4>
      <hr style="margin-left: 100px" />
    </b-col>
    <b-col cols="12">
      <b-card border-variant="light">
        <b-row>
          <b-col
            v-if="noQueue"
            cols="12"
          >
            <alert-message
              bg-color="#ff5d5d"
              class="mt-4"
              icon-class="far fa-times-circle"
              :message="$t('noRecordWasFound')"
            />
          </b-col>
          <b-col
            v-for="(queue, index) in queues"
            :key="index"
            cols="12"
          >
            <b-row>
              <b-col
                lg="3"
                md="6"
              >
                <base-input
                  :disabled="true"
                  :label="$t('selectRow')"
                  :value="queue.name"
                />
              </b-col>
              <validation-observer
                :ref="`services${queue.queueId}FormRef`"
                class="col-lg-9 col-md-12"
                novalidate
              >
                <b-row
                  v-for="(service, i) in queue.screenServices"
                  :key="i"
                >
                  <b-col
                    md="4"
                  >
                    <b-button
                      v-if="queue.screenServices.length > 1"
                      class=" mr-2 float-left"
                      style="margin-top: 29px"
                      variant="danger"
                      @click="onRemove(service.queueId, i)"
                    >
                      <i class="fas fa-trash" />
                    </b-button>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <filter-select
                        v-model="service.serviceId"
                        :error="errors[0]"
                        :error-msg="$t('mustSelectOne')"
                        fieldtext="name"
                        fieldvalue="id"
                        :label="$t('selectService')"
                        :options="getServiceTypes(queue, service.serviceId)"
                      />
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="2"
                    sm="3"
                  >
                    <filter-numeric
                      v-model="service.orderAutoRegister"
                      :min="1"
                      :title="$t('order')"
                    />
                  </b-col>
                  <b-col
                    md="4"
                    sm="9"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <filter-select
                        v-model="service.redirectScreenId"
                        :error="errors[0]"
                        :error-msg="$t('mustSelectOne')"
                        fieldtext="name"
                        fieldvalue="kioskScreenId"
                        :label="$t('sendTo')"
                        :options="screens"
                      />
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="d-flex justify-content-end align-items-end pb-3"
                    md="2"
                  >
                    <b-button
                      v-if="showAddService(service.queueId, i)"
                      variant="outline-primary"
                      @click="onAdd(service.queueId)"
                    >
                      <i class="fas fa-plus" /> {{ $t('service') }}
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
              
              
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="queues.length"
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import { getQueuesWithRelatedService as _getQueuesWithRelatedService } from '@/services/ServicesService';
import { submitScreenService as _submitScreenService } from '@/services/KioskService';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

export default {
  name: 'ScreenService',
  components: {
    AlertMessage,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
    screenNumber: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      queues: [],
      noQueue: false,
    };
  },
  mounted() {
    this.loadServices();
  },
  computed: {
    screens() {
      return this.kiosk.screens.filter(
        (_, index) => index != this.screenNumber - 1,
      );
    },
  },
  methods: {
    generateService(queueId) {
      return {
        queueId,
        serviceId: null,
        redirectScreenId: null,
        kioskScreenServiceId: 0,
        orderAutoRegister: 1
      };
    },
    loadServices() {
      _getQueuesWithRelatedService(
        this.kiosk.locationConfigurationId,
        this.value.specialField,
        this.value.kioskScreenId,
      )
        .then((response) => {
          this.queues = response.data.map(queue => {
            return {
              ...queue,
              screenServices: queue.screenServices.length ? queue.screenServices : [this.generateService(queue.queueId)]
            }
          });
          this.noQueue = !this.queues.length;
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
    getServiceTypes(queue, serviceId) {
      const selectedServices = queue.screenServices.filter(x => x.serviceId).map(x => x.serviceId);
      return  queue.serviceCodeServiceTypes.filter(x => !selectedServices.includes(x.id) || x.id === serviceId);
    },
    getSelectedServiceName(queue, serviceId) {
      if (!serviceId) return '';
      return queue.serviceCodeServiceTypes.find(x => x.id === serviceId).name;
    },
    async onAdd(queueId) {
      if (!(await this.$refs[`services${queueId}FormRef`][0].validate())) return;

      const queue = this.queues.find(x => x.queueId == queueId);
      queue.screenServices.push(this.generateService(queueId));
    },
    onRemove(queueId, index) {
      const queue = this.queues.find(x => x.queueId == queueId);
      queue.screenServices.splice(index, 1);
    },
    showAddService(queueId, index) {
      const queue = this.queues.find(x => x.queueId == queueId);
      if (queue.serviceCodeServiceTypes.length === index + 1) return false;
      return queue.screenServices.length === index + 1;
    },
    async onSubmit() {
      const services = this.queues.reduce((prev, curr) => {
        return prev.concat(curr.screenServices.filter(service => service.serviceId && service.redirectScreenId));
      }, []);

      await _submitScreenService({
        kioskId: this.kiosk.kioskId,
        kioskScreenId: this.value.kioskScreenId,
        services
      })
        .then((response) => {
          this.value.services = response.data;
          this.$emit('submitted');
          this.loadServices();
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
