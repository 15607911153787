<template>
  <b-row>
    <b-col cols="12">
      <b-card border-variant="light">
        <b-row>
          <b-col
            lg="3"
            md="6"
          >
            <filter-numeric
              v-model="value.assignScreen.screenCount"
              :clearable="false"
              :min="1"
              :title="$t('screensAmount')"
            />
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <filter-select
              v-model="value.assignScreen.canSendNotifications"
              :clearable="false"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('sendingNotifications')"
              :options="notificationOptions"
              translate
            />
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <span class="col-form-label">{{
              $t('integrationWithApiWebService')
            }}</span>
            <br />
            <b-button
              class="mt-1"
              variant="outline-info"
              @click="showIntegrationModal = true"
            >
              {{ $t('configuration') }}
            </b-button>
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <filter-select
              v-model="value.assignScreen.relationIds"
              fieldtext="name"
              fieldvalue="id"
              is-multiple
              :label="$t('relations')"
              :options="kiosks"
            />
          </b-col>
          <b-col
            lg="3"
            offset-lg="9"
          >
            <b-button
              block
              class="mt-4"
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
        <custom-modal
          v-model="showIntegrationModal"
          size="lg"
        >
        <AssignClients :kiosk-id="value.kioskId" v-if="showIntegrationModal" @close="showIntegrationModal = false"  />
        </custom-modal>
      </b-card>
    </b-col>
    <b-col
      class="mt-5"
      cols="12"
    >
    <base-filled-button
        bg-color="#707070"
        class="float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'kiosksList' })"
        :text="$t('cancel')"
      />
      <b-button
        class="float-left"
        variant="primary"
        @click="$emit('continue', -1)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </b-button>
      <b-button
        class="float-right"
        variant="primary"
        @click="onSubmit(true)"
      >
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import AssignClients from './components/AssignClients';
import { setScreenTypeMetadata as _setScreenTypeMetadata } from '@/services/KioskService';
import { getKioskList as _getKioskList } from '@/services/CompanyService';

export default {
  name: 'AssignScreen',
  components: {
    CustomModal,
    AssignClients
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notificationOptions: ConfirmationOptions,
      showIntegrationModal: false,
      kiosks: []
    };
  },
  mounted() {
    _getKioskList(this.value.companyId).then(({data}) => this.kiosks = data.filter(x => x.id != this.value.kioskId))
  },
  methods: {
    onSubmit(next = false) {
      _setScreenTypeMetadata({
        kioskId: this.value.kioskId,
        ...this.value.assignScreen,
      })
        .then((response) => {
          this.$emit('change-screens', response.data);
          if (next) setTimeout(() => this.$emit('continue', 1), 200);
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
