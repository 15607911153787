<template>
  <b-card
    border-variant="light"
    class="pt-2"
  >
    <b-row>
      <b-col md="3">
        <p>
          <b>{{ $t('kioskName') }}</b>
          <br />
          {{ value.name }}
        </p>
      </b-col>
      <b-col md="3">
        <p>
          <b>{{ $t('company') }}</b>
          <br />
          {{ value.companyName }}
        </p>
      </b-col>
      <b-col md="3">
        <b class="text-capitalize">{{ $t('localization') }}:</b>
        <br />
        {{ value.locationConfigurationName }}
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-2">
        <action-button
          :actions="actions"
          class="float-right"
          :text="$t('actions')"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'KioskSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () =>
            this.$emit('on-edit', this.value)
        },
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#198E0F',
          action: () =>
            this.$emit('on-clone', this.value)
        },
        {
          label: this.$t('view'),
          iconClass: 'far fa-eye',
          iconColor: '#198E0F',
          action: () => this.showKioskPreview(this.value),
          visible: () => {
            return this.value.macAddressList != null;
          },
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
  methods: {
    showKioskPreview(kiosk) {
      this.$emit('on-show-preview', kiosk);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
