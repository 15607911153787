<template>
  <div class="main-content secondary-content mb-4">
    <b-row>
      <b-col cols="12">
        <div class="caption mb-4 text-left main-content-title">
          {{ caption }}
        </div>
      </b-col>
    </b-row>
    <div id="generalInfo">
      <b-row>
        <b-col cols="12" lg="3" sm="3">
          <b-form-group :label="$t('specialists')">
            <label class="strong">{{ space.specialistCount || '-' }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="3" sm="3">
          <b-form-group :label="$t('services')">
            <label class="strong">{{ space.servicesCount || '-' }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2" sm="3">
          <b-form-group :label="$t('days')">
            <label class="strong">{{ space.daysCount || '-' }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2" sm="3">
          <b-form-group :label="$t('spaces') | capitalize">
            <label class="strong">{{ space.spacesCount || '-' }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2" sm="3">
          <b-form-group :label="$t('totalSpaces')">
            <label class="strong">{{ space.totalSpaces || '-' }}</label>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
    spaceCalculation: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  setup() {},
  data() {
    return {
      caption: this.title,
      space: {},
      // isM: isM,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  mounted() {
    this.caption = this.title;
    this.space = this.spaceCalculation;
  },
  watch: {
    spaceCalculation(val) {
      this.space = val;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.main-content,
.secondary-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 60px !important;
  padding-top: 40px !important;
  padding-bottom: 0px !important;
}
.secondary-content {
  padding-top: 30px !important;
  padding-bottom: 10px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
</style>