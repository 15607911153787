<template>
  <b-row>
    <b-col class="my-4" cols="12">
      <h4 class="font-weight-bolder float-left">
        {{ $t('office') }}
      </h4>
      <hr style="margin-left: 100px" />
    </b-col>
    <b-col cols="12">
      <b-card border-variant="light">
        <b-row>
          <b-col v-if="noQueue" cols="12">
            <alert-message
              bg-color="#ff5d5d"
              class="mt-4"
              icon-class="far fa-times-circle"
              :message="$t('noRecordWasFound')"
            />
          </b-col>
          <b-col v-for="(queue, index) in queues" :key="index" cols="12">
            <b-row>
              <b-col md="6" lg="3">
                <base-input
                  v-model="queue.queueName"
                  :disabled="true"
                  :label="$t('selectRow')"
                />
              </b-col>
              <b-col md="6" lg="3">
                <filter-numeric
                  v-model="queue.orderAutoRegister"
                  :min="1"
                  :title="$t('buttonOrder')"
                />
              </b-col>
              <b-col md="6" lg="3">
                <base-input
                  v-model="queue.code"
                  :label="$t('code')"
                />
              </b-col>
              <b-col md="6" lg="3">
                <filter-select
                  v-model="queue.redirectScreenId"
                  fieldtext="name"
                  fieldvalue="kioskScreenId"
                  :label="`${$t('button')} ${queue.queueName} ${$t('sendTo')}`"
                  :options="screens"
                />
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="queues.length"
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import { getQueuesForKioskScreenList as _getQueuesForKioskScreenList } from '@/services/ServicesService';
import { submitScreenQueues as _submitScreenQueues } from '@/services/KioskService';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

export default {
  name: 'ScreenQueue',
  components: {
    AlertMessage,
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
    screenNumber: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      queues: [],
      noQueue: false,
    };
  },
  mounted() {
    this.loadQueues();
  },
  computed: {
    screens() {
      return this.kiosk.screens.filter(
        (_, index) => index != this.screenNumber - 1,
      );
    },
  },
  methods: {
    loadQueues() {
      _getQueuesForKioskScreenList(
        this.kiosk.locationConfigurationId,
        this.value.specialField,
        this.value.kioskScreenId,
      )
        .then((response) => {
          this.queues = response.data;
          this.noQueue = !this.queues.length;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _submitScreenQueues({
        kioskId: this.kiosk.kioskId,
        kioskScreenId: this.value.kioskScreenId,
        queues: this.queues.filter((x) => x.queueName && !!x.redirectScreenId),
      })
        .then((response) => {
          this.value.queues = response.data;
          this.$emit('submitted');
          this.loadQueues();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
