<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      :cascade-activation="true"
      class="mb-5"
      :step="0"
      :tabs="Tabs"
    />
    <div class="row">
      <div class="col-md-12">
        <validation-observer
          ref="cloneFormRef"
          novalidate
        >
          <create
            class="my-3"
            hide-submit-button
            :value="kiosk"
            @continue="onContinue($event)"
          >
            <div class="col-12 mt-3">
              <b-card border-variant="light">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox
                      v-model="kiosk.copyTexts"
                      switch
                    >
                      {{ $t('copyTexts') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="kiosk.copyColors"
                      switch
                    >
                      {{ $t('copyColors') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox
                      v-model="kiosk.copyLogos"
                      switch
                    >
                      {{ $t('copyLogos') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="kiosk.copyImages"
                      switch
                    >
                      {{ $t('copyImages') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-card>
            </div>

            <template v-slot:buttons>
              <button
                class="btn btn-primary"
                @click="onSubmit()"
              >
                <i class="far fa-save" /> {{ $t('clone') }}
              </button>
            </template>
          </create>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Create from './Create';
import { getKiosk as _getKiosk } from '@/services/KioskService';
import { createKioskClone as _createKioskClone } from '@/services/KioskService';
import ScreenTypes from '@/constants/KioskScreenTypes';
import StepWizard from '@/components/StepWizard';

export default {
  name: 'Clone',
  components: {
    StepWizard,
    Create,
  },
  data() {
    return {
      kiosk: {
        kioskId: null,
        companyId: null,
        locationConfigurationId: null,
        locationConfigurationName: null,
        name: null,
        macAddress: [],
        screens: [],
        canSendNotifications: false,
        copyTexts: true,
        copyColors: true,
        copyLogos: true,
        copyImages: true,
      },
    };
  },
  computed: {
    Tabs() {
      return [
        {
          name: this.$t('kioskCreation'),
          icon: 'far fa-edit',
          disabled: false,
        },
        ...this.kiosk.screens,
      ];
    },
  },
  mounted() {
    this.kiosk.kioskId = Number(this.$route.params.id);
    this.loadKiosk(this.kiosk.kioskId);
  },
  methods: {
    orderScreens(screens) {
      const baseScreenTypeIds = [ScreenTypes.Notification, ScreenTypes.Turn];

      const optionalScreens = screens.filter(
        (x) => !baseScreenTypeIds.includes(x.screenTypeId),
      );

      const baseScreens = screens
        .filter((x) => baseScreenTypeIds.includes(x.screenTypeId))
        .sort((a, b) => {
          if (a.screenTypeId > b.screenTypeId) return 1;
          if (a.screenTypeId < b.screenTypeId) return -1;
          return 0;
        });

      return optionalScreens.concat(baseScreens).map((screen, index) => {
        const name = !baseScreenTypeIds.includes(screen.screenTypeId)
          ? `${this.$t('screen')} ${index + 1}`
          : '';
        let icon = 'far fa-layer-plus';
        if (screen.screenTypeId === ScreenTypes.Notification)
          icon = 'far fa-bell-on';
        if (screen.screenTypeId === ScreenTypes.Turn)
          icon = 'fas fa-ticket-alt';

        return {
          kioskScreenId: screen.id,
          name: `${name}${name && screen.name ? ' - ' : ''}${
            screen.name || ''
          }`,
          kioskScreenTypeId: screen.screenTypeId,
          specialField: screen.specialField,
          isValid: false,
          icon,
        };
      });
    },
    loadKiosk(id) {
      _getKiosk(id)
        .then((response) => {
          const data = response.data;
          this.kiosk = {
            ...this.kiosk,
            companyId: data.companyId,
            locationConfigurationId: data.locationConfigurationId,
            locationConfigurationName: data.locationConfigurationName,
            name: `${data.name} - copy`,
            macAddress: [],
            canSendNotifications: !!data.screens.find(
              (screen) => screen.screenTypeId === ScreenTypes.Notification,
            ),
            screens: this.orderScreens(data.screens),
          };
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'kiosksList' });
        });
    },
    onContinue(event) {
      if (event === -1) this.$router.push({ name: 'kiosksList' });
    },
    async onSubmit() {
      if (!(await this.$refs.cloneFormRef.validate())) return;
      if (!this.kiosk.macAddress.length)
        return this.ShowToast('Error', this.$t('macAddressError'), 'error');

      _createKioskClone(this.kiosk).then((response) => {
        this.ShowToast(
          this.$t('success'),
          this.$t('kioskClonedSuccessfully'),
          'success',
        );
        this.$router.push({ name: 'kioskEdit', params: { id: response.data } });
      });
    },
  },
};
</script>
