<template>
  <b-modal
    id="custom-alert-modal"
    ref="custom-alert-modal-ref"
    centered
    size="md"
    @hidden="onClose()"
  >
    <template #modal-header>
      <b-col cols="12">
        <div class="caption mt-4 mb-4 text-center main-content-title">
          {{ title }}
        </div>
      </b-col>
    </template>
    <b-container class="text-center" fluid>
      <b-row>
        <b-col class="mb-4" cols="12">
          <label class="color-black modal-confirmation-label">
            {{ message }}
          </label>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-col cols="12">
        <div class="buttons-container d-flex justify-content-center mb-4">
          <slot name="buttoms" />
          <button
            class="btn btn-primary mr-4 pl-5 pr-5"
            type="button"
            @click="confirm()"
          >
            <slot>{{ $t('yes') }}</slot>
          </button>
          <button
            v-show="showCancelButton"
            class="btn btn-default pl-5 pr-5"
            type="button"
            @click="cancel()"
          >
            {{ $t('no') }}
          </button>
        </div>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(val) {
      this.$refs['custom-alert-modal-ref'][val ? 'show' : 'hide']();
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
      this.$refs['custom-alert-modal-ref'].hide();
    },
    confirm() {
      this.$emit('on-confirm', true);
      this.onClose();
    },
    cancel() {
      this.$emit('on-confirm', false);
      this.onClose();
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
.strong {
  font-weight: bold !important;
}
#appointments-container {
  /* background-color: $color-app-background; */
}
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 20px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.title-description {
  margin-top: 18px;
  margin-bottom: 18px;
}
.filters label {
  font: normal normal normal 14px/21px Lato;
  letter-spacing: 0px;
  color: $color-font-secondary;
  opacity: 1;
}

.label-default {
  background-color: $color-label-default;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: $color-white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.label-round {
  border-radius: 1.25em;
  padding-right: 5x;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  width: 60%;
}

[data-role='remove'] {
  margin-left: 8px;
  cursor: pointer;
}

[data-role='remove']:after {
  content: 'x';
  padding: 0px 2px;
}

.pull-right {
  float: right;
}

.color-black {
  color: $color-font-primary !important;
}

.fs-18 {
  font-size: 18px !important;
}

.form-control {
  border: 1px solid $color-label-default;
}

.left-divider {
  border-left: 1px solid $color-label-default;
  opacity: 1;
}

// no scoped things that where initially here
.datepicker .input-wrapper,
.timepicker .input-wrapper {
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $color-font-secondary;
  background-color: $color-white;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.75rem + 2px);
  overflow-x: hidden;
}

.datepicker .input-wrapper .suffix,
.timepicker .input-wrapper .suffix {
  display: none;
}
.datepicker .input-wrapper .prefix,
.timepicker .input-wrapper .prefix {
  color: $color-primary;
  margin-right: 6px;
}

.datepicker .input-wrapper .prefix svg,
.timepicker .input-wrapper .prefix svg {
  width: 18px;
  height: 20px;
}

.datepicker .input-wrapper input,
.timepicker .input-wrapper input {
  outline: 0;
  border: none;
  -moz-outline-style: none;
  font-family: lato, regular;
}
</style>
<style lang="scss" scoped>
.cus-radiobutton-container > label {
  top: 5px;
}
.weekdays-container .cus-checkbox-container > label {
  top: 5px;
}
.duration {
  width: 100%;
}

.col-form-label {
  font: normal normal normal 14px/21px Lato !important;
  color: $color-font-secondary !important;
}

label {
  font: normal normal normal 14px/21px Lato;
  color: #1c1c1d !important;
}

.modal-confirmation-label {
  font-size: 16px;
  font-weight: bold;
}
</style>
