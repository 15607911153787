<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col
          lg="3"
          md="6"
          xl="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <filter-select
              v-model="value.kioskScreenTypeId"
              :clearable="false"
              :disabled="typesToRemove.includes(value.kioskScreenTypeId)"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('screenType')"
              :options="screenTypes"
            />
          </validation-provider>
        </b-col>
        <b-col
          lg="3"
          md="6"
          xl="2"
        >
          <filter-color
            v-model="value.buttonBackgroundColor"
            :label="$t('buttonColor')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
          xl="2"
        >
          <filter-color
            v-model="value.buttonBorderColor"
            :label="$t('borderColor')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
          xl="2"
        >
          <filter-color
            v-model="value.buttonTextColor"
            :label="$t('textColor')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
        >
          <filter-numeric
            v-model="value.buttonRadiusSize"
            measurement-unit="pt"
            :min="1"
            :title="$t('radiusSize')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
        >
          <filter-numeric
            v-model="value.buttonBorderSize"
            measurement-unit="pt"
            :min="0"
            :title="$t('borderSize')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
        >
          <filter-numeric
            v-model="value.buttonShadowSize"
            measurement-unit="pt"
            :min="0"
            :title="$t('shadowSize')"
          />
        </b-col>
        <b-col
          lg="3"
          md="6"
        >
          <filter-color
            v-model="value.buttonShadowColor"
            :label="$t('shadowColor')"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="haveExtraFields"
      cols="12"
    >
      <hr />
      <screen-header :value="value" />
    </b-col>
  </b-row>
</template>

<script>
import ScreenTypes from '@/constants/KioskScreenTypes';
import ScreenHeader from './ScreenHeader';

export default {
  name: 'ScreenType',
  components: {
    ScreenHeader,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      haveExtraFields: false,
      typesWithExtraFields: [
        ScreenTypes.Button,
        ScreenTypes.Queue,
        ScreenTypes.Service,
        ScreenTypes.Client,
        ScreenTypes.CheckIn,
        ScreenTypes.Notification,
        ScreenTypes.Turn,
        ScreenTypes.SpecialField,
        ScreenTypes.Information,
      ],
      typesToRemove: [ScreenTypes.Notification, ScreenTypes.Turn],
      screenTypes: [],
    };
  },
  created() {
    if (!this.kiosk.haveSpecialFields && this.value.kioskScreenTypeId != ScreenTypes.SpecialField)
      this.typesToRemove.push(ScreenTypes.SpecialField);

    this.screenTypes = this.types.filter((type) =>
          this.value.kioskScreenTypeId !== type.id
            ? !this.typesToRemove.includes(type.id)
            : true)
  },
  watch: {
    'value.kioskScreenTypeId': {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.value.name = this.types.find((x) => x.id === val).name;
        this.haveExtraFields = this.typesWithExtraFields.includes(val);

        const isCLient = val === ScreenTypes.Client;

        this.value.paternalLastNameAsk = isCLient ? false : null;
        this.value.paternalLastNameRequired = isCLient ? false : null;
        this.value.maternalLastNameAsk = isCLient ? false : null;
        this.value.maternalLastNameRequired = isCLient ? false : null;
        this.value.displayKeyboardForClient = isCLient ? false : null;
      },
    },
  },
};
</script>
