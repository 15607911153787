<template>
  <b-row class="mb-5">
    <b-col
      class="my-4"
      cols="12"
    >
      <h4 class="font-weight-bolder float-left">
        {{ $t('rowsAndAppointmentService') }}
      </h4>
      <hr style="margin-left: 100px" />
    </b-col>
    <b-col cols="12">
      <b-card border-variant="light">
        <b-row class="p-3">
          <b-col
            class="header border round-top-left"
            cols="6"
          >
            <h4 class="text-center py-2">
              {{ $t('row') }}
            </h4>
          </b-col>
          <b-col
            class="header border round-top-right"
            cols="6"
          >
            <h4 class="text-center py-2">
              {{ $t('appointmentService') }}
            </h4>
          </b-col>
          <template v-for="(service, index) in services">
            <b-col
              :key="`s_${index}`"
              class="border kiosk_service_row"
              cols="6"
            >
              <p>{{ service.name }}</p>
            </b-col>
            <b-col
              :key="`t_${index}`"
              class="border kiosk_service_row"
              cols="6"
            >
              <p>{{ service.type }}</p>
            </b-col>
          </template>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { getWithServiceTypes } from '@/services/ServicesService';

export default {
  name: 'ScheenChecking',
  components: {},
  data() {
    return {
      services: [],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
    locationConfigurationId: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    await getWithServiceTypes(this.locationConfigurationId)
      .then((resp) => {
        this.services = resp.data;
      })
      .catch(error => this.ShowErrorToast(error.response.data.message));
  },
};
</script>

<style scoped lang="scss">

.header {
  background-color: $color-primary;
  color: $color-white;
}

.round-top-left {
  border-top-left-radius: 0.25em;
}

.round-top-right {
  border-top-right-radius: 0.25em;
}

.kiosk_service_row {
  padding: 12px 0 4px 12px;
}
</style>
