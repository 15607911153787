<template>
  <section>
    <content-header
      :title="$t('systemErrorsLegacy')"
    />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterSystemErrors')" />
        </div>
        <validation-observer ref="errorsFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="search(1)"
          />
        </validation-observer>
      </div>

      <separator class="my-4" :text="$t('errorsList')" />
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(systemError, index) in systemErrors.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="systemError"
          />
        </div>
      </div>
      <custom-pagination
        v-if="systemErrors.count > 0"
        id="pgPager"
        class="my-4"
        :data="systemErrors"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
  </section>
</template>

<script>

import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import { getLegacySystemErrors as _getLegacySystemErrors } from '@/services/ErrorService';
import { getApplicationCategories as _getApplicationCategories } from '@/services/CodeService'

export default {
  name: 'LegacySystemErrorsList',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
  },
  data() {
    return {
      selectedFilters: {
        dateFrom: this.$moment().format(),
        dateTo: this.$moment().format(),
        category: []
      },
      filters: {
        categories: []
      },
      systemErrors: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'dateFrom',
          label: this.$t('dateFrom'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.dateFrom,
          value: this.selectedFilters.dateFrom,
          onChanged: this.onDateFromChanged,
        },
        {
          rules: 'required',
          name: 'dateTo',
          label: this.$t('dateTo'),
          component: 'BaseDatetimePicker',
          format: 'dd/MMM/yyyy',
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.dateTo,
          value: this.selectedFilters.dateTo,
          onChanged: this.onDateToChanged,
        },
        {
          name: 'category',
          label: this.$t('category'),
          component: 'FilterMultiSelect',
          options: this.filters.categories,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-4 col-lg-3',
          initialValue: this.selectedFilters.category,
          onChanged: this.onCategoryChanged,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getApplicationCategories()
        .then((response) => {
          this.filters.categories = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onDateFromChanged(value) {
      this.selectedFilters.dateFrom = value;
    },
    onDateToChanged(value) {
      this.selectedFilters.dateTo = value;
    },
    onCategoryChanged(value) {
      if (!value) {
        this.selectedFilters.category = [];

        return;
      }

      this.selectedFilters.category = [value];
    },
    async search(pageIndex = null, pageSize = null) {
      const isValid = await this.$refs.errorsFormRef.validate();

      if (!isValid) {
        return;
      }

      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        dateFrom:
          this.$moment(this.selectedFilters.dateFrom).format('YYYY-MM-DD'),
        dateTo: this.$moment(this.selectedFilters.dateTo).format('YYYY-MM-DD'),
        categoryId: this.selectedFilters.category.length ? this.selectedFilters.category[0].id : null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getLegacySystemErrors(payload)
        .then(({ data }) => {
          this.systemErrors = data;
          this.noResultsFound = !this.systemErrors.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
