<template>
  <div class="item-space">
    <slot v-if="hasContentSlot" name="content" />
    <div v-else class="month-item">
      <span class="d-none d-sm-block" @click="onItemClick">
        {{ itemCount }} {{ itemCount == 1 ? $t('item') : $t('items') }}
      </span>
      <span class="d-sm-none" @click="onItemClick">
        {{ itemCount }} {{ itemCount == 1 ? $t('item') : $t('items') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarEventMonth',
  props: {
    currentDay: {
      type: Date,
      default: () => null,
    },
    itemCount: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    hasContentSlot() {
      return !!this.$slots.content;
    },
  },
  methods: {
    onItemClick() {
      this.$emit('on-selected-day', this.currentDay);
    },
  },
};
</script>

<style scoped lang="scss">
.item-space {
  font: normal normal normal 16px/21px Lato;
  background: $color-white 0% 0% no-repeat padding-box;
  text-align: center;
  margin-top: 39px;

  .month-item {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    color: $color-primary;
  }
}
</style>
