<template>
  <div>
    <h1>Acceso denegado</h1>
  </div>
</template>
<script>
export default {
  name: 'Forbidden'
};
</script>
