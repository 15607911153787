<template>
  <div class="card pt-2 border-light search-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('errorMessage') }}: </label>
            <span>{{ value.errorMessage }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('errorLocation') }}: </label>
            <span>{{ value.errorLocation }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('errorDate') }}: </label>
            <span>{{ $moment(value.errorDate).format('MM/DD/yyyy hh:mm:ss A') }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('pageOfOrigin') }}: </label>
            <span>{{ value.exceptionAtPageRequered }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('turnoPRCategory') }}: </label>
            <span>{{ value.categoryName }}</span>
          </p>
        </div>
        <div class="col col-sm-12 col-md-6 mt-1">
          <p>
            <label>{{ $t('exceptionSent') }}: </label>
            <span>{{ value.isSent ? $t('yes') : $t('no') }}</span>
          </p>
        </div>
        <div class="col col-sm-12 mt-1">
          <p>
            <label>{{ $t('internalErrorException') }}: </label>
            <span>{{ value.stackTrace || '-' }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegacyErrorLogSearchResult',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { };
  },
};
</script>

<style lang="scss" scoped>
.search-result p {
  margin-bottom: 0.2rem;
}

.search-result p i {
  cursor: pointer;
  font-size: 18px;
}

.search-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.search-result p i,
.search-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
