<template>
  <b-row>
    <b-col md="4">
      <filter-select
        v-model="value.displayTurnNumber"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('displayPersonTurn')"
        :options="options"
        translate
      />
    </b-col>
    <b-col md="4">
      <filter-select
        v-model="value.displayQueuePosition"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('displayHowMuchIsLeftBefore')"
        :options="options"
        translate
      />
    </b-col>
    <b-col md="4">
      <filter-select
        v-model="value.displayRowName"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('displayRowName')"
        :options="options"
        translate
      />
    </b-col>
    <b-col md="6">
      <validation-provider v-slot="{ errors }" rules="max:64">
        <base-input
          v-model="value.homeButtonText"
          :error="errors[0]"
          :error-msg="$t('invalidField')"
          :label="`${$t('homeButtonText')} ESP`"
        />
      </validation-provider>
    </b-col>
    <b-col md="6">
      <validation-provider v-slot="{ errors }" rules="max:64">
        <base-input
          v-model="value.homeButtonTextEN"
          :error="errors[0]"
          :error-msg="$t('invalidField')"
          :label="`${$t('homeButtonText')} ENG`"
        />
      </validation-provider>
    </b-col>
    <b-col md="6">
      <b-form-checkbox v-model="value.displayPreferentialOption" switch>
        {{ $t('activatePreferentialTurnOption') }}
      </b-form-checkbox>
    </b-col>
    <div class="col-lg-6" />
    <div class="col-md-12">
      <hr />
    </div>
    <div class="col-md-4 mt-4">
      <b-form-checkbox class="mt-1" v-model="value.showWaitTime" switch>
        {{ $t('showWaitTime') }}
      </b-form-checkbox>
    </div>

    <div class="col-lg-8">
      <validation-provider v-slot="{ errors }" rules="max:256">
        <base-input
          v-model="value.textWaitingTime"
          :disabled="!value.showWaitTime"
          :error="errors[0]"
          :error-msg="$t('invalidField')"
          :label="$t('messageWaitingTime')"
        />
      </validation-provider>
    </div>
    <div class="col-md-12">
      <hr />
    </div>
  </b-row>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import BaseInput from '@/components/BaseInput';
export default {
  name: 'ScreenTurn',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: ConfirmationOptions,
    };
  },
};
</script>
