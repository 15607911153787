<template>
  <b-row>
    <b-col
      class="my-4"
      cols="12"
    >
      <h4 class="font-weight-bolder float-left">
        {{ $t('dataEntryScreen') }}
      </h4>
      <hr />
    </b-col>
    <b-col>
      <b-card border-variant="light">
        <screen-header :value="model" />
        <b-row>
          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.isEmail"
              :clearable="false"
              :error="error"
              :error-msg="$t('mustSelectOne')"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('requestEmail')"
              :options="options"
              translate
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.isEmailRequired"
              :clearable="false"
              :disabled="!model.isEmail"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('emailRequired')"
              :options="options"
              translate
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.displayKeyboardForEmail"
              :clearable="false"
              :disabled="!model.isEmail"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('displayKeyboardForEmail')"
              :options="options"
              translate
            />
          </b-col>

          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.isSMS"
              :clearable="false"
              :error="error"
              :error-msg="$t('mustSelectOne')"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('requestPhone')"
              :options="options"
              translate
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.isSMSRequired"
              :clearable="false"
              :disabled="!model.isSMS"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('phoneRequired')"
              :options="options"
              translate
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
          >
            <filter-select
              v-model="model.displayKeyboardForSMS"
              :clearable="false"
              :disabled="!model.isSMS"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('displayKeyboardForPhone')"
              :options="options"
              translate
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="mt-1"
            cols="12"
          >
            <hr />
            <div class="clearfix"></div>
            <h4 class="float-left mb-3">
              {{ $t('termsAndConditions') }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-checkbox
              v-model="model.hasCustomTermsAndConditions"
              switch
            >
              {{ $t('hasCustomText') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            v-show="model.hasCustomTermsAndConditions"
            cols="6"
          >
            <b-form-checkbox class="chkMiddle" v-model="isEnglishMode" switch>
              {{ $t('showEnglish') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-show="model.hasCustomTermsAndConditions"
            cols="12"
            class="pt-3"
          >
            <editor v-model="termsAndConditionsText" :init="editorInit" />
          </b-col>
          <b-col class="text-right mt-3">
            <b-button
              variant="outline-primary"
              @click="onSubmit()"
            >
              <i class="far fa-save" /> {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ScreenHeader from './ScreenHeader';
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import { submitScreenType as _submitScreenType } from '@/services/KioskService';

import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'ScreenNotification',
  components: {
    ScreenHeader,
    Editor,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: ConfirmationOptions,
      isEnglishMode: false,
      model: {
        topText: null,
        topTextEN: null,
        topTextColor: null,
        topTextSize: null,
        topTextBoldPercentage: null,
        isEmail: false,
        isSMS: false,
        displayKeyboardForEmail: false,
        displayKeyboardForSMS: false,
        isEmailRequired: false,
        isSMSRequired: false,
        hasCustomTermsAndConditions: false,
        termsAndConditionsText: null,
        termsAndConditionsTextEN: null,
      },
      error: '',
    };
  },
  computed: {
    termsAndConditionsText: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.termsAndConditionsTextEN ?? '';
        } else {
          return this.model.termsAndConditionsText ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.termsAndConditionsTextEN = newVal;
        } else {
          this.model.termsAndConditionsText = newVal;
        }
      },
    },
    editorInit() {
      return {
        height: 300,
        menubar: false,
        plugins:
          'advlist autolink lists link image charmap print preview anchor formatpainter searchreplace visualblocks code fullscreen insertdatetime table paste textcolor',
        toolbar:
          'undo redo | formatselect | forecolor bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist',
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(screen) {
        this.model = {
          topText: screen.notificationTopText,
          topTextEN: screen.notificationTopTextEN,
          topTextColor: screen.notificationTopTextColor,
          topTextSize: screen.notificationTopTextSize,
          topTextBoldPercentage: screen.notificationTopTextBoldPercentage,
          isEmail: screen.isEmail,
          isSMS: screen.isSMS,
          displayKeyboardForEmail: screen.displayKeyboardForEmail,
          displayKeyboardForSMS: screen.displayKeyboardForSMS,
          isEmailRequired: screen.isEmailRequired,
          isSMSRequired: screen.isSMSRequired,
          hasCustomTermsAndConditions: screen.hasCustomTermsAndConditions,
          termsAndConditionsText: screen.termsAndConditionsText,
          termsAndConditionsTextEN: screen.termsAndConditionsTextEN
        };
      },
    },
    'model.isEmail'(val) {
      if (!val) {
        this.model.displayKeyboardForEmail = false;
        this.model.isEmailRequired = false;
      }
    },
    'model.isSMS'(val) {
      if (!val) {
        this.model.displayKeyboardForSMS = false;
        this.model.isSMSRequired = false;
      }
    },
  },
  methods: {
    onSubmit() {
      this.error = '';
      if (!this.model.isEmail && !this.model.isSMS)
        return (this.error = 'error');

      const screen = {
        ...this.value,
        notificationTopText: this.model.topText,
        notificationTopTextEN: this.model.topTextEN,
        notificationTopTextColor: this.model.topTextColor,
        notificationTopTextSize: this.model.topTextSize,
        notificationTopTextBoldPercentage: this.model.topTextBoldPercentage,
        isEmail: this.model.isEmail,
        isSMS: this.model.isSMS,
        isEmailRequired: this.model.isEmailRequired,
        isSMSRequired: this.model.isSMSRequired,
        displayKeyboardForEmail: this.model.displayKeyboardForEmail,
        displayKeyboardForSMS: this.model.displayKeyboardForSMS,
        hasCustomTermsAndConditions: this.model.hasCustomTermsAndConditions,
        termsAndConditionsText: this.model.termsAndConditionsText,
        termsAndConditionsTextEN: this.model.termsAndConditionsTextEN
      };

      _submitScreenType(screen)
        .then(() => {
          this.$emit('input', screen);
        })
        .catch(error => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
