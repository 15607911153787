<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col>
        <b-form-group>
          <div :key="language" class="action-button">
            <calendar-navigate-selector
              :first-date="startDate"
              :language="language"
              :last-date="endDate"
              :view-type="viewType"
              @on-navigate="onNavigateHandler"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col v-if="showCalendarViewSelector" xl="2">
        <b-form-group>
          <div>
            <calendar-view-selector
              :key="language"
              :language="language"
              :view-type="viewType"
              @on-select-view="onSelectView"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <available-spaces-calendar-event
          v-if="areAvailableSpaceEventsVisible"
          :key="language"
          :entry-date="currentDate"
          :event-list="eventList"
          :item-click="itemClick"
          :items="items"
          :language="language"
          :month-items="monthItems"
          :view-type="viewType"
          @change-current-day="onChangeCurrentDay"
          @on-viewtype-changed="onChangeViewType"
          @refreshItems="refreshCalendarItems()"
        />

        <appointment-calendar-event
          v-if="areRegisteredAppointmentEventsVisible"
          :key="language"
          :entry-date="currentDate"
          :event-list="eventList"
          :item-click="itemClick"
          :items="items"
          :language="language"
          :month-items="monthItems"
          :view-type="viewType"
          :show-event-item-count="showEventItemCount"
          :time-click="timeClick"
          @change-current-day="onChangeCurrentDay"
          @on-viewtype-changed="onChangeViewType"
          @refreshItems="refreshCalendarItems()"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  VIEW_TYPE_CODE,
  VIEW_ORIENTATION,
} from '@/components/full-calendar/constants';
import _ from 'lodash';
import CalendarNavigateSelector from '@/components/full-calendar/CalendarNavigateSelector';
import CalendarViewSelector from '@/components/full-calendar/CalendarViewSelector';

import AvailableSpacesCalendarEvent from '@/components/full-calendar/AvailableSpacesCalendarEvent';
import AppointmentCalendarEvent from '@/components/full-calendar/AppointmentCalendarEvent';

import CalendarTypes from '@/constants/CalendarTypes';

export default {
  name: 'FullCalendar',
  components: {
    CalendarViewSelector,
    CalendarNavigateSelector,
    AvailableSpacesCalendarEvent,
    AppointmentCalendarEvent,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    firstDate: {
      type: [String, Date],
      default: function () {
        return new Date();
      },
      required: true,
    },
    lastDate: {
      type: [String, Date],
      default: function () {
        return new Date();
      },
      required: true,
    },
    monthItems: {
      type: Array,
      default: () => [],
    },
    itemClick: {
      type: Function,
      default: () => {},
    },
    timeClick: {
      type: Function,
      default: null,
    },
    type: {
      type: Number,
      default: () => CalendarTypes.AvailableSpaces,
    },
    showCalendarViewSelector: {
      type: Boolean,
      default: () => true,
    },
    showEventItemCount: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      viewType: VIEW_TYPE_CODE.WEEK,
      eventList: [],
      currentDate: this.$moment().toDate(),
      startDate:
        typeof this.firstDate === 'string'
          ? this.$moment(this.firstDate)
          : this.firstDate,
      endDate:
        typeof this.lastDate === 'string'
          ? this.$moment(this.lastDate)
          : this.lastDate,
    };
  },
  computed: {
    language: function () {
      return this.$i18n.locale;
    },
    areAvailableSpaceEventsVisible() {
      return this.type == CalendarTypes.AvailableSpaces;
    },
    areRegisteredAppointmentEventsVisible() {
      return this.type == CalendarTypes.RegisteredAppointments;
    },
  },
  methods: {
    getCurrentDate() {
      return this.$moment().startOf('month').toDate();
    },
    refreshCalendarItems() {
      this.updateCalendarRangeEvent();
    },
    onSelectView(viewType) {
      if (viewType) {
        this.viewType = viewType;

        switch (this.viewType) {
          case VIEW_TYPE_CODE.DAY:
            this.updateCurrentStartDateEndDate('day');
            break;
          case VIEW_TYPE_CODE.WEEK:
            this.updateCurrentStartDateEndDate('isoWeek');
            break;
          case VIEW_TYPE_CODE.MONTH:
            this.updateCurrentStartDateEndDate('month');
            break;
          default:
            this.updateCurrentStartDateEndDate('day');
        }

        this.updateCalendarRangeEvent();
      }
    },
    onSelectFilter() {
      this.currentDate = this.$moment().startOf('month').toDate();
    },
    onNavigateHandler({ orientation, viewType }) {
      if (orientation === VIEW_ORIENTATION.BACK) {
        switch (viewType) {
          case VIEW_TYPE_CODE.DAY:
            this.currentDate = this.$moment(this.currentDate)
              .startOf('day')
              .add(-1, 'days')
              .toDate();

            this.updateCurrentStartDateEndDate('day');
            break;
          case VIEW_TYPE_CODE.WEEK:
            this.currentDate = this.$moment(this.currentDate)
              .add(-7, 'days')
              .toDate();

            this.updateCurrentStartDateEndDate('isoWeek');
            break;
          case VIEW_TYPE_CODE.MONTH:
            this.currentDate = this.$moment(this.currentDate)
              .add(-1, 'M')
              .startOf('month')
              .toDate();

            this.updateCurrentStartDateEndDate('month');
            break;
          default:
            break;
        }

        this.updateCalendarRangeEvent();
      } else if (orientation === VIEW_ORIENTATION.FORWARD) {
        switch (viewType) {
          case VIEW_TYPE_CODE.DAY:
            this.currentDate = this.$moment(this.currentDate)
              .startOf('day')
              .add(1, 'days')
              .toDate();

            this.updateCurrentStartDateEndDate('day');
            break;
          case VIEW_TYPE_CODE.WEEK:
            this.currentDate = this.$moment(this.currentDate)
              .add(7, 'days')
              .toDate();

            this.updateCurrentStartDateEndDate('isoWeek');
            break;
          case VIEW_TYPE_CODE.MONTH:
            this.currentDate = this.$moment(this.currentDate)
              .add(1, 'M')
              .startOf('month')
              .toDate();

            this.updateCurrentStartDateEndDate('month');
            break;
          default:
            break;
        }

        this.updateCalendarRangeEvent();
      }
    },
    getCurrentPeriod() {
      return _.capitalize(this.$moment(this.currentDate).format('MMMM YYYY'));
    },
    onChangeCurrentDay(changedDay) {
      if (changedDay) {
        this.viewType = VIEW_TYPE_CODE.DAY;
        this.currentDate = changedDay;
      }
    },
    onChangeViewType(viewTypeParam) {
      if (viewTypeParam) {
        this.viewType = viewTypeParam.viewType;

        if (this.viewType == VIEW_TYPE_CODE.WEEK) {
          this.currentDate = new Date(viewTypeParam.date);

          this.startDate = this.$moment(this.currentDate)
            .startOf('isoWeek')
            .toDate();
          this.endDate = this.$moment(this.currentDate)
            .endOf('isoWeek')
            .toDate();

          this.updateCalendarRangeEvent();
        }
      }
    },

    updateCalendarRangeEvent() {
      this.$emit('on-start-end-date-changed', {
        startDate: this.startDate,
        endDate: this.endDate,
        viewType: this.viewType,
      });
    },
    updateCurrentStartDateEndDate(unitOfTime) {
      if (unitOfTime == 'day') {
        const startDateBase = this.$moment(this.currentDate).startOf('day');

        this.startDate = startDateBase.toDate();
        this.endDate = startDateBase.add(1, 'days').seconds(-1).toDate();
      } else {
        this.startDate = this.$moment(this.currentDate)
          .startOf(unitOfTime)
          .toDate();
        this.endDate = this.$moment(this.currentDate)
          .endOf(unitOfTime)
          .toDate();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.action-button {
  display: flex;
  justify-content: flex-start;
}

.current-period {
  color: $color-font-primary;
  font-weight: bold;
  font-size: 1.5rem;
}

/deep/ .custom-dropdown {
  .btn-dropdown-actions {
    width: 9rem;
    height: 2.3rem;
    span {
      margin-right: 18px;
    }
  }
}

.calendar-container__body {
  height: auto;
}
</style>
