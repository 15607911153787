<template>
  <b-row>
    <b-col
      lg="3"
      md="6"
    >
      <filter-select
        v-model="value.paternalLastNameAsk"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('requestPaternalLastName')"
        :options="options"
        translate
      />
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <filter-select
        v-model="value.paternalLastNameRequired"
        :clearable="false"
        :disabled="!value.paternalLastNameAsk"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('paternalLastNameRequired')"
        :options="options"
        translate
      />
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <filter-select
        v-model="value.maternalLastNameAsk"
        :clearable="false"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('requestMaternalLastName')"
        :options="options"
        translate
      />
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <filter-select
        v-model="value.maternalLastNameRequired"
        :clearable="false"
        :disabled="!value.maternalLastNameAsk"
        fieldtext="text"
        fieldvalue="value"
        :label="$t('maternalLastNameRequired')"
        :options="options"
        translate
      />
    </b-col>


    <b-col
      lg="3"
      md="6"
    >
      <filter-select
        v-model="value.redirectScreenId"
        fieldtext="name"
        fieldvalue="kioskScreenId"
        :label="$t('sendTo')"
        :options="screens"
      />
    </b-col>
  </b-row>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
export default {
  name: 'ScreenClient',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: ConfirmationOptions,
    };
  },
  computed: {
    screens() {
      return this.kiosk.screens.filter(
        (_, index) => index != this.screenNumber - 1,
      );
    },
  },
  watch: {
    'value.paternalLastNameAsk'(val){
      if (!val) this.value.paternalLastNameRequired = false;
    },
    'value.maternalLastNameAsk'(val){
      if (!val) this.value.maternalLastNameRequired = false;
    }
  }
};
</script>
