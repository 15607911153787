<template>
  <div class="card pt-2 border-light search-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('errorLevel') }}: </label>
            <span>{{ value.errorLevel }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('company') }}: </label>
            <span>{{ value.companyName }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('userName') }}: </label>
            <span>{{ value.user ? `${value.userName} (${value.user})` : '-'}}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('displayName') }}: </label>
            <span>{{ value.displayId ? `${value.displayName} (${value.displayId})` : '-' }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('errorDate') }}: </label>
            <span>{{
              $moment(value.timeStamp).format('MM/DD/yyyy hh:mm:ss A')
            }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('pwaName') }}: </label>
            <span>{{ value.pwaClientId ? `${value.pwaClientName} (${value.pwaClientId})` : '-' }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('errorMessage') }}: </label>
            <span>{{ value.errorMessage }}</span>
          </p>
        </div>
        <div class="col col-sm-6 mt-1">
          <p>
            <label>{{ $t('kioskName') }}: </label>
            <span>{{ value.kioskId ? `${value.kioskName} (${value.kioskId})` : '-' }}</span>
          </p>
        </div>
        <div class="col col-sm-12 mt-1">
          <p>
            <label>{{ $t('internalErrorException') }}: </label>
            <span>{{ value.exceptionData || '-' }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorLogSearchResult',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.search-result p {
  margin-bottom: 0.2rem;
}

.search-result p i {
  cursor: pointer;
  font-size: 18px;
}

.search-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.search-result p i,
.search-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
