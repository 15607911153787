<template>
  <div class="p-3 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">

        <div class="cell">
          <label>{{ $t('description') }}: </label>
          <span>{{ value.description }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('domain') }}: </label>
          <span>{{ value.key }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('subcode') }}: </label>
          <span>{{ value.alternativeKey || '-' }}</span>
        </div>

      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <div class="cell">
          <label>{{ $t('legacyPath') }}: </label>
          <span>{{ value.path || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('path') }}: </label>
          <span>{{ value.storagePath || '-' }}</span>
        </div>
        <div class="cell">
          <b-form-checkbox
              v-model="value.active"
              class="chk-available"
              :disabled="true"
              switch
            >
              {{ $t('available') }}
            </b-form-checkbox>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'LogoSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit', this.value),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        }
      ],
    };
  }
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>

