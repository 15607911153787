<template>
  <div>
    <h1>Auto Registro</h1>
  </div>
</template>
<script>
export default {
  name: 'AutoRegister'
};
</script>
