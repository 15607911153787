<template>
  <div>
    <h1>Web User (Ciudadano)</h1>
  </div>
</template>
<script>
export default {
  name: 'WebUser'
};
</script>
