<template>
  <b-container id="appointments-container" fluid>
    <div class="main-content">
      <b-form>
        <input
          class="form-control"
          name="clientAppointmentId"
          type="hidden"
          :value="space.clientAppointmentId"
        />
        <div id="generalInfo">
          <b-row>
            <b-col cols="12">
              <div
                class="
                  caption
                  mb-4
                  text-center
                  main-content-title
                  text-uppercase
                "
              >
                {{ $t('generalInformation') }}
              </div>
            </b-col>
          </b-row>
          <b-row v-if="space.specialist">
            <b-col cols="12" md="4">
              <b-form-group :label="$t('specialist') + ':'">
                <select
                  id="specialists"
                  class="form-control"
                  disabled="disabled"
                  name="specialists"
                  readonly="readonly"
                >
                  <option :value="space.specialist.value">
                    {{ space.specialist.text }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group :label="$t('company') + ':'">
                <select
                  id="companies"
                  class="form-control"
                  disabled="disabled"
                  name="companies"
                  readonly="readonly"
                >
                  <option :value="space.company.value">
                    {{ space.company.text }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group :label="$t('services') + ':'">
                <select
                  id="services"
                  class="form-control"
                  disabled="disabled"
                  name="services"
                  readonly="readonly"
                >
                  <option :value="space.service.value">
                    {{ space.service.text }}
                  </option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="space.duration">
            <b-col cols="12" lg="4" sm="3">
              <b-form-group :label="$t('durationHoursAndMinutes') + ':'">
                <base-numeric-input
                  class="duration"
                  icon-class="clock"
                  :min="10"
                  :step="10"
                  :value="space.duration"
                  @on-selected-value="onSelectedDuration($event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4" sm="3">
              <b-form-group :label="$t('startDate') + ':'">
                <base-datetime-picker
                  class="theme-orange"
                  :disabled="true"
                  format="dd/MMM/yyyy"
                  :is-read-only="true"
                  :min-datetime="today"
                  type="date"
                  :value="space.date"
                  @change="onSelectedDateEvent($event)"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('fromHour') + ':'">
                <base-datetime-picker
                  class="theme-orange"
                  icon-class="clock"
                  is-readonly="true"
                  type="time"
                  :value="space.startHour"
                  @change="onSelectedStartHourEvent($event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('toHour') + ':'">
                <base-datetime-picker
                  class="theme-orange"
                  icon-class="clock"
                  is-readonly="true"
                  type="time"
                  :value="space.endHour"
                  @change="onSelectedEndHourEvent($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div id="submit">
          <b-row>
            <b-col cols="12">
              <div class="py-3 pull-right">
                <slot name="buttoms" />
                <button
                  class="btn btn-primary mr-2"
                  type="button"
                  @click="updateSpace()"
                >
                  <i class="fal fa-save" /> {{ $t('save') }}
                </button>
                <button
                  class="btn btn-danger mr-2"
                  type="button"
                  @click="showDeleteSpaceConfirmation()"
                >
                  <i class="fal fa-minus-circle" /> {{ $t('delete') }}
                </button>
                <button
                  class="btn btn-default"
                  type="button"
                  @click="cancelManager()"
                >
                  <i class="fal fa-times-circle" /> {{ $t('cancel') }}
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
    <div>
      <base-alert
        v-model="showDeleteSpaceModal"
        :message="$t('areyousuredeletespace')"
        :title="$t('deleteavailablespace') | uppercase"
        @on-confirm="manageSpaceDeletionConfirmation($event)"
      />
      <base-alert
        v-model="messageModal.active"
        :message="messageModal.message"
        :show-cancel-button="false"
        :title="messageModal.title"
        @on-confirm="onMessageModalEvent($event)"
      >
        <slot name="confirm" />
      </base-alert>
    </div>
  </b-container>
</template>

<script>
import '@livelybone/vue-datepicker/lib/css/index.css';
import AppointmentService from '@/services/AppointmentService.js';
import BaseAlert from '@/components/BaseAlert-old.vue';
import _formatter from 'moment';

export default {
  components: {
    BaseAlert,
  },
  props: {
    selectedSpace: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
  data() {
    return {
      caption: 'Información General',
      sectionSpecialistTitle: 'Seleccionar especialista',
      today: _formatter().format(),
      filters: {
        companies: [],
        locations: [],
        services: [],
      },
      specialists: {
        selected: [],
        availables: [],
      },
      frequencies: [
        { text: 'Diario', value: '1' },
        { text: 'Semanal', value: '2' },
        { text: 'Mensual', value: '3' },
        { text: 'Anual', value: '4' },
      ],
      companyIdSelected: 0,
      locationIdSelected: 0,
      serviceIdSelected: [],
      specialistSelected: [],
      weekDays: [
        { text: 'domingo', value: '7' },
        {
          text: 'lunes',
          value: '1',
        },
        { text: 'martes', value: '2' },
        { text: 'miércoles', value: '3' },
        { text: 'jueves', value: '4' },
        { text: 'viernes', value: '5' },
        { text: 'sábado', value: '6' },
      ],
      space: {},
      showDeleteSpaceModal: false,
      messageModal: {
        active: false,
        title: 'Advertencia',
        message: '',
      },
    };
  },
  mounted() {
    this.space = this.selectedSpace;
    this.updateDurationEvent();
  },
  watch: {
    selectedSpace(val) {
      this.space = val;
    },
  },
  methods: {
    goToVerifyInformation() {
      //   this.$emit('on-show-verify-space');
    },
    cancelManager() {
      this.$emit('on-cancel-space-manager');
    },
    getDurationTime() {
      var hours = Math.floor(this.space.duration / 60) + 12;
      var minutes = this.space.duration % 60;

      var d = new Date();
      d.setUTCHours(hours);
      d.setUTCMinutes(minutes);
      return d.toISOString();
    },
    showDeleteSpaceConfirmation() {
      this.showDeleteSpaceModal = true;
    },
    deleteSpace() {
      AppointmentService.deleteSpace(this.space.clientAppointmentId).then(
        () => {
          this.showDeleteSpaceModal = false;
          this.cancelManager();
        },
      );
    },
    cancelSpaceDeletion() {
      this.showDeleteSpaceModal = false;
    },
    manageSpaceDeletionConfirmation(val) {
      if (val == true) {
        this.deleteSpace();
      } else {
        this.cancelSpaceDeletion();
      }
    },
    onMessageModalEvent() {
      this.messageModal.active = false;
    },
    onSelectedDuration(duration) {
      this.space.duration = duration;
      this.updateEndHourEvent();
    },
    onSelectedStartHourEvent(startHour) {
      this.space.startHour = _formatter(startHour).format();
      this.updateEndHourEvent();
    },
    onSelectedDateEvent(date) {
      this.space.date = _formatter(date).format();
    },
    onSelectedEndHourEvent(endHour) {
      this.space.endHour = _formatter(endHour).format();
      this.updateDurationEvent();
    },
    updateEndHourEvent() {
      this.space.endHour = _formatter(this.space.startHour)
        .add(this.space.duration, 'minute')
        .format();
      this.updateDurationEvent();
    },
    updateDurationEvent() {
      var start = _formatter(this.selectedSpace.startHour, _formatter.ISO_8601);
      var end = _formatter(this.selectedSpace.endHour, _formatter.ISO_8601);

      var duration = _formatter.duration(end.diff(start));
      this.space.duration = duration.asMinutes();
    },
    updateSpace() {
      AppointmentService.updateSpace(
        this.space.clientAppointmentId,
        this.space.date,
        this.space.startHour,
        this.space.duration,
      )
        .then(() => {
          this.showDeleteSpaceModal = false;
          this.cancelManager();
        })
        .catch((response) => {
          this.messageModal.message = response.data.message;
          this.messageModal.active = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  font-size: 14px;
  font: normal normal medium 14px/29px Lato;
}
.strong {
  font-weight: bold !important;
}
.main-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 20px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.title-description {
  margin-top: 18px;
  margin-bottom: 18px;
}
.filters label {
  font: normal normal normal 14px/21px Lato;
  letter-spacing: 0px;
  color: $color-font-secondary;
  opacity: 1;
}

.label-default {
  background-color: $color-label-default;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: $color-white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.label-round {
  border-radius: 1.25em;
  padding-right: 5x;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  width: 60%;
}

[data-role='remove'] {
  margin-left: 8px;
  cursor: pointer;
}

[data-role='remove']:after {
  content: 'x';
  padding: 0px 2px;
}

.pull-right {
  float: right;
}

.color-black {
  color: $color-font-primary !important;
}

.fs-18 {
  font-size: 18px !important;
}

.form-control {
  border: 1px solid $color-label-default;
}

.left-divider {
  border-left: 1px solid $color-label-default;
  opacity: 1;
}

.duration {
  width: 100%;
}

.col-form-label {
  font: normal normal normal 14px/21px Lato !important;
  color: $color-font-secondary !important;
}

label {
  font: normal normal normal 14px/21px Lato;
  color: #1c1c1d !important;
}

.modal-header,
.modal-footer {
  display: none !important;
}

.modal-confirmation-label {
  font-size: 16px;
  font-weight: bold;
}
</style>
<style  lang="scss">
.datepicker .input-wrapper,
.timepicker .input-wrapper {
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $color-font-secondary;
  background-color: $color-white;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.75rem + 2px);
  overflow-x: hidden;
}

.datepicker .input-wrapper .suffix,
.timepicker .input-wrapper .suffix {
  display: none;
}
.datepicker .input-wrapper .prefix,
.timepicker .input-wrapper .prefix {
  color: $color-primary;
  margin-right: 6px;
}

.datepicker .input-wrapper .prefix svg,
.timepicker .input-wrapper .prefix svg {
  width: 18px;
  height: 20px;
}

.datepicker .input-wrapper input,
.timepicker .input-wrapper input {
  outline: 0;
  border: none;
  outline-style: none;
  -moz-outline-style: none;
  font-family: lato, regular;
}
.cus-radiobutton-container > label {
  top: 5px;
}
.weekdays-container .cus-checkbox-container > label {
  top: 5px;
}
</style>
