<template>
  <div class="calendar-view-selector">
    <select
      id="calendar-view-selector"
      class="form-control icon-input"
      name="calendar-view-selector"
      @change="SelectedCalendarView($event)"
    >
      <option
        v-for="(item, index) in getOptions"
        :key="'calendarViewSelector' + index"
        :selected="item.id === viewType"
        :value="item.id"
      >
        {{ $t('select') }} {{ $t(item.name) }}
      </option>
    </select>
    <b-icon
      class="custom-form-input-icon"
      icon="calendar3"
    />
  </div>
</template>

<script>
export default {
  name: 'CalendarViewSelector',
  computed: {
    evalViewType () {
      return (id) => id === this.viewType;
    },
    getOptions () {
      return [
        { id: 1, name: 'month' },
        { id: 2, name: 'week' },
        { id: 3, name: 'day' }
      ];
    }
  },
  methods: {
    SelectedCalendarView (event) {
      var selectedview = parseInt(event.target.value);
      this.$emit('on-select-view', selectedview);
    }
  },
  props: {
    viewType: {
      type: Number,
      default: () => 2,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.calendar-view-selector {
  display: flex;
  cursor: pointer;
  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f5b60;
    width: 105px;
    height: 38px;
    span {
      user-select: none;
    }
    &.active {
      border-radius: 8px;
      background: #ff7058;
      color: $color-white;
    }
  }
}
.icon-input {
  padding-left: 36px !important;
}
</style>
