<template>
  <div id="dashboard" class="container-md">
    <div class="row justify-content-between p-1 pt-5 pb-2">
      <div class="col-sm-12 col-md-8">
        <h1 class="header-title title">
          {{ $t('welcome') }}{{ welcomeMessage }}
        </h1>
      </div>
      <div class="col-sm-12 col-md-3">
        <h4>{{ dateMessage }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.turnsQuantity"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggTurnsQuantity"
            :center-data-text="$t('notAttended')"
            :center-data-value="
              gauges.turnsQuantity.metrics.notAttendedClientsTotal
            "
            :left-data-text="$t('attendeds')"
            :left-data-value="gauges.turnsQuantity.metrics.attendedClientsTotal"
            :right-data-text="$t('total')"
            :right-data-value="gauges.turnsQuantity.metrics.turnClientsTotal"
            :title="$t('numberOfTurns')"
            :value="gauges.turnsQuantity.metrics.valuePercentage"
            :valueText="gauges.turnsQuantity.metrics.valueText"
          />
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.totalTransactions"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggTotalTransactions"
            :center-data-text="$t('notAttendedF')"
            :center-data-value="
              gauges.totalTransactions.metrics.notAttendedTurnsTotal
            "
            :left-data-text="$t('attendedsF')"
            :left-data-value="
              gauges.totalTransactions.metrics.attendedTurnsTotal
            "
            :right-data-text="$t('total')"
            :right-data-value="
              gauges.totalTransactions.metrics.turnsAbsoluteTotal
            "
            :title="$t('totalTransactions')"
            :value="gauges.totalTransactions.metrics.valuePercentage"
            :valueText="gauges.totalTransactions.metrics.valueText"
          />
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.averageWaitTime"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggAverageWaitTime"
            :center-data-text="`${$t('targetAverageTime')}: ${
              gauges.averageWaitTime.metrics.targetAverageWaitingTime
            }`"
            :showCenterDataValue="false"
            :left-data-value="
              gauges.averageWaitTime.metrics.minAverageWaitingTime
            "
            :right-data-value="
              gauges.averageWaitTime.metrics.maxAverageWaitingTime
            "
            :title="$t('averageWaitingTimeGauge')"
            :value="gauges.averageWaitTime.metrics.valuePercentage"
            :valueText="gauges.averageWaitTime.metrics.valueText"
            :color="gauges.averageWaitTime.color"
          />
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.turnsQuantityPhone"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggTurnsQuantityPhone"
            :showCenterDataValue="false"
            :left-data-text="$t('totalCellPhones')"
            :left-data-value="
              gauges.turnsQuantityPhone.metrics.turnsQuantityPhone
            "
            :right-data-text="$t('totalClientsDashboard')"
            :right-data-value="
              gauges.turnsQuantityPhone.metrics.turnClientsTotal
            "
            :title="$t('cellPhones')"
            :value="gauges.turnsQuantityPhone.metrics.valuePercentage"
            :valueText="gauges.turnsQuantityPhone.metrics.valueText"
          />
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.turnsQuantityEmail"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggTurnsQuantityEmail"
            :showCenterDataValue="false"
            :left-data-text="$t('totalEmails')"
            :left-data-value="
              gauges.turnsQuantityEmail.metrics.turnsQuantityEmail
            "
            :right-data-text="$t('totalClientsDashboard')"
            :right-data-value="
              gauges.turnsQuantityEmail.metrics.turnClientsTotal
            "
            :title="$t('emails')"
            :value="gauges.turnsQuantityEmail.metrics.valuePercentage"
            :valueText="gauges.turnsQuantityEmail.metrics.valueText"
          />
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pt-3 gauge-container">
        <loading
          :active="loaders.averageServiceTime"
          color="#F37E00"
          :is-full-page="false"
        />
        <div class="gauge-panel">
          <percentage-gauge
            id="ggAverageServiceTime"
            :center-data-text="`${$t('targetAverageTime')}: ${
              gauges.averageServiceTime.metrics.targetAverageServiceTime
            }`"
            :showCenterDataValue="false"
            :left-data-value="
              gauges.averageServiceTime.metrics.minAverageServiceTime
            "
            :right-data-value="
              gauges.averageServiceTime.metrics.maxAverageServiceTime
            "
            :title="$t('averageServiceTimeGauge')"
            :value="gauges.averageServiceTime.metrics.valuePercentage"
            :valueText="gauges.averageServiceTime.metrics.valueText"
            :color="gauges.averageServiceTime.color"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modulesName } from '@/store';
import { mapState, mapGetters } from 'vuex';
import PercentageGauge from '@/components/PercentageGauge';
import {
  getTurnsQuantity as _getTurnsQuantity,
  getTotalTransactions as _getTotalTransactions,
  getAverageWaitTime as _getAverageWaitTime,
  getAverageServiceTime as _getAverageServiceTime,
  getTurnsQuantityPhone as _getTurnsQuantityPhone,
  getTurnsQuantityEmail as _getTurnsQuantityEmail,
} from '@/services/ReportService';

export default {
  name: 'Dashboard',
  components: {
    PercentageGauge,
  },
  computed: {
    ...mapState(modulesName.userModuleName, [
      'profile',
      'currentActiveLocation',
    ]),
    ...mapGetters(modulesName.languageModuleName, ['getLanguage']),
    welcomeMessage() {
      const firstName = `${this.profile.firstName ?? ''}`;
      const lastPaternalName = `${this.profile.lastPaternalName ?? ''}`;
      const lastMaternalName = `${this.profile.lastMaternalName ?? ''}`;

      let message = `, ${firstName} ${lastPaternalName} ${lastMaternalName}`;
      return message;
    },
    dateMessage() {
      var locale = this.getLanguage;

      var formatES = 'dddd, D [de] MMMM [de] Y';
      var formatEN = 'dddd, MMMM D Y';
      var format = locale == 'en' ? formatEN : formatES;

      return this.$moment(new Date()).locale(locale).format(format);
    },
  },
  data() {
    return {
      loaders: {
        turnsQuantity: false,
        totalTransactions: false,
        turnsQuantityPhone: false,
        turnsQuantityEmail: false,
        averageWaitTime: false,
        averageServiceTime: false,
      },
      gauges: {
        turnsQuantity: {
          metrics: {
            notAttendedClientsTotal: 0,
            attendedClientsTotal: 0,
            turnClientsTotal: 0,
            valueText: '',
            valuePercentage: 0,
          },
        },
        totalTransactions: {
          metrics: {
            notAttendedTurnsTotal: 0,
            attendedTurnsTotal: 0,
            turnsAbsoluteTotal: 0,
            valueText: '',
            valuePercentage: 0,
          },
        },
        turnsQuantityPhone: {
          metrics: {
            turnsQuantityPhone: 0,
            turnClientsTotal: 0,
            valueText: '',
            valuePercentage: 0,
          },
        },
        turnsQuantityEmail: {
          metrics: {
            turnsQuantityEmail: 0,
            turnClientsTotal: 0,
            valueText: '',
            valuePercentage: 0,
          },
        },
        averageWaitTime: {
          metrics: {
            maxAverageWaitingTime: 0,
            minAverageWaitTime: 0,
            targetAverageWaitingTime: 0,
            valueText: '',
            valuePercentage: 0,
          },
          color: '#198E0F',
        },
        averageServiceTime: {
          metrics: {
            maxAverageServiceTime: 0,
            minAverageServiceTime: 0,
            targetAverageServiceTime: 0,
            valueText: '',
            valuePercentage: 0,
          },
          color: '#198E0F',
        },
      },
    };
  },
  methods: {
    async getTurnsQuantity() {
      if (!this.currentActiveLocation) {
        return;
      }
      this.loaders.turnsQuantity = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getTurnsQuantity(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.turnsQuantity.metrics.notAttendedClientsTotal =
            data.centerDataValue;
          this.gauges.turnsQuantity.metrics.attendedClientsTotal =
            data.leftDataValue;
          this.gauges.turnsQuantity.metrics.turnClientsTotal =
            data.rightDataValue;
          this.gauges.turnsQuantity.metrics.valueText = data.valueText;
          this.gauges.turnsQuantity.metrics.valuePercentage =
            data.valuePercentage;
        })
        .finally(() => {
          this.loaders.turnsQuantity = false;
        });
    },
    async getTotalTransactions() {
      if (!this.currentActiveLocation) {
        return;
      }
      this.loaders.totalTransactions = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getTotalTransactions(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.totalTransactions.metrics.notAttendedTurnsTotal =
            data.centerDataValue;
          this.gauges.totalTransactions.metrics.attendedTurnsTotal =
            data.leftDataValue;
          this.gauges.totalTransactions.metrics.turnsAbsoluteTotal =
            data.rightDataValue;
          this.gauges.totalTransactions.metrics.valueText = data.valueText;
          this.gauges.totalTransactions.metrics.valuePercentage =
            data.valuePercentage;
        })
        .finally(() => {
          this.loaders.totalTransactions = false;
        });
    },
    async getAverageWaitTime() {
      if (!this.currentActiveLocation) {
        return;
      }
      this.loaders.averageWaitTime = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getAverageWaitTime(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.averageWaitTime.metrics.targetAverageWaitingTime =
            data.centerDataValue;
          this.gauges.averageWaitTime.metrics.minAverageWaitTime =
            data.leftDataValue;
          this.gauges.averageWaitTime.metrics.maxAverageWaitingTime =
            data.rightDataValue;
          this.gauges.averageWaitTime.metrics.valueText = data.valueText;
          this.gauges.averageWaitTime.metrics.valuePercentage =
            data.valuePercentage;

          this.gauges.averageWaitTime.color =
            data.valuePercentage > 50 ? '#FA2A2A' : '#198E0F';
        })
        .finally(() => {
          this.loaders.averageWaitTime = false;
        });
    },
    async getTurnsQuantityPhone() {
      if (!this.currentActiveLocation) {
        return;
      }

      this.loaders.turnsQuantityPhone = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getTurnsQuantityPhone(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.turnsQuantityPhone.metrics.turnsQuantityPhone =
            data.leftDataValue;
          this.gauges.turnsQuantityPhone.metrics.turnClientsTotal =
            data.rightDataValue;
          this.gauges.turnsQuantityPhone.metrics.valueText = data.valueText;
          this.gauges.turnsQuantityPhone.metrics.valuePercentage =
            data.valuePercentage;
        })
        .finally(() => {
          this.loaders.turnsQuantityPhone = false;
        });
    },
    async getTurnsQuantityEmail() {
      if (!this.currentActiveLocation) {
        return;
      }

      this.loaders.turnsQuantityEmail = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getTurnsQuantityEmail(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.turnsQuantityEmail.metrics.turnsQuantityEmail =
            data.leftDataValue;
          this.gauges.turnsQuantityEmail.metrics.turnClientsTotal =
            data.rightDataValue;
          this.gauges.turnsQuantityEmail.metrics.valueText = data.valueText;
          this.gauges.turnsQuantityEmail.metrics.valuePercentage =
            data.valuePercentage;
        })
        .finally(() => {
          this.loaders.turnsQuantityEmail = false;
        });
    },
    async getAverageServiceTime() {
      if (!this.currentActiveLocation) {
        return;
      }

      this.loaders.averageServiceTime = true;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
      };

      await _getAverageServiceTime(payload)
        .then((response) => {
          const data = response.data;

          this.gauges.averageServiceTime.metrics.targetAverageServiceTime =
            data.centerDataValue;
          this.gauges.averageServiceTime.metrics.minAverageServiceTime =
            data.leftDataValue;
          this.gauges.averageServiceTime.metrics.maxAverageServiceTime =
            data.rightDataValue;
          this.gauges.averageServiceTime.metrics.valueText = data.valueText;
          this.gauges.averageServiceTime.metrics.valuePercentage =
            data.valuePercentage;

          this.gauges.averageServiceTime.color =
            data.valuePercentage > 50 ? '#FA2A2A' : '#198E0F';
        })
        .finally(() => {
          this.loaders.averageServiceTime = false;
        });
    },
    async getMetrics() {
      this.getTurnsQuantity();
      this.getTotalTransactions();
      this.getTurnsQuantityPhone();
      this.getTurnsQuantityEmail();
      this.getAverageWaitTime();
      this.getAverageServiceTime();
    },
  },
  watch: {
    currentActiveLocation() {
      this.getMetrics();
    },
  },
  mounted() {
    this.getMetrics();
  },
};
</script>

<style lang="scss" scoped>
#turn-manager-gauges {
  width: 100%;
  margin-left: 0px;
}

#turn-manager-gauges .card {
  max-width: 197px;
  max-height: 123px;
}

.gauge-navigation {
  height: 100%;
  max-height: 293px;
  width: 59px;
  color: $color-primary;
  font-size: 32px;
}

.gauge-panel {
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  min-height: 293px;
}

.gauge-panel > div {
  margin-left: auto;
  margin-right: auto;
}

#dashboard h1.title {
  text-align: left;
  font-size: 25px;
  font-weight: bold;
}

#dashboard h4 {
  text-align: right;
  font-weight: bold;
  font-size: 15px;
}
</style>
