<template>
  <custom-modal
    :id="id"
    :ref="id"
    :class="id"
    :title="title || $t('previewView')"
  >
    <div class="back-container text-center">
      <div
        id="frmPreviewContainer"
        ref="frmPreviewContainer"
        class="d-flex flex-row mt-2"
      />
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import loading from '@/mixins/vue-loading';

export default {
  props: {
    id: {
      type: String,
      default: () => 'preview-modal-ref',
    },
    title: {
      type: String,
      default: null,
    },
  },
  mixins: [loading],
  components: {
    CustomModal,
  },
  methods: {
    show(url) {
      if (url) {
        this.$refs[this.id].show();

        setTimeout(() => {
          const frameContainer = document.getElementById('frmPreviewContainer');

          if (frameContainer != null) {
            // Clear the content
            frameContainer.innerHTML = '';

            // Create the frame
            const frameElement = document.createElement('iframe');

            frameElement.id = 'frmPreview';
            frameElement.className = 'frmPreview';
            frameElement.width = '97.5%';
            frameElement.height = '100%';
            frameElement.style = 'overflow-x:hidden;height:100%;width:97.5%;';
            frameElement.seamless = 'seamless';
            frameElement.src = url;

            // Append the new frame
            frameContainer.appendChild(frameElement);
          }
        }, 1000);
      }
    },
    hide() {
      const frameElement = document.getElementById('frmPreview');

      document.removeChild(frameElement);

      this.$refs[this.id].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
#preview-modal-ref > .modal-dialog .modal-content {
  min-width: 1280px;
  min-height: 846px;
  overflow: hidden;
}

#frmPreviewContainer {
  width: 100%;
  height: 92%;
  position: absolute;
  overflow: hidden;
}
</style>
