<template>
  <div class="row">
    <div class="col-12 col-md-12 col-sm-6">
      <div
        v-if="label"
        :class="[`text-${labelPosition}`]"
      >
        <label class="input-label">{{ label }}</label>
      </div>
      <label
        v-if="!canDownload && !disabled"
        class="form-control position-relative text-center mb-0 file-input-container"
        :class="{ 'was-Invalidated': error, 'input-disabled': disabled }"
      >
        <label class="file-select">
          <em class="fas fa-file-plus pr-4 text-green" />
          <span class="font-weight-bold">Presione aquí para anejar documento</span>
          <input
            ref="fileuploadRef"
            :accept="accept"
            :disabled="disabled"
            type="file"
            @change="handleFileChange"
          />
        </label>
      </label>
      <div
        v-if="canDownload"
        class="d-flex align-items-center w-100 justify-content-start has-document"
      >
        <div class="font-weight-normal">
          <label class="circle-green" />
          <label class="mb-0">{{ fileLabel }} </label>
        </div>
        <div
          class="d-flex flex-row-reverse ml-2"
          :class="{'button-container': hasShortStyle}"
        >
          <button
            class="btn outline-danger"
            :class="{ 'input-disabled': disabled }"
            :disabled="disabled"
            :hidden="disabled"
            type="button"
            @click="removeFile"
          >
            <i class="fas fa-times-circle" />
            <span
              v-if="!hasShortStyle"
              class="ml-1"
            >Eliminar</span>
          </button>
          <button
            v-if="!preventDownload && canDownload"
            class="btn outline-success mr-2 ml-2"
            type="button"
            @click="downloadFile"
          >
            <i class="fas fa-file-download" />
            <span
              v-if="!hasShortStyle"
              class="ml-1"
            >Descargar</span>
          </button>
        </div>
      </div>
      <error
        v-if="error"
        :message="customErrorMsg"
        variant="no-border"
      />

      <div
        v-if="isLoadingFile && !disabled"
        class="progress mt-2"
      >
        <div
          aria-valuemax="100"
          aria-valuemin="0"
          aria-valuenow="25"
          class="progress-bar progress-bar-striped bg-success text-center"
          role="progressbar"
          :style="{ width: currentLoad }"
        >
          {{ this.progressLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileInputStatus from "@/constants/FileInputStatus.js";
import Error from '@/components/basics/error/CustomError.vue';
// import alertMixin from "./alert.mixin.js"

export default {
  /** Componente personalizado de entrada de archivos */
  name: "CustomFileInput",
  components: {
    Error
  },
  mixins:[],
  props: {
    /** Id del Componente CustomFileInput */
    id: {
      type: String,
      default: null,
    },
    /** Control para mostrar texto */
    label: {
      type: String,
      default: "",
    },
    /** Control para posicionar texto */
    labelPosition: {
      type: String,
      default: "center"
    },
    /**Texto de referencia para el CustomFileInput */
    placeholder: {
      type: String,
      default: "",
    },
    /** V-model Parámetro de entrada del CustomFileInput */
    value: {
      type: File,
      default: null,
    },
    /**Estado del archivo adjunto ejemplo: Loading, Loader, Deleted*/
    attachmentStatus: {
      type: String,
      default: FileInputStatus.Empty,
    },
    /** Control para aceptar archivos */
    accept: {
      type: String,
      default: "*",
    },
    /** Estable selección múltiple */
    multiple: {
      type: Boolean,
      default: false,
    },
    /** Estado requerido */
    required: {
      type: String,
      default: function() {
        return "";
      },
    },
    /**Estado Error */
    error: {
      type: String,
      default: "",
    },
    /** Mensaje de error encontrado */
    customErrorMsg: {
      type: String,
      default: function() {
        return this.error;
      }
    },
    /** Deshabilita el control */
    disabled: {
      type: Boolean,
      default: false
    },
    /**Estable estilo de visualización */
    displayStyle: {
      type: String,
      default: "short",
    },
    /** Establece ID de archivo adjunto */
    attachmentId: {
      type: String,
      default: null,
    },
    /** Posibilita editar */
    isEdit: {
      type: Boolean,
      default: false,
    },
    /** Bloquea funcion de descargar */
    preventDownload: {
      type: Boolean,
      default: false,
    },
    /** Estable el nombre en el archivo */
    name: {
      type: String,
      default: "",
    },
    /** Establece un tamaño máximo */
    maxSize: {
      //Size in MB
      type: Number,
      default: 10,
    },
    onDownload:{
      required:true,
      type:Function,
      default:()=> new Promise((resolve, reject) => { reject(new Error("Debe implementar la función del API para descargar.")); })
    }
  },
  data() {
    return {
      currentLoad: "0%",
      status: this.attachmentStatus,
      fileReader: null,
      file: null,
      fileLabel: this.name,
      isDeleting: false,
      fileMaxSize: this.maxSize
    };
  },
  computed: {
    hasShortStyle() {
      return this.displayStyle === "normal";
    },
    isLoadingFile() {
      return this.status === FileInputStatus.Loading;
    },
    canDownload() {
      return (
        (this.attachmentId != null && this.status != FileInputStatus.Deleted) ||
        (this.status === FileInputStatus.Loaded &&
          this.fileReader !== null &&
          this.fileReader.result != null) ||
        this.isEdit
      );
    },
    progressLabel() {
      return this.status !== FileInputStatus.Loaded ? "Cargando" : "Completado";
    }
  },
  methods: {
    validateFile(file) {
      //check extension and size
      const fileSize = (file.size / 1048576).toFixed(2);
      const acceptedContentTypes = this.accept.split(',')
        .map(s=> s.replace(',','').toLowerCase())
        .filter(s => s)
      let validationResult = true

      //validate extension
      if(!acceptedContentTypes.includes(file.type)){
        this.ShowToast(
          "Error",
          `Favor de seleccionar un archivo de tipo ${acceptedContentTypes}.`,
          "error"
        );
        validationResult = false
      }

      //validate file size in mb
      if(fileSize>this.maxSize){
        this.ShowToast(
          "Error",
          `Favor de seleccionar el archivo de tamaño menor o igual a ${this.maxSize}MB.`,
          "error"
        );
        validationResult = false
      }

      return validationResult
    },
    handleFileChange: function(e) {
      if (e.target.files && e.target.files.length > 0) {

        //validate file
        if(!this.validateFile(e.target.files[0])){
          this.$refs.fileuploadRef.value = null
          return;
        }

        this.$emit("input", e.target.files[0]);
        this.loadFile();
      }
    },
    downloadFile() {
      if (this.canDownload) {
        if (this.attachmentId && !this.isDeleting) {
          // peiServices.downloadDocumentByAttachmentId(this.attachmentId)
          if ({}.toString.call(this.onDownload) === '[object Function]'){
            this.onDownload(this.attachmentId).catch(() => {
              this.ShowToast('', 'El documento no existe.', 'error');
              });
          }

        } else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let url = this.fileReader.result;
          a.href = url;
          a.download = this.file.name;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    },
    removeFile: function(e) {
      e?.preventDefault();
      this.$emit("input", null);
      this.$emit("after-remove", this.attachmentId);
      this.currentLoad = "0%";
      this.status = FileInputStatus.Deleted;
      this.fileReader = null;
      this.file = null;
      this.fileLabel = null;
      this.isDeleting = true;
    },
    onLoadProgress(event) {
      let loaded = event.loaded / event.total * 100;
      this.status = FileInputStatus.Loading;
      this.currentLoad = loaded + "%";
    },
    onLoad() {
      this.status = FileInputStatus.Loaded;
    },
    loadFile() {
      let file = this.$el.querySelector("input[type=file]").files[0]
      let reader = new FileReader();

      reader.onprogress = this.onLoadProgress;
      reader.onloadend = this.onLoad;
      reader.addEventListener("onprogress", this.onLoadProgress);

      if (file) {
        reader.readAsDataURL(file);
        this.fileReader = reader;
        this.file = file;
        this.fileLabel = file.name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-label {
  color: $color-font-secondary !important;
}
.file-select {
  text-align: center;
  margin-bottom: 0px !important;
}
button {
  color: #3f5b60;
  background-color: $color-white;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  height: 2.375rem;
  border: 1px solid #3f5b60;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

input[type="file"] {
  display: none;
}

.success {
  color: $color-white;
  background-color: $color-success;
  border-color: $color-success;
}

.outline-success {
  color: $color-success;
  background-color: $color-white;
  border-color: $color-success;
}

.danger {
  color: $color-white;
  background-color: $color-danger;
  border-color: $color-danger;
}

.outline-danger {
  color: $color-danger;
  background-color: $color-white;
  border-color: $color-danger;
}
.outline-danger:hover {
  background-color: $color-danger;
  color: $color-white;
}
.outline-success:hover {
  background-color: $color-success;
  color: $color-white;
}

.input-disabled {
  background-color: $color-disabled;
  cursor: initial;
}

.button-container button {
  height: 33px;
  width: 33px;
  padding: 0;
}

.file-input-container {
  height: auto !important;
}
</style>
