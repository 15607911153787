<template>
  <b-container class="mt-5" fluid>
    <step-wizard
      :cascade-activation="true"
      class="mb-5"
      :step="stepIndex"
      :tabs="Tabs"
      @OnStepChange="onStepChange($event)"
    />
    <b-tabs v-model="stepIndex" pills>
      <b-tab>
        <create
          class="my-3"
          :value="kiosk"
          @continue="onContinue($event)"
          @submmited="loadKiosk(kioskId)"
        />
      </b-tab>
      <b-tab>
        <screen-assignment
          v-if="stepIndex == 1"
          class="mt-3"
          :value="kiosk"
          @change-screens="kiosk.screens = orderScreens($event)"
          @continue="onContinue($event)"
        />
      </b-tab>
      <b-tab v-for="(screen, index) in kiosk.screens" :key="index" lazy>
        <screen-management
          v-model="kiosk.screens[index]"
          class="mt-3"
          :kiosk="kiosk"
          :screen-number="index + 1"
          :first-screen="index ? firstScreen : null"
          :types="screenTypes"
          @continue="onContinue($event)"
          @update:first-screen="firstScreen = $event"
        />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import Create from './Create';
import ScreenAssignment from './ScreenAssignment';
import ScreenManagement from './ScreenManagement';
import { getScreenTypes as _getScreenTypes } from '@/services/KioskService';
import {
  getKiosk as _getKiosk,
  getScreenInfo as _getScreenInfo,
} from '@/services/KioskService';
import { saveCreationStep as _saveCreationStep } from '@/services/KioskService';
import ScreenTypes from '@/constants/KioskScreenTypes';
import StepWizard from '@/components/StepWizard';

export default {
  name: 'KioskMaster',
  components: {
    Create,
    ScreenAssignment,
    ScreenManagement,
    StepWizard,
  },
  data() {
    return {
      stepIndex: 0,
      kiosk: {
        kioskId: null,
        companyId: null,
        locationConfigurationId: null,
        locationConfigurationName: null,
        name: null,
        macAddress: [],
        haveSpecialFields: false,
        isMultiLang: false,
        layout: {
          kioskId: null,
          kioskDesignConfigurationId: null,
          headerBackgrounColor: null,
          headerBackgroundImage: null,
          headerLogo: null,
          headerLogoPosition: null,
          headerText: null,
          headerTextColor: null,
          headerTextPosition: null,
          contentBackgroundColor: null,
          contentBackgroundImage: null,
          footerBackgroundColor: null,
          footerBackgroundImage: null,
          footerLogo: null,
          footerLogoPosition: null,
          homeButtonText: null,
          homeButtonTextColor: null,
          homeButtonBackgroundColor: null,
          homeButtonBorderColor: null,
          homeButtonRadiusSize: null,
          homeButtonShadowColor: null,
          homeButtonBorderSize: null,
          backButtonText: null,
          backButtonTextColor: null,
          backButtonBackgroundColor: null,
          backButtonBorderColor: null,
          backButtonRadiusSize: null,
          backButtonShadowColor: null,
          backButtonBorderSize: null,
          keyboardSize: null,
          backToStartCounter: null,
          keyboardDisplayZoom: true,
          keyboardCapsLock: false,
          showInformativeMessage: false,
          informativeMessage: null,
          informativeMessageColor: null,
          keyboardSimple: false,
          keyboardBackgroundColor: null,
          keyboardTextColor: null,
          datePickerBackgroundColor: null,
          datePickerTextColor: null,
        },
        assignScreen: {
          screenCount: 0,
          canSendNotifications: true,
        },
        screens: [],
      },
      screenTypes: [],
      firstScreen: null,
    };
  },
  computed: {
    Tabs() {
      return [
        {
          name: this.$t('kioskCreation'),
          icon: 'far fa-edit',
          disabled: false,
        },
        {
          name: this.$t('screenMapping'),
          icon: 'far fa-plus-square',
          disabled: !this.kiosk.kioskId,
        },
        ...this.kiosk.screens,
      ];
    },
  },
  created() {
    this.loadScreenType();
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'kioskEdit') {
          const kioskId = Number(val.params.id);
          this.loadKiosk(kioskId);
          this.kiosk.kioskId = kioskId;
        } else {
          this.kiosk.kioskId = null;
        }
      },
    },
    stepIndex(val) {
      _saveCreationStep(this.kiosk.kioskId, val);
    },
    'kiosk.screens': {
      deep: true,
      handler() {
        this.validateScreens();
      },
    },
  },
  methods: {
    async loadScreenType() {
      await _getScreenTypes()
        .then((response) => (this.screenTypes = response.data))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    loadKiosk(id) {
      _getKiosk(id)
        .then((response) => {
          const data = response.data;
          const layout =
            {
              ...data.kioskLayoutDesignConfiguration,
              headerLogo: null,
              headerBackgroundImage: null,
              contentBackgroundImage: null,
              footerBackgroundImage: null,
              footerLogo: null,
            } || this.kiosk.layout;

          this.kiosk = {
            ...this.kiosk,
            companyId: data.companyId,
            locationConfigurationId: data.locationConfigurationId,
            locationConfigurationName: data.locationConfigurationName,
            name: data.name,
            macAddress: data.macAddress,
            haveSpecialFields: data.haveSpecialFields,
            isMultiLang: data.isMultiLang,
            hasCardReader: data.hasCardReader,
            assignScreen: {
              screenCount: data.screens.filter(
                (screen) =>
                  ![ScreenTypes.Turn, ScreenTypes.Notification].includes(
                    screen.screenTypeId,
                  ),
              ).length,
              canSendNotifications: data.canSendNotifications,
              relationIds: data.relationIds,
            },
            layout,
            screens: this.orderScreens(data.screens),
          };

          setTimeout(() => (this.stepIndex = data.creationStep), 200);

          if (this.kiosk.screens.length) {
            const screen = this.kiosk.screens[0];
            _getScreenInfo({
              kioskId: this.kiosk.kioskId,
              kioskScreenId: screen.kioskScreenId,
            })
              .then(({ data }) => {
                const value = {
                  ...screen,
                  ...data,
                };

                this.firstScreen = value;
              })
              .catch((error) =>
                this.ShowErrorToast(error.response.data.message),
              );
          }
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'kiosksList' });
        });
    },
    validateScreens() {
      let isValid = true;
      this.kiosk.screens.forEach((screen) => {
        let disabled = true;
        if (isValid) {
          isValid = screen.isValid;
          disabled = false;
        }
        screen.disabled = disabled;
      });
    },
    orderScreens(screens) {
      const baseScreenTypeIds = [ScreenTypes.Notification, ScreenTypes.Turn];

      const optionalScreens = screens.filter(
        (x) => !baseScreenTypeIds.includes(x.screenTypeId),
      );

      const baseScreens = screens
        .filter((x) => baseScreenTypeIds.includes(x.screenTypeId))
        .sort((a, b) => {
          if (a.screenTypeId > b.screenTypeId) return 1;
          if (a.screenTypeId < b.screenTypeId) return -1;
          return 0;
        });

      return optionalScreens.concat(baseScreens).map((screen, index) => {
        const name = !baseScreenTypeIds.includes(screen.screenTypeId)
          ? `${this.$t('screen')} ${index + 1}`
          : '';
        let icon = 'far fa-layer-plus';
        if (screen.screenTypeId === ScreenTypes.Notification)
          icon = 'far fa-bell-on';
        if (screen.screenTypeId === ScreenTypes.Turn)
          icon = 'fas fa-ticket-alt';

        return {
          kioskScreenId: screen.id,
          name: `${name}${name && screen.name ? ' - ' : ''}${
            screen.name || ''
          }`,
          kioskScreenTypeId: screen.screenTypeId,
          specialField: screen.specialField,
          isValid: screen.isValid,
          icon,
        };
      });
    },
    onContinue(event) {
      const isNegative = event < 0;
      const nextPosition = this.stepIndex + event;
      if (nextPosition === -1) this.$router.push({ name: 'kiosksList' });

      if (isNegative) this.stepIndex = nextPosition;
      else {
        if (this.stepIndex === 0) this.stepIndex = nextPosition;
        else if (this.stepIndex === 1 && this.kiosk.screens.length)
          this.stepIndex = nextPosition;
        else if (this.stepIndex === this.kiosk.screens.length + 1) {
          this.$router.push({ name: 'kiosksList' });
          _saveCreationStep(this.kiosk.kioskId, 0);
        } else if (
          this.stepIndex > 1 &&
          this.kiosk.screens[this.stepIndex - 2].kioskScreenTypeId
        ) {
          this.stepIndex = nextPosition;
        }
      }
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
</style>
