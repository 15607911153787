<template>
  <b-container id="appointments-container" fluid>
    <loading :active="loading" color="#F37E00" :is-full-page="true" />
    <b-row>
      <b-col cols="12">
        <div class="header-title py-3">
          <h4 class="title">
            {{ $t('verifySpaces') }}
          </h4>
        </div>
      </b-col>
    </b-row>
    <div class="main-content mb-4">
      <b-form>
        <div id="generalInfo" class="border-bottom mb-4">
          <b-row>
            <b-col cols="12">
              <div
                class="caption border-bottom mb-4 text-left main-content-title"
              >
                {{ $t(caption) }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="3" sm="3">
              <b-form-group :label="$t('company') + ':'">
                <label class="strong">{{
                  selectedCompany != undefined ? selectedCompany.name : ''
                }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3" sm="3">
              <b-form-group :label="$t('localization') + ':'">
                <label class="strong">{{
                  selectedLocalization != undefined
                    ? selectedLocalization.name
                    : ''
                }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" sm="6">
              <b-form-group :label="$t('services') + ':'">
                <label
                  v-for="(service, index) in selectedServices"
                  :key="service.id"
                  class="strong"
                >
                  {{
                    service.name != undefined
                      ? index != 0
                        ? ', ' + service.name
                        : service.name
                      : ''
                  }}
                </label>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div id="dateAndTime">
          <b-row class="mb-4">
            <b-col cols="12" lg="3" sm="3">
              <b-form-group :label="$t('startDate')">
                <label class="strong">{{ selectedDates[0] | date }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3" sm="3">
              <b-form-group :label="$t('end')">
                <label class="strong">{{
                  selectedDates[selectedDates.length - 1] | date
                }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('durationInMinutes')">
                <label class="strong">{{ hourDuration }} Minutos</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('fromHour')">
                <label class="strong">{{ hourStart | hour }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('toHour')">
                <label class="strong">{{ hourEnd | hour }}</label>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div id="recurrency" class="mb-4 border-bottom">
          <b-row>
            <b-col cols="12" lg="3" sm="3">
              <b-form-group :label="$t('recurrency') + ':'">
                <label class="strong">{{
                  selectedFrecuency ? $t(selectedFrecuency.name) : '-'
                }}</label>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3" sm="3">
              <b-form-group class="strong" :label="$t('selectedDays')">
                <div
                  v-for="selectedDay in selectedWeekDays"
                  :key="selectedDay.id"
                >
                  <span>{{ $t(selectedDay.name) | capitalize }}</span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group class="strong" :label="$t('ignoredHours')">
                <div v-for="ignoredHour in ignoredHours" :key="ignoredHour.id">
                  <span>{{ ignoredHour.name }}</span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2" sm="3">
              <b-form-group :label="$t('ignoredDates')">
                <div
                  v-for="ignoredDate in ignoredDates"
                  :key="ignoredDate"
                  class="strong"
                >
                  <span>{{ ignoredDate | date }}</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div id="specialists">
          <b-row>
            <b-col cols="12" lg="12" sm="12">
              <b-form-group :label="$t('specialists')">
                <b-col
                  v-for="specialistName in selectedSpecialistsNames"
                  :key="specialistName"
                  class="strong float-left p-1"
                  lg="2"
                  md="3"
                  sm="6"
                >
                  {{ specialistName }}
                </b-col>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
    <div>
      <SpaceResult :space-calculation="spaceCalculatedData" />
    </div>
    <div v-if="spacesAreProcessed">
      <div class="result-main-content result-secondary-content mb-4 danger">
        <div id="generalInfo">
          <b-row>
            <b-col cols="12" class="text-center">
              <base-filled-button
                class="mx-0"
                icon-class="fa fa-eye"
                :on-click="() => $router.push({ name: 'ProcessList' })"
                :text="$t('watchRunningProcessStatus')"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <alert-message
      v-if="spaceLimit > 0 && spaceLimit < spaceCalculatedData.totalSpaces"
      bg-color="#ff5d5d"
      icon-class="far fa-times-circle"
      :message="`${$t('systemDoesNotLetMore')} ${spaceLimit} ${$t(
        'spaceAmount',
      )} ${spaceCalculatedData.totalSpaces}`"
    />
    <div v-if="!spacesAreProcessed">
      <b-row>
        <b-col
          cols="12"
          class="flex-sm-row d-flex flex-column-reverse justify-content-center align-items-center justify-content-sm-between"
        >
          <button
            class="btn btn-default"
            type="button"
            @click="$refs['go-back-modal-ref'].show()"
          >
            <i class="far fa-times-circle" /> {{ $t('cancel') }}
          </button>
          <div class="header-buttons py-3 float-right">
            <slot name="buttoms" />
            <button
              class="btn btn-outline-primary mr-2"
              type="button"
              @click="goStepBack"
            >
              <i class="fal fa-chevron-circle-left" /> {{ $t('goBack') }}
            </button>
            <button
              v-if="!spacesAreProcessed"
              class="btn btn-primary mr-2"
              type="button"
              @click="processSpaces()"
            >
              <i class="fal fa-eye" /> {{ $t('process') }}
            </button>
          </div>
        </b-col>
      </b-row>
    </div>
    <button
      v-else
      class="btn btn-primary mr-2 ml-auto d-block"
      type="button"
      @click="goToAvailableSpaces()"
    >
      <i class="far fa-times-circle" />
      {{ $t('finalize') }}
    </button>
    <custom-modal id="go-back-modal" ref="go-back-modal-ref" size="md">
      <div class="back-container text-center">
        <i class="fal fa-exclamation-circle back-container__icon" />
        <div class="back-container__title text-center">
          {{ $t('cancelSpaceConfimation') }}
        </div>
        <div class="back-container__message text-center">
          {{ $t('irreversibleoperationmessage') }}
        </div>
        <div class="d-flex flex-row mt-4">
          <button
            class="btn btn-primary mx-4 flex-grow-1"
            type="button"
            @click="OnCancel"
          >
            {{ $t('yes') }}
          </button>

          <button
            class="btn btn-secondary mx-4 flex-grow-1"
            type="button"
            @click="$refs['go-back-modal-ref'].hide()"
          >
            {{ $t('no') }}
          </button>
        </div>
      </div>
    </custom-modal>
  </b-container>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import AlertMessage from '../../components/basics/alerts/AlertMessage.vue';
import { saveSpaces as _saveSpaces } from '@/services/AppointmentService';
import SpaceResult from '@/components/spaces/SpaceResult.vue';
import _formatter from 'moment';
import { modulesName } from '@/store';

export default {
  name: 'VerifySpaces',
  components: {
    SpaceResult,
    CustomModal,
    AlertMessage,
  },
  mixins: [sessionState],
  data() {
    const initialData = this.initializeState();

    return initialData;
  },
  mounted() {
    if (!this.hasSessionData('spacesToVerify')) {
      this.$router.push({ name: 'availablespaces' });
      return;
    }
    this.hourEndText = _formatter(this.hourEnd, 'hh:mm A');
  },
  computed: {
    selectedSpecialistsNames: function () {
      const names = this.selectedSpecialists
        .map(function (x) {
          return x.name.substring(0, x.name.indexOf(' - '));
        })
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

      let uniqueNames = [];

      names.forEach((c) => {
        if (!uniqueNames.includes(c)) {
          uniqueNames.push(c);
        }
      });

      return uniqueNames;
    },
    recurrencyOptionText: function () {
      return this.frequencies.find(
        (element) => element.id === this.recurrencyOption,
      ).name;
    },
    availableWeekDays() {
      const result = this.$moment.weekdays().map((e, i) => {
        return { name: e.charAt(0).toUpperCase() + e.slice(1), id: i };
      });

      return result;
    },
    hoursToProcess() {
      const hoursValues = [];

      for (let i = 0; i < this.selectedHours.length; i++) {
        const isIgnored = this.ignoredHours.find(
          (element) => element == this.selectedHours[i],
        );

        if (!isIgnored) {
          hoursValues.push(this.selectedHours[i]);
        }
      }

      return hoursValues;
    },
  },
  methods: {
    goStepBack() {
      this.$router.push({ name: 'CreateSpace' });
    },
    goToAvailableSpaces() {
      this.$router.push({ name: 'availablespaces' });
    },
    async processSpaces() {
      const specialistsIds = this.selectedSpecialists.map(function (x) {
        return x.id;
      });
      const servicesIds = this.selectedServices.map(function (x) {
        return x.id;
      });

      const hoursValues = this.hoursToProcess.map(function (x) {
        return x.id;
      });

      if (
        !this.spacesAreProcessed &&
        specialistsIds.length > 0 &&
        servicesIds.length > 0 &&
        this.selectedDates.length > 0 &&
        hoursValues.length > 0
      ) {
        try {
          _saveSpaces(
            specialistsIds,
            servicesIds,
            this.selectedDates,
            hoursValues,
            this.hourDuration,
          );

          this.caption = 'confirmation';
          this.spacesAreProcessed = true;
          this.removeSessionData('spacesToVerify');
        } catch (error) {
          this.ShowErrorToast(error.response.data.message);
        }

        setTimeout(() => {
          this.$store.dispatch(
            `${modulesName.appModuleName}/setLoading`,
            false,
          );
        }, 2000);
      }
    },
    OnCancel() {
      this.removeSessionData('spacesToVerify');
      this.removeSessionData('SpacesCreate');
      this.removeSessionData('spacesToCreate');
      this.removeSessionData('availablespaces');
      this.$router.go(this.$router.push({ name: 'availablespaces' }));
    },
    initializeState() {
      const defaultData = {
        selectedDates: [],
        selectedHours: [],

        selectedCompany: null,
        selectedLocalization: null,
        selectedServices: [],
        selectedSpecialists: [],

        selectedWeekDays: [],

        hourDuration: null,
        hourStart: null,
        hourEnd: null,

        ignoredHours: [],
        ignoredDates: [],

        selectedFrecuency: null,

        processedSpacesData: {},
        hourEndText: '',
        spacesAreProcessed: false,
        spaceCalculatedData: {},
        spaceLimit: 0,
        caption: 'reviewInfo',

        loading: false,
      };

      if (!this.hasSessionData('spacesToVerify')) {
        return defaultData;
      }

      const sessionData = this.loadSessionData('spacesToVerify');

      return { ...defaultData, ...sessionData };
    },
  },
  destroyed() {
    if (this.hasSessionData('spacesToVerify')) {
      this.removeSessionData('spacesToVerify');
    }
  },
};
</script>
<style lang="scss" scoped>
#appointments-container {
  background-color: $color-app-background;
  padding-bottom: 50px;
}
.main-content,
.secondary-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 25px !important;
}
.secondary-content {
  padding-top: 30px !important;
  padding-bottom: 10px !important;
}
.main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
.title-description {
  margin-top: 18px;
  margin-bottom: 18px;
}
.header-title {
  font-family: 'lato-bold', sans-serif;
  font-size: 24px;
  color: $color-font-primary;
}
.danger {
  border: 2px #ff5b5c solid;
}

.result-main-content,
.result-secondary-content {
  background: $color-white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.result-secondary-content {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.result-main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}
</style>
