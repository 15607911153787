<template>
  <validation-observer ref="massSpaceCreateFormRef" novalidate>
    <div v-if="!processCompleted" class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:50">
          <custom-file-input
            v-model="documentToValidate"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('documentToProcess')"
            :prevent-download="true"
            :onDownload="() => null"
            @after-remove="onRemoveFile"
          />
        </validation-provider>
      </div>
    </div>
    <div v-show="documentToValidate && model.validatedDocument && !processCompleted" class="row">
      <div class="col-md-12">
        <div class="card mt-4">
          <div class="card-body pb-2">
            <div class="row">
              <div class="col-sm-12 col-md-6 float-left font-lg text-center">
                <label>{{ $t('rowsWithErrors') }}: </label>
                <label class="error-data">{{ model.validationData ? model.validationData.invalidRows.length : 0 }}</label>
              </div>
              <div class="col-sm-12 col-md-6 float-right font-lg text-center">
                <label>{{ $t('validRows') }}: </label>
                <label class="success-data">{{ model.validationData ? model.validationData.validRows.length : 0 }}</label>
              </div>
            </div>
            <hr class="splitter" />
            <div class="row">
              <div class="col-sm-12 col-md-6 float-left font-lg text-center">
                <label>{{ $t('selectedDays') }}: </label>
                <label class="success-data">{{ model.daysCount }}</label>
              </div>
              <div class="col-sm-12 col-md-6 float-left font-lg text-center">
                <label>{{ $t('specialists') }}: </label>
                <label class="success-data">{{ model.specialistCount }}</label>
              </div>
              <div class="col-sm-12 col-md-6 float-right font-lg text-center">
                <label>{{ $t('services') }}: </label>
                <label class="success-data">{{ model.servicesCount }}</label>
              </div>
              <div class="col-sm-12 col-md-6 float-left font-lg text-center">
                <label>{{ creationResult != null ? $t('createdSpaces') : $t('spacesToCreate') }}: </label>
                <label class="success-data">{{ creationResult ? this.creationResult.totalSpaces : this.model.totalSpaces }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="processCompleted">
      <b-row>
        <b-col cols="12" class="text-center">
          <base-filled-button
            class="mx-0"
            icon-class="fa fa-eye"
            :on-click="() => $router.push({ name: 'ProcessList' })"
            :text="$t('watchRunningProcessStatus')"
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="!processCompleted" class="row mt-4 pt-4 footer">
      <div class="col-sm-12 col-md-6">
        <b-button
          class="text-capitalize float-left"
          variant="outline-success"
          @click="onDownloadTemplate"
        >
          <i class="fal fa-download" /> {{ $t('downloadTemplate') }}
        </b-button>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          v-if="documentToValidate && model.validatedDocument == null"
          class="btn btn-danger float-right"
          variant="danger"
          @click="onValidate"
        >
          <i class="fas fa-upload" /> {{ $t('validate') }}
        </button>
        <button
          v-if="documentToValidate && model.validatedDocument && !processCompleted"
          :disabled="!model.isValid"
          class="btn btn-primary float-right ml-3"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="fad fa-cogs" /> {{ $t('process') }}
        </button>
        <button
          v-if="documentToValidate && model.validatedDocument"
          class="btn btn-success float-right"
          variant="success"
          @click="onDownloadValidatedDocument"
        >
          <i class="far fa-cloud-download" /> {{ $t('download') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import CustomFileInput from '@/components/CustomFileInput';

import _generalApi from '@/modules/app/api.js';

import {
  getSpaceMassCreationTemplate as _getSpaceMassCreationTemplate,
  validateSpaceMassCreationDocument as _validateSpaceMassCreationDocument,
  createSpacesInMass as _createSpacesInMass
} from '@/services/AppointmentService';

export default {
  name: 'MassSpaceCreationForm',
  components: { CustomFileInput },
  data() {
    return { 
      documentToValidate: null,
      creationResult: null,
      processCompleted: false,
      model: this.resetModel()
    };
  },
  mounted() {
  },
  watch: {
    documentToValidate(newValue) {
      if (!newValue) {
        this.model = this.resetModel();
      }
    },
  },
  methods: {
    reset() {
      this.documentToValidate = null;
      this.creationResult = null;
      this.model = this.resetModel();
    },
    resetModel() {
      return {
        isValid: false,
        locationCount: 0,
        specialistCount: 0,
        servicesCount: 0,
        daysCount: 0,
        totalSpaces: 0,
        validationData: {
          validRows: [],
          invalidRows: []
        },
        validatedDocument: null
      }
    },
    async onValidate() {
      const isValid = await this.$refs.massSpaceCreateFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = {
        documentTemplate: this.documentToValidate
      };

      await _validateSpaceMassCreationDocument(payload)
        .then((response) => {
          this.model = response.data;
        })
        .catch((error) => { 
          if(error.errors) {
            this.ShowErrorToast(error.errors.DocumentTemplate[0]);
          } else {
            this.ShowErrorToast(error.response.data.message);
          }
        });
    },
    async onDownloadValidatedDocument() {
      if(this.model.validatedDocument) {
        _generalApi.ConvertToBlob(this.model.validatedDocument);
      }
    },
    async onDownloadTemplate() {
      _getSpaceMassCreationTemplate().then(response => _generalApi.ConvertToBlob(response.data))
        .catch((error) => {
              if (error.response.data.message) {
                this.ShowToast('Error', error.response.data.message, 'error');
              } else {
                this.ShowToast('Error', error.message, 'error');
              }
            });
    },
    async onSubmit() {
      let payload = {
        validRows: []
      };

      this.model.validationData.validRows.forEach((x) => { 
        payload.validRows.push({ 
          locationConfigurationId: x.locationConfigurationId, 
          serviceTypeId: x.serviceTypeId,
          date: x.date, 
          hourFrom: x.hourFrom, 
          hourTo: x.hourTo, 
          duration: x.duration,
          specialistCount: x.specialistCount
        });
      });

      _createSpacesInMass(payload).then(response => {
        this.ShowSuccessSaveToast();

        this.processCompleted = true;
        this.creationResult = response.data;
      })
        .catch((error) => {
              if (error.response.data.message) {
                this.ShowToast('Error', error.response.data.message, 'error');
              } else {
                this.ShowToast('Error', error.message, 'error');
              }
            });
    },
    onRemoveFile() {
      this.processCompleted = false;
      this.reset();
    }
  },
};
</script>
<style lang="scss" scoped>
  .footer {
    border-top: 1px solid #dee2e6;
  }

  .error-data {
    margin-left: 10px;
    color: $color-danger;
  }

  .success-data {
    margin-left: 10px;
    color: $color-success;
  }

  ::v-deep .has-document {
    margin-left: 32%;
  }

  @media only screen and (max-width: 991px) {
    ::v-deep .has-document {
      margin-left: 20%;
    }
  }

  @media only screen and (max-width: 575px) {
    ::v-deep .has-document {
      margin-left: 23%;
    }
  }
</style>
