<template>
  <b-row class="mb-5">
    <b-col cols="12">
      <b-card border-variant="light">
        <validation-observer ref="screenTypeFormRef" novalidate>
          <screen-type :kiosk="kiosk" :types="types" :value="screen" />
          <b-row>
            <b-col
              v-if="screen.kioskScreenTypeId === screenTypes.Client"
              cols="12"
            >
              <screen-client :kiosk="kiosk" :value="screen" />
            </b-col>
            <b-col
              v-if="screen.kioskScreenTypeId === screenTypes.Turn"
              cols="12"
            >
              <screen-turn :value="screen" />
            </b-col>
            <b-col
              v-if="
                screen.kioskScreenTypeId === screenTypes.Queue ||
                screen.kioskScreenTypeId === screenTypes.Service
              "
              cols="12"
            >
              <input-special-field :kiosk="kiosk" :value="screen" />
            </b-col>
            <b-col class="d-flex justify-content-between">
              <b-form-checkbox v-model="screen.hideInPWA" switch>
                {{ $t('hideInPWA') }}
              </b-form-checkbox>
              <b-button variant="outline-primary" @click="onSubmitScreen()">
                <i class="far fa-save" /> {{ $t('save') }}
              </b-button>
            </b-col>
            <div class="col-12">
              <alert-message
                v-if="showingFirstConfig"
                class="mt-3"
                :message="
                  $t('youHaveNotSavedYetConfigurationCurrentlyFromFirstScreen')
                "
              />
            </div>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col v-if="value.kioskScreenTypeId === screenTypes.Button" cols="12">
      <screen-buttons
        :screen-number="screenNumber"
        :screens="kiosk.screens"
        :value="screen"
        @submitted="$emit('input', { ...value, isValid: isValid() })"
      />
    </b-col>
    <b-col
      v-if="
        value.kioskScreenTypeId === screenTypes.Service &&
        screen.specialField &&
        initialSpecialField === screen.specialField
      "
      cols="12"
    >
      <screen-service
        :kiosk="kiosk"
        :screen-number="screenNumber"
        :value="screen"
        @submitted="$emit('input', { ...value, isValid: isValid() })"
      />
    </b-col>
    <b-col
      v-if="
        value.kioskScreenTypeId === screenTypes.Queue &&
        screen.specialField &&
        initialSpecialField === screen.specialField
      "
      cols="12"
    >
      <screen-queue
        :kiosk="kiosk"
        :screen-number="screenNumber"
        :value="screen"
        @submitted="$emit('input', { ...value, isValid: isValid() })"
      />
    </b-col>
    <b-col
      v-if="value.kioskScreenTypeId === screenTypes.SpecialField"
      cols="12"
    >
      <screen-special-field
        :kiosk="kiosk"
        :screen-number="screenNumber"
        :screens="kiosk.screens"
        :value="screen"
      />
    </b-col>
    <b-col v-if="value.kioskScreenTypeId === screenTypes.CheckIn" cols="12">
      <screen-checking
        :location-configuration-id="kiosk.locationConfigurationId"
        :value="screen"
      />
    </b-col>
    <b-col
      v-if="value.kioskScreenTypeId === screenTypes.Notification"
      cols="12"
    >
      <screen-notification v-model="screen" />
    </b-col>
    <b-col v-if="value.kioskScreenTypeId === screenTypes.Information" cols="12">
      <screen-information
        v-model="screen"
        :kiosk="kiosk"
        :screen-number="screenNumber"
      />
    </b-col>
    <b-col class="mt-5" cols="12">
      <base-filled-button
        bg-color="#707070"
        class="float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'kiosksList' })"
        :text="$t('cancel')"
      />
      <b-button
        class="float-left"
        variant="primary"
        @click="$emit('continue', -1)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </b-button>
      <b-button
        class="float-right"
        :disabled="!isValid()"
        variant="primary"
        @click="$emit('continue', 1)"
      >
        <span v-if="kiosk.screens.length === screenNumber">
          {{ $t('finalize') }}
        </span>
        <span v-else>
          {{ $t('continue') }} <i class="far fa-arrow-alt-circle-right" />
        </span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import ScreenType from './components/ScreenType';
import ScreenButtons from './components/ScreenButtons';
import ScreenTypes from '@/constants/KioskScreenTypes';
import ScreenQueue from './components/ScreenQueue';
import ScreenChecking from './components/ScreenChecking';
import ScreenService from './components/ScreenService.vue';
import ScreenNotification from './components/ScreenNotification';
import ScreenInformation from './components/ScreenInformation';
import ScreenClient from './components/ScreenClient';
import ScreenTurn from './components/ScreenTurn';
import InputSpecialField from './components/InputSpecialField';
import ScreenSpecialField from './components/ScreenSpecialField';
import { getScreenInfo as _getScreenInfo } from '@/services/KioskService';
import { submitScreenType as _submitScreenType } from '@/services/KioskService';
import AlertMessage from '@/components/basics/alerts/AlertMessage.vue';

export default {
  name: 'ScreenManagement',
  components: {
    ScreenType,
    ScreenButtons,
    ScreenQueue,
    ScreenChecking,
    ScreenService,
    ScreenNotification,
    ScreenClient,
    ScreenTurn,
    InputSpecialField,
    ScreenSpecialField,
    ScreenInformation,
    AlertMessage,
  },
  props: {
    firstScreen: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => {},
    },
    screenNumber: {
      type: Number,
      default: () => {},
    },
    kiosk: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      screenTypes: ScreenTypes,
      initialKioskScreenTypeId: null,
      initialSpecialField: null,
      screen: {
        kioskId: this.kiosk.kioskId,
        kioskScreenId: this.value.kioskScreenId,
        kioskScreenTypeId: this.value.kioskScreenTypeId,
        buttonBackgroundColor: null,
        buttonBorderColor: null,
        buttonTextColor: null,
        buttonRadiusSize: null,
        buttonBorderSize: null,
        buttonShadowSize: null,
        buttonShadowColor: null,
        topText: null,
        topTextEN: null,
        columnsQuantity: null,
        topTextColor: null,
        topTextSize: null,
        topTextBoldPercentage: null,
        notificationTopText: null,
        notificationTopTextColor: null,
        notificationTopTextSize: null,
        notificationTopTextBoldPercentage: null,
        paternalLastNameAsk: null,
        paternalLastNameRequired: null,
        maternalLastNameAsk: null,
        maternalLastNameRequired: null,
        displayKeyboardForClient: null,
        displayTurnNumber: null,
        displayQueuePosition: null,
        specialField: null,
        maintainQueueRelationshipWithService: null,
        displayPreferentialOption: false,
        confirmationButtonSize: null,
        confirmationButtonPWASize: null,
        buttons: [],
        queues: [],
        services: [],
        specialFields: [],
      },
      showingFirstConfig: false,
    };
  },
  mounted() {
    this.loadScreen();
  },
  watch: {
    'screen.kioskScreenTypeId'(value) {
      if (value === this.initialKioskScreenTypeId)
        this.value.kioskScreenTypeId = value;
      else this.value.kioskScreenTypeId = null;
    },
    firstScreen() {
      this.addFirstScreenConfiguration();
    }
  },
  methods: {
    canAddFirstScreenConfiguration() {
      const {
        buttonBackgroundColor,
        buttonBorderColor,
        buttonTextColor,
        buttonRadiusSize,
        buttonBorderSize,
        buttonShadowSize,
        buttonShadowColor,
        topTextColor,
        topTextSize,
        topTextBoldPercentage,
      } = this.screen || {};

      return (
        !!this.firstScreen &&
        !buttonBackgroundColor &&
        !buttonBorderColor &&
        !buttonTextColor &&
        !buttonRadiusSize &&
        !buttonBorderSize &&
        !buttonShadowSize &&
        !buttonShadowColor &&
        !topTextColor &&
        !topTextSize &&
        !topTextBoldPercentage
      );
    },
    addFirstScreenConfiguration() {
      if (!this.canAddFirstScreenConfiguration()) return;
      this.screen.buttonBackgroundColor =
        this.firstScreen.buttonBackgroundColor;
      this.screen.buttonBorderColor = this.firstScreen.buttonBorderColor;
      this.screen.buttonTextColor = this.firstScreen.buttonTextColor;
      this.screen.buttonRadiusSize = this.firstScreen.buttonRadiusSize;
      this.screen.buttonBorderSize = this.firstScreen.buttonBorderSize;
      this.screen.buttonShadowSize = this.firstScreen.buttonShadowSize;
      this.screen.buttonShadowColor = this.firstScreen.buttonShadowColor;
      this.screen.topTextColor = this.firstScreen.topTextColor;
      this.screen.topTextSize = this.firstScreen.topTextSize;
      this.screen.topTextBoldPercentage =
        this.firstScreen.topTextBoldPercentage;
      this.showingFirstConfig = true;
    },
    loadScreen() {
      if (!this.value.kioskScreenTypeId)
        return this.addFirstScreenConfiguration();
      this.initialKioskScreenTypeId = this.value.kioskScreenTypeId;
      _getScreenInfo({
        kioskId: this.kiosk.kioskId,
        kioskScreenId: this.value.kioskScreenId,
      })
        .then((response) => {
          const value = {
            ...this.screen,
            ...response.data,
            buttons: response.data.buttons || [],
            services: response.data.services || [],
            queues: response.data.queues || [],
            specialFields: response.data.specialFields || [],
          };

          this.screen = value;
          this.initialSpecialField = this.screen.specialField;
          this.addFirstScreenConfiguration();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmitScreen() {
      if (!(await this.$refs.screenTypeFormRef.validate())) return;
      _submitScreenType({
        ...this.screen,
      })
        .then(() => {
          this.ShowSuccessSaveToast();
          this.initialKioskScreenTypeId = this.screen.kioskScreenTypeId;
          if (this.initialSpecialField !== this.screen.specialField) {
            this.screen.queues = [];
            this.screen.services = [];
          }
          this.initialSpecialField = this.screen.specialField;
          this.$emit('input', {
            kioskScreenId: this.value.kioskScreenId,
            kioskScreenTypeId: this.screen.kioskScreenTypeId,
            name: `${this.$t('screen')} ${this.screenNumber} - ${
              this.screen.name
            }`,
            isValid: this.isValid(),
            specialField: this.screen.specialField,
            icon: this.value.icon,
          });
          this.showingFirstConfig = false;
          if (this.screenNumber == 1) {
            this.$emit('update:first-screen', {...this.screen});
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },

    isValid() {
      if (!this.screen.kioskScreenTypeId) return false;
      else {
        let isValid = true;
        switch (this.screen.kioskScreenTypeId) {
          case ScreenTypes.Button:
            isValid = !!this.screen.buttons.length;
            break;
          case ScreenTypes.Queue:
            isValid = !!this.screen.queues.length && this.screen.specialField;
            break;
          case ScreenTypes.Service:
            isValid = !!this.screen.services.length && this.screen.specialField;
            break;
        }
        return isValid;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
</style>
