const SpecialFieldType = Object.freeze({
    SpecialField1: 1,
    SpecialField2: 2,
    SpecialField3: 3,
    SpecialField4: 4,
    SpecialField5: 5,
    SpecialField6: 6,
    SpecialField7: 7,
  });

  export default SpecialFieldType;
